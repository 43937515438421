import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

type Props = {
  onSuccess?: any,
  onFailure?: any,
  runUntil?: number,
  reachEnd?: any,
};

export const StopWatch = forwardRef((props: Props, ref) => {
  let timeVar = (props.runUntil || 0) * 1000;
  //console.log(props.runUntil)
  const [time, setTime] = useState<any>(timeVar);
  const [running, setRunning] = useState(false);

  const handleStop = () => setRunning(false);
  const toggle = () => setRunning(!running);

  if (time === 0) {
    handleStop();
    props.reachEnd();
    setTime(null)
  }
  //if (time === undefined) setTime(timeVar)


  useEffect(() => {
    let interval: any;
    if (running) {
      interval = setInterval(() => {
        timeVar-= 10;
        setTime((prevTime: any) => prevTime - 10);
        if (timeVar < 0) {
          handleStop();
          props.reachEnd();
        }
      }, 10);

    } else if (!running) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [running]);

  useImperativeHandle(ref, () => ({
    handleStart,
    handleStop,
    toggle,
  }));

  const handleStart = () => {
    setRunning(true)
  };

  return (
    <div className="stopwatch">
      <div className="numbers">
        <span>{ time > 0 ? ("0" + Math.floor((time / 3600000) % 60)).slice(-2) : '00'}:</span>
        <span>{ time > 0 ? ("0" + Math.floor((time / 60000) % 60)).slice(-2) : '00'}:</span>
        <span>{ time > 0 ? ("0" + Math.floor((time / 1000) % 60)).slice(-2) : '00'}</span>
{/*         <span>{("0" + ((time / 10) % 100)).slice(-2)}</span> */}
      </div>
   {/*    <div className="buttons">
        <button onClick={() => setRunning(true)}>Start</button>
        <button onClick={() => setRunning(false)}>Stop</button>
        <button onClick={() => setTime(0)}>Reset</button>
      </div> */}
    </div>
  );
});
import { useUserState } from "../../services/User";

export const ProtectedPages = ({children}: any) => {
  const user = useUserState();

  return (
    (user?.id && !user.loading) && <>

     <>{ children }</>
    
    </>
  )
}
import { createContext, useContext, useEffect, useState } from 'react';

export const GlobalContext = createContext({});

export const GlobalContextProvider = (props: any) => {
  const [userNameInput, setUserNameInput] = useState<string | null>()

/*   useEffect(() => {
  }, [userNameInput]); */

  return <GlobalContext.Provider value={{ userNameInput, setUserNameInput }} {...props} />
}

export const useGlobalState = () => {
  const data: any = useContext(GlobalContext)
  return { ...data }
}
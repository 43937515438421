import ChartLoader from '../../loaders/ChartLoader';
import './DonutChart.scss';

type Props = {
  title?: string,
  showTotal?: boolean,
  data?: any,
};

export const DonutChart= (props: Props) => {
  const programChartData = {
    options: { labels: ['Finalized', 'Correct', 'Errors'], dataLabels: {enabled: false}, legend: {position: 'bottom'} as any},
    series: [ 5, 10, 15 ],
  }

  return (
    <section id="donut-chart">
      <div className="text-performance">
        <span className="first-line-performance">{props.title}</span>
        {/* <span className="second-line-performance">Update 1 month ago</span> */}
      </div>
      <div className='chart-container'>
        {props.data && <ChartLoader
            height={320}
            options={props.data.options}
            series={props.data.series}
            type="donut"
            />
        }
        {props.showTotal &&<>
        {props.data.totalExams ? <div className='title-span'>
          <span className='main-number'>{props.data.totalExams}</span>
          <span className='text'>Exams</span>
        </div>
        : <div className='title-span'>No data</div>
        }
        </>}
      </div>
 
    </section>
  );
};

import { format } from 'date-fns';
import { collection, deleteDoc, doc, orderBy, query, setDoc, where, writeBatch } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { db } from '../../services/firebase';
import { QueryUtils } from '../../util/query-utils';
import './UploadExamsWidget.scss';
import { ConfirmModal } from '../confirm-modal/ConfirmModal';
import { useToast } from '../../services/ToastContext';
import CSVReader from 'react-csv-reader';

type Props = {
  title?: string;
};

export const UploadExamsWidget = (props: Props) => {
  const confirmModalRef: any = useRef(null);
  const { showToastMessage } = useToast();
  const imageFileRef: any = useRef(null);
  useEffect(() => {
    fetchApi();
    fetchReportsFromDb();
  }, []);

  const [calendarList, setCalendarList] = useState<any>();
  const [reportsList, setReportsList] = useState<any[]>([]);
  const [selectedReport, setSelectedReport] = useState<any>();

  async function fetchApi() {
    const customQuery = query(collection(db, 'exams-calendar'), orderBy('date', 'desc'));
    const results = await QueryUtils.executeQuery(customQuery);
    setCalendarList(results);
  }

  async function fetchReportsFromDb() {
    const customQuery = query(collection(db, 'reports'));
    const reports = await QueryUtils.executeQuery(customQuery);
    setReportsList(reports);
  }

  function convertDate(date: any) {
    return date.toDate().toDateString();
  }

  async function handleDeleteReport(report: any) {
    setSelectedReport(report);
    confirmModalRef.current?.handleShow();
  }

  async function handleConfirmDelete() {
    await deleteDoc(doc(db, 'reports', selectedReport.id));
    setReportsList((prevReports) => prevReports.filter((report) => report.id !== selectedReport.id));
    showToastMessage('Report deleted successfully');
    confirmModalRef.current?.handleCancel();
  }

  async function handleFileUpload(data: any, fileInfo: any) {
    const batch = writeBatch(db);
    let examId: any;

    data.forEach((item: any) => {
      if (item.subreference) {
        examId = item.subreference;
      }
      if (item['q_1'] !== null) {
        const itemWithoutEmptyFields = Object.fromEntries(
          Object.entries(item).filter(([key, value]) => value !== undefined && value !== null && value !== '')
        );

        console.log(itemWithoutEmptyFields);

        itemWithoutEmptyFields.uploadDate = new Date();
        itemWithoutEmptyFields.examId = examId;

        const docADocRef = doc(collection(db, 'results-face-to-face'));
        batch.set(docADocRef, itemWithoutEmptyFields);
      }
    });

    await batch.commit();
    showToastMessage('Exams uploaded successfully');
  }

  /* async function createUserInDatabase(uid: string, email: string, pwd: string, firstName: string, lastName: string, parentalPassword: string, birthDate: any, generatedLogin: string) {
    const campaign = window.localStorage.getItem('campaign') || '';
    await setDoc(doc(db, 'user', uid), {
      id: uid,
      firstName,
      lastName,
      parentalPassword,
      campaign,
      birthDate: birthDate.toISOString(),
      login: generatedLogin,
      email,
    });
  }; */

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, '_'),
  };

  return (
    <section id="upload-exams-widget">
      <div className="performance-box">
        <div className="reports">
          <div className="reports-title">
            <img src="/icons/report-icn.png" alt="report-icn.png" />
            <span className="first-line-ranking">Upload face to face exams</span>
          </div>

          <div className="reports-list">
           {/*  <CSVReader
              cssClass="react-csv-input"
              label="Select CSV with statistics"
              onFileLoaded={handleFileUpload}
              parserOptions={parseOptions}
            /> */}

            <div className={'img-container'}>
              <CSVReader
                cssClass="upload-input secondary"
                ref={imageFileRef}
                label="Select CSV with statistics"
                onFileLoaded={handleFileUpload}
                parserOptions={parseOptions}
              />
              <img
                className="uploaded-file"
                src="/icons/upload.svg"
                height={30}
                alt="uploaded file"
                onClick={() => imageFileRef.current.click()}
              />

              {/*  <input
                  className="upload-input secondary"
                  name="headerImage"
                  ref={imageFileRef}
                  type="file"
                  onChange={(e) => {
                    sendImage(e);
                  }}
                /> */}
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        ref={confirmModalRef}
        onConfirm={handleConfirmDelete}
        title="Report Deletion"
        confirmBtn="Delete"
        description={`Are you sure you want to delete report: "${selectedReport?.report}"`}
      />
    </section>
  );
};

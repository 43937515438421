import React, { lazy, useEffect, useRef, useState } from 'react'
import './home.scss'
import { ProtectedPages } from '../../layouts/admin/ProtectedLayout';
import { WidgetBox } from '../../components/widget-box/WidgetBox';
import { DonutChart } from '../../components/donut-chart/DonutChart';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import { Exam } from '../../models/exam';
import { useUserState } from '../../services/User';
import { QueryUtils } from '../../util/query-utils';
import { db } from '../../services/firebase';
import { ArrayUtils } from '../../util/array-utils';
import { useStaticState } from '../../services/StaticContext';
import { ExamResult } from '../../models/exam-result';
import { addMinutes, format } from 'date-fns';
import { ExamType } from '../../models/exam-type';
import { Button } from 'react-bootstrap';
import { Utils } from '../../util/string-utils';
import { Navigate, useNavigate } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { CalendarWidget } from '../../components/calendar-widget/CalendarWidget';
import ChartLoader from '../../loaders/ChartLoader';
import useWindowDimensions from '../../services/window-dimensions';
import { Subject } from '../../models/subject';


const Home = () => {
  const user = useUserState();
  const navigate = useNavigate();
  const subjectRef: any = useRef(null);

  const { examTypes, subjects, subSubjects } = useStaticState();
  const [ examTypesForUser, setExamTypesForUser ] = useState<ExamType[]>();
  const [ userResults, setUserResults ] = useState<ExamResult[]>(); //these are all user results //RESP RESULTS HAVE THE EXAM QUESTION COPY!!
  const [ allExams, setAllExams ] = useState<Exam[]>();
  const [ selectedExamTypeResults, setSelectedExamTypeResults ] = useState<ExamResult[]>();
  const [selectedExamResult, setSelectedExamResult] = useState<ExamResult>();
  const [alreadyUpdated, setAlreadyUpdated] = useState<boolean>(true);
  const [lastChartData, setLastChartData] = useState<any>()
  const [startChartRender, setStartChartRender] = useState<boolean>(false);
  const { height, width, isMobile, isTablet, isMobileOnly, isTabletPortrait, isDesktop } = useWindowDimensions();
  const [selectedSubjectLastChart, setSelectedSubjectLastChart] = useState<any>('UiycKh7mYXbjAEscF0bO');
 
  useEffect(() => {
    if (user?.id && subjects?.length, examTypes?.length) {
      getExamResultsOverTime();

      setTimeout(() => {
        setStartChartRender(true)
      }, 5000);

      //window.google.charts.load("current", { packages: ["corechart"] });
      //google.charts.setOnLoadCallback(prepareChart);
    }
  }, [user?.id, subjects, examTypes]);

  async function getExamResultsOverTime() {
    try {
      const queryExams = query(collection(db, 'exam'));
      const respExams = await QueryUtils.executeQuery(queryExams) as Exam[];
      setAllExams(respExams)

      const customQuery = query(collection(db, 'results'), where('userId', '==', user.id), orderBy('created_date', 'desc'));
      const results = (await QueryUtils.executeQuery(customQuery)) as ExamResult[];

      const customQuery2 = query(collection(db, 'additional-results'), where('userId', '==', user.id), orderBy('created_date', 'desc'));
      const results2 = (await QueryUtils.executeQuery(customQuery2)) as ExamResult[];

      let respResults = results2.concat(results);
      //console.log(respExams)
      respResults = Utils.filterOutOnGoingExamsAndOld(respExams, respResults);

      const eIdList = new Set();
      respResults.forEach(r => {
        eIdList.add(r.examId);
      });

      const t2 = new Set();
      Array.from(eIdList).forEach(item => t2.add(respExams.find(a => a.id === item)?.type))

      setExamTypesForUser(examTypes.filter(et => Array.from(t2).some(item => item === et.id)));

      const filteredResults = updateSelectedExamResults( respExams?.find(exam => exam.id === respResults[0]?.examId)?.type!, respExams, respResults)
      setSelectedExamResult(filteredResults?.[0]);
    } catch(e) {
      console.error(e)
    }
  }

  function updateSelectedExamResults(examType: string, respExams?: Exam[], respResults?: ExamResult[]) {
    if (!respExams) respExams = allExams;
    if (!respResults) respResults = userResults; //RESP RESULTS HAVE THE EXAM QUESTION COPY!!

    respResults?.forEach(result => {
      const exam = respExams?.find(exam => exam.id === result.examId);
      result.examType = exam?.type;
      result.questions = exam?.questions;

      if (!result.submitted_date && exam?.timeMinutes) {
        result.submitted_date = addMinutes(result.created_date.toDate(), exam.timeMinutes);
      }

      result.correctAnswers = 0;
      exam?.questions.forEach((question) => {
        if (ArrayUtils.answeredCorrectly(question, result)) {
          result.correctAnswers++;
        }
      });

      //result.skipped = exam!.questions!.length - result.answers
    });

    setUserResults(respResults);
    buildLastChart(respExams!, respResults!);
    const filteredResults = respResults?.filter(item => respExams?.find(exam => exam.id === item.examId)?.type === examType)?.filter((item, i) => i < 7);
    setSelectedExamTypeResults(filteredResults);

    return filteredResults;
  }

  function percentString(quantity?: number, total?: number){
    if (quantity !== undefined && total) return ((quantity / total) * 100).toFixed(2) + '%';
  }
  function percent(quantity?: number, total?: number){
    if (quantity !== undefined && total) return ((quantity / total) * 100);
  }

  function onSelectCol(index: number) {
    setSelectedExamResult(selectedExamTypeResults?.[index]);

    const series: any = document.querySelector('.apexcharts-series')?.children;
    if (series?.length) {
      Array.from(series).forEach((item: any, i: number) => {
        const barHeight = item.getAttribute('barHeight');
       // if (barHeight > 300) {
        if (i !== index) {
          item.style.fill = '#F3F6F9';
          item.style.filter = 'none';
        } else {
          item.style.fill = 'rgba(0, 227, 150, 0.85)';
          item.style.opacity = '1';
          item.style.filter = 'none';
        }
      });
    }

    const plots: any = document.querySelector('.apexcharts-datalabels')?.children;

    if (plots?.length) {
      Array.from(plots).forEach((item: any, i: number) => {
        if (i !== index) {
          item.style.opacity = '0';
        } else {
          item.style.opacity = '1';
        }
      });
    }
  //}, 1000);
  }

  function getDonutChartData() {
    const userExamsCorrectAnswers = userResults?.map(item => item.correctAnswers || 0);
    const chartData: any = {
      options: {
        labels: ['Correct', 'Incorrect'], 
        tooltip: {
          y: {
            formatter: function(value: any) {
              return value.toFixed(2) + '%';
            }
          }
        },
        colors : ['rgba(0, 227, 150, 0.85)', '#b84644'], 
        legend: {position: 'bottom'} as any
      },
      series: [
        percent(userExamsCorrectAnswers?.reduce((acc, currentValue) => acc + currentValue, 0), userResults!.reduce((acc, currentValue) => acc + currentValue.questions!.length, 0)),
        percent(userResults?.map(item => item!.questions!.length - (item.correctAnswers || 0))?.reduce((acc, currentValue) => acc + currentValue, 0), userResults!.reduce((acc, currentValue) => acc + currentValue.questions!.length, 0))
       ],
    }

    chartData.totalExams = userResults?.length;

    return chartData;
  }

  function buildLastChart(allExams: Exam[], userResults:ExamResult[]) {
    const graphData: any[] = [];
    userResults?.forEach(result => {
      result.questions?.forEach(resultQuestion => {
        const foundItem = graphData.find(item => item.subSubject === resultQuestion?.subSubject);
        const answeredCorrecty = ArrayUtils.answeredCorrectly(resultQuestion!, result);
        if (foundItem) {
          if (answeredCorrecty) foundItem.correctAnswers++;
          foundItem.total++;
        } else {
          graphData.push({subSubject: resultQuestion?.subSubject, total: 1, correctAnswers: answeredCorrecty ? 1 : 0});
        }
      });
    });

    //console.log(graphData)
    const categories: any = graphData.map(item => subSubjects.find(s => s.id === item.subSubject)?.name || 'none');
    const series = [
      {name: 'Correct Answers', type: 'bar', data: graphData.map(item => item.subSubject + ':' + item.correctAnswers) },
      {name: 'Wrong Answers', type: 'bar', data: graphData.map((item: any) => item.subSubject + ':' + (item.total - item.correctAnswers)) },
    ]

    //console.log(series)

    const dat: any = {
      options: {
        colors : ['rgba(0, 227, 150, 0.85)', '#b84644'],
        chart: {id: 'chart-line'},
        stacked: false,
        xaxis:  {categories},
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#008FFB',
              }
            },
          }
        ],
      },
      series,
    };

    if (isMobileOnly) {
      dat.options.plotOptions = {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      }
    }

    //console.log(dat)

    setLastChartData(dat);
  }

  function buildChartData() {
    if (!selectedExamTypeResults) return;
    const examTypeResults = [...selectedExamTypeResults] as ExamResult[];
    const categories: any = examTypeResults?.map(item => format(item.submitted_date instanceof Date ? item.submitted_date : item.submitted_date.toDate(),  'd/MM/yy HH:mm'));
    //const categories: any = selectedExamResults?.map(item => item.id);

    const series = [
      {
        name: 'Correct Answers',
        type: 'bar',
        data: examTypeResults?.map((item, i: number) => {
          return {x: i, y: percentString(item.correctAnswers, item?.questions?.length)} //columnWidthOffset: -10
        })
      },
      //{name: 'Wrong Answers', type: 'bar', data: selectedExamResults?.map((item: any) => 0) },
    ]

    const dat: any = {
      options: {
        colors : ['rgba(0, 227, 150, 0.85)'],
        legend: {
          show: false,
          onItemHover: {
            highlightDataSeries: false,
          }
        },
        tooltip: {
          enabled: false,
        },
        chart: {
          id: 'chart-line',
          toolbar: {
            show: false,
            tools: {
              zoom: false,
              //selection: true,
            }
          },
          legend: {
            show: false,
            onItemHover: {
              highlightDataSeries: false,
            }
          },
          events: {
            updated: (chart: any) => {
              onSelectCol(0);
              setAlreadyUpdated(true);
            },
            mounted: (chart: any) => {
              onSelectCol(0)
            },
            dataPointSelection: (event: any, chartContext:any, config: any) => {
              //console.log(chartContext.w.globals.labels[config.dataPointIndex])
              //config.config.xaxis.categories[config.dataPointIndex];
              onSelectCol(config.dataPointIndex);
              setTimeout(() => {
                onSelectCol(config.dataPointIndex);
              }, 1);
            },
          },
          states: {
            active: {
              filter: {
                type: 'none' /* none, lighten, darken */
              }
            }
          },
          selection: {
            enabled: true,
            type: 'y',
            fill: {
              color: '#24292e',
              opacity: 0.1
            },
            stroke: {
              width: 1,
              dashArray: 3,
              color: '#24292e',
              opacity: 0.4
            },
            xaxis: {
              min: undefined,
              max: undefined
            },
            yaxis: {
              min: undefined,
              max: undefined
            }
          }
        },
        stacked: false,
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            //columnWidth: '20%',
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            foreColor: '#474850',
            padding: 11,
            //borderRadius: 10,
            //borderWidth: 0
          },
          offsetY: -56,
          style: {
            enabled: true,
            colors: ['#F3F6F9']
          },
          formatter: (val: any, opts: any) => {
            const resp = examTypeResults?.map(item => format(item.submitted_date instanceof Date ? item.submitted_date : item.submitted_date.toDate(), 'd/MM/yy HH:mm'))[opts.dataPointIndex];
            return resp?.split(' ');
            //return selectedExamResults?.[opts.dataPointIndex].id;
          }
        },
        xaxis:  {
          //floating: true,
          categories,
          //forceNiceScale: true,
          tooltip: {
            enabled: false
          },
          labels: {
            show: false,
          }
        },
        yaxis: [
          {
           /*  axisBorder: {
              show: true,
              color: '#008FFB'
            }, */
            //forceNiceScale: true,
            //min: 10,
            max: 100,
            //floating: true,
            tooltip: {
              enabled: false
            },
            labels: {
              show: false,
              style: {
                colors: '#008FFB',
              },
              formatter: function(value: any) {
                return  value + '%';
              }
            },
            formatter: (val: number, opts: any) => {
              return val + '%';
            }
          }
        ]
      },
      series,
    };

    return dat;
  }

  function getWidthOffset() {
    if (!selectedExamTypeResults?.length) return '30px';
    switch (selectedExamTypeResults?.length) {
      case 1: return '41px';
      case 2: return '41px';
      case 3: return '163px';
      case 4: return '112px';
      case 5: return '41px';
      case 6: return '41px';
      case 7: return '41px';
      case 8: return '38.75px';

      default:
        break;
    }
  }

  function getPaddingOffset() {
    if (!selectedExamTypeResults?.length) return '30px';
    switch (selectedExamTypeResults?.length) {
      case 1: return '42px';
      case 2: return '42px';
      case 3: return '98px';
      case 4: return '80px';
      case 5: return '42px';
      case 6: return '42px';
      case 7: return '42px';
      case 8: return '53px';

      default:
        break;
    }
  }

  function updateSelected(type: ExamType) {
    //setAlreadyUpdated(false);
    updateSelectedExamResults(type.id)
    /* setTimeout(() => {
      onSelectCol(0);
    }, 1000); */
  }

  function goToPage(route: string) {
    navigate(route);
  }

  function getChartHeight() {
    if (lastChartData?.series?.[0].data?.length) {
      return lastChartData?.series?.[0].data?.length * 36;
    } else {
      return 500;
    }
  }

  function filterOptionsXAxisBySubject(subject: string) {
    const filtered = JSON.parse(JSON.stringify(lastChartData.options));
    filtered.xaxis.categories = lastChartData?.options?.xaxis?.categories?.filter((subS: any) => selectedSubjectLastChart === subSubjects.find(item => item.name === subS)?.subjectId);
    //"Scale readings and number Lines "
    filtered.xaxis.categories = filtered.xaxis.categories.map((item: any) => {
      const totalSpaces = item.split(' ').length - 1;
      const splitIndex = Math.floor(totalSpaces / 2);
      const itemShort = item.length >= 22 ? (item.substring(0, 22) + '...') : item;
      //return item.length > 25 ? itemShort.split(' ', splitIndex + 1) : itemShort;
      return itemShort;
    });
    return filtered;
  }

  function filterSeriesBySubject(subject: string) {
    const filtered = JSON.parse(JSON.stringify(lastChartData.series));
    filtered[0].data = lastChartData?.series[0]?.data?.filter((subS: any) => selectedSubjectLastChart === subSubjects.find(item => item.id === subS.split(':')[0])?.subjectId).map((item: any) => Number(item.split(':')[1]));
    filtered[1].data = lastChartData?.series[1]?.data?.filter((subS: any) => selectedSubjectLastChart === subSubjects.find(item => item.id === subS.split(':')[0])?.subjectId).map((item: any) => Number(item.split(':')[1]));

    return filtered;
  }

  async function updateQuestionSubject(e: any) {
    //console.log(e.target.value)
    setSelectedSubjectLastChart(e.target.value);
   /*  const questionCopy = { ...selectedQuestion } as Question;
    const oldSubject = questionCopy.subject;
    if (selectSubjectPlaceholder === e.target.value) {
      questionCopy.subject = undefined;
    } else {
      questionCopy.subject = e.target.value;
      setSelectedSubjectSubs(subSubjects.filter((s) => s.subjectId === e.target.value));
    }

    setSelectedQuestion(questionCopy); */
  }

  return (
    <ProtectedPages>
      <section id="home">
        <div className='widgets-container'>
          <div className="welcome-box">
            <div className="title-welcome">
              <span className="first-line">Hello, {user?.firstName}</span>
              <span className="second-line">Welcome back!</span>
            </div>

            <div className="welcome-data">
              <span>Welcome back to Mock Exam Centre, ignite your academic journey by clicking the button below to start!</span>
            </div>

            <Button onClick={() => goToPage('/exams-list')} variant="light" className="try-btn">
              <span>Let's try it!</span>
              <img src='/icons/double-arrow.svg' />
            </Button>
          <div>

        </div>
        </div>
          {/* <CalendarWidget title="Calendar" isDate={true} subtitle='Next exams' text1='Calendar' text2='Placeholder text 2'/> */}
        </div>


        <div className='widgets-container'>
          {allExams && width >= 720 && <div className='overtime-chart'>
           <header className='overtime-header'>
              <span className='progress-title'>Progress Score</span>
              {selectedExamResult?.questions?.length &&<div className='score-box'>
                <div className='header-item'>
                  <span>Hits</span>
                  <span>{percentString(selectedExamResult?.correctAnswers, selectedExamResult?.questions?.length)}</span>
                </div>
                <div className='header-item'>
                  <span>Misses</span>
                  <span>{percentString(selectedExamResult.questions?.length - selectedExamResult?.correctAnswers, allExams.find(item => item.id === selectedExamResult?.examId)?.questions?.length)}</span>
                </div>
              </div>}
            </header>

            <div className='left-container'>
              <div className='left-panel'>
                {examTypesForUser?.map(type =>
                  <span key={type.id} onClick={() => updateSelected(type)} className={selectedExamTypeResults?.[0]?.examType === type.id ? 'selected': ''}>{type.name}</span>
                  )}
              </div>

              <div>
              {selectedExamTypeResults?.length ? <ChartLoader
                  options={buildChartData().options}
                  series={buildChartData().series}
                  type="bar"
                  height={335}
                  width={375}
                  />
                  : <div className='no-data bar-chart'>No data</div>
                }

            {/*    <div className='labels-panel' style={{gap: getWidthOffset(), paddingLeft: getPaddingOffset()}} >
                {selectedExamResults?.map((item: any) => item.correctAnswers)?.map((item, i) => 
                  <span key={i} className={item === 0 ? 'zero-value ': ''}>{item}</span>
                )}
                </div> */}
              </div>
            </div>
          </div>}

          {!!userResults?.length && <DonutChart title='Performance' data={getDonutChartData()}/>}
        </div>

        <div className='subsubject-chart'>
          <div className='sub-title'>Performance by Subjects</div>
          <select className="form-select form-select-lg form-select-solid" data-control="select2" data-placeholder="Select Type..." ref={subjectRef} value={selectedSubjectLastChart} onChange={updateQuestionSubject}>
            {subjects?.map((subject: Subject, i: number) => (
              <option key={'subj' + subject.id} value={subject.id}>
                {subject.name}
              </option>
            ))}
          </select>
          {allExams?.length && lastChartData?.options?.xaxis?.categories && lastChartData?.series[0]?.data?.length && userResults?.length && filterOptionsXAxisBySubject(lastChartData.options)?.xaxis?.categories?.length ? (
            <ChartLoader
              options={filterOptionsXAxisBySubject(lastChartData.options)}
              series={filterSeriesBySubject(lastChartData.series)}
              type="bar"
              width={isMobileOnly ? undefined : lastChartData?.options?.xaxis?.categories?.length * (lastChartData?.options?.xaxis?.categories?.length > 20 ? 77 :40)}
              height={isMobileOnly ? getChartHeight() : 400}
            />
          ) : (
            <div className='no-data bar-chart'>No data</div>
          )}
        </div>

      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      </section>
    </ProtectedPages>
  )
}
export default Home;
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, sendPasswordResetEmail } from 'firebase/auth';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAuthState } from '../../services/firebase';
import { useUserState } from '../../services/User';
import { Utils } from '../../util/string-utils';
import { ToastMessage } from '../toast/ToastMessage';
import './parentalPassword.scss';

type Props = {
  onSuccess?: any;
  onFailure?: any;
};

export const ParentalPassword = forwardRef((props: Props, ref) => {
  const toastRef: any = useRef(null);
  const user: any = useUserState();

  useImperativeHandle(ref, () => ({
    handleShow,
    handleClose,
  }));

  const [showAuthModal, setShowAuthModal] = useState(false);

  const [accountPassword, setAccountPassword] = useState<string>('');
  const [parentalPassword, setParentalPassword] = useState<string>('');
  const handleClose = () => setShowAuthModal(false);
  const handleShow = () => setShowAuthModal(true);

  function handleStringField(e: any, field: string) {
    const value = e.target.value;
    //setState(value);
    if (field == 'accountPassword') {
      setAccountPassword(value);
    } else if (field == 'parentalPassword') {
      setParentalPassword(value);
    }
  }


  const reAuth = async () => {
    const auth = getAuth()

    try {
      const currentUser = auth.currentUser;

      if (currentUser) {
        const credential = EmailAuthProvider.credential(user.login + '@mockexamcentre.com', accountPassword);
        const result = await reauthenticateWithCredential(currentUser, credential);
        handleClose();
        props.onSuccess();
      }
    } catch (e: any) {
      toastRef.current.toggleShow(Utils.formatErrorMsg(e.message), true);
      props.onFailure();
    }
  };

  return (
    <section id="">
      <Modal show={showAuthModal} onHide={handleClose} centered dialogClassName="global-modal parental-password" backdropClassName="newBackdrop">
        <Modal.Header>
          <Modal.Title>Please enter the passwords to confirm the profile changes</Modal.Title>
        </Modal.Header>
        <ToastMessage ref={toastRef} />

        <Modal.Body>
          <div className="fields-panel pwd-reset">
            <div>
              <label className="form-label fw-bolder text-dark">Account Password</label>
              <input
                className="input-field form-control form-control-lg form-control-solid"
                name="accountPassword"
                placeholder="Account Password"
                type="password"
                value={accountPassword}
                autoComplete="new-password"
                onChange={(e) => handleStringField(e, 'accountPassword')}
              />
            </div>
            <div>
              <label className="form-label fw-bolder text-dark">Parental Password</label>
            <input
              className="input-field form-control form-control-lg form-control-solid"
              name="parentalPassword"
              placeholder="Parental Password"
              type="password"
              value={parentalPassword}
              autoComplete="new-password"
              onChange={(e) => handleStringField(e, 'parentalPassword')}
            />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button className="negative-btn btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="main-btn btn" onClick={reAuth}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
});

import { createContext, useContext, useEffect, useState } from 'react';
import { db } from './firebase';
import { collection, doc, getDocs, onSnapshot, query } from 'firebase/firestore';
import { Exam } from '../models/exam';
import { ExamType } from '../models/exam-type';
import { Subject } from '../models/subject';
import { SubSubject } from '../models/sub-subject';
import { set } from 'cypress/types/lodash';
import { differenceInMinutes } from 'date-fns';

export const StaticContext = createContext({});

type StaticDataState = {
  examTypes: ExamType[];
  subjects: Subject[];
  subSubjects: SubSubject[];
};

export const StaticContextProvider = (props: any) => {
  const [staticData, setStaticData] = useState<StaticDataState>();

  useEffect(() => {
    fetchApi();
  }, []);

  async function fetchApi() {
    let examTypes: ExamType[] = [];
    let subjects: Subject[] = [];
    let subSubjects: SubSubject[] = [];

    //if (!localStorage.getItem('staticData')) {
      const fetchExamTypes = getDocs(query(collection(db, 'exam-type'))).then((querySnapshot) => {
        querySnapshot.forEach((doc) => examTypes.push(doc.data() as ExamType));
      });

      const fetchSubjects = getDocs(query(collection(db, 'subject'))).then((querySnapshot) => {
        querySnapshot.forEach((doc) => subjects.push(doc.data() as Subject));
      });

      const fetchSubSubjects = getDocs(query(collection(db, 'sub-subjects'))).then((querySnapshot) => {
        querySnapshot.forEach((doc) => subSubjects.push(doc.data() as SubSubject));
      });

      Promise.all([fetchExamTypes, fetchSubjects, fetchSubSubjects]).then(() => {
        const data = {
          ...staticData,
          date: new Date(),
          examTypes: examTypes.sort((a, b) => (a.order > b.order ? 1 : -1)),
          subjects: subjects.sort((a, b) => (a.name > b.name ? 1 : -1)),
          subSubjects: subSubjects.sort((a, b) => (a.name > b.name ? 1 : -1)),
        };

        localStorage.setItem('staticData', JSON.stringify(data));
        setStaticData(data);
      });
   /*  } else {
      const localData = localStorage.getItem('staticData');
      if (localData) {
        setStaticData(JSON.parse(localData));
      }
    } */
  }

  return <StaticContext.Provider value={{ staticData }} {...props} />;
};

export const useStaticState = () => {
  const data: any = useContext(StaticContext);
  return (data?.staticData as StaticDataState) || {};
};

import { getYear } from 'date-fns';
import { useRef } from 'react';
import { AcceptableUsePolicy } from '../pdf-modal/acceptable-use-policy/acceptable-use-policy';
import { CookiePolicy } from '../pdf-modal/cookie-policy/cookie-policy';
import { PdfModal } from '../pdf-modal/PdfModal';
import { PrivacyPolicy } from '../pdf-modal/privacy-policy/privacy-policy';
import { TermsAndConditions } from '../pdf-modal/terms-and-conditions/terms-and-conditions';
import './footer.scss';
import { useState } from 'react';
import { SchoolModal } from '../SchoolModal/SchoolModal';
import { Tiffin } from '../SchoolModal/tiffin/tiffin';
import { Sutton } from '../SchoolModal/sutton/sutton';
import { GLAssessment } from '../SchoolModal/gl-assessment/GlAssessment';

export function Footer() {
  const year = getYear(new Date());
  const pdfModal: any = useRef(null);
  const schoolModal: any = useRef(null);
  const [modalTitle, setModalTitle] = useState('Terms and Conditions');

  return (
    <section id="footer">
      <footer className="footer">
        <div className="footer-container">
          <div className="first-session-footer">
            <img loading='lazy' className='img-logo-footer' src="/media/images/logo-footer.png" alt="" />

            <div className="media-icons">
              <img loading='lazy' src="/media/images/instagram.svg" alt="instagram-icon" onClick={() => window.open('https://www.instagram.com/mockexamcentre/')} />
              <img loading='lazy' src="/media/images/facebook.svg" alt="facebook-icon" onClick={() => window.open('https://www.facebook.com/MockExamCentre')} />
              <img loading='lazy' src="/media/images/linkedin.svg" alt="linkedin-icon" onClick={() => window.open('https://www.linkedin.com/company/mockexamcentre/')} />
              {/* <img loading='lazy' src="/media/images/twitter.svg" alt="twitter-icon"  /> */}
              <img loading='lazy' src="/media/images/youtube.svg" alt="youtube-icon" onClick={() => window.open('https://www.youtube.com/@MockExamCentre')} />
            </div>

            <div>
              <span className="first-session-text">
              {/*  <span className='eleven'><strong>Eleven Plus Centre</strong>, <br /></span>  */}
                Vista Business Centre <br />
                50 Salisbury Rd, <br/>
                Hounslow<br/> TW4 6JQ
              </span>
            </div>
          </div>

          <div className="second-session-footer">
            <span className="title-second-session-footer">Company</span>
            <span className="text-second-session-footer" id="aboutus-footer">About Us</span>
            <span className="text-second-session-footer">Blog</span>
            <span className="text-second-session-footer" id="pricing-footer">Prices</span>
            <span className="text-second-session-footer" onClick={() => {pdfModal?.current?.handleShow(<TermsAndConditions />); setModalTitle('Terms and Conditions')}}>Terms and Conditions</span>
            <span className="text-second-session-footer" onClick={() => {pdfModal?.current?.handleShow(<CookiePolicy />); setModalTitle('Cookie Policy')}}>Cookie Policy</span>
            <span className="text-second-session-footer" onClick={() => {pdfModal?.current?.handleShow(<AcceptableUsePolicy />); setModalTitle('Acceptable Use Policy')}}>Acceptable Use Policy</span>
            <span className="text-second-session-footer" onClick={() => {pdfModal?.current?.handleShow(<PrivacyPolicy />); setModalTitle('Privacy Policy') }}>Privacy Policy</span>
          </div>

          <div className="third-session-footer">
            <span className="title-second-session-footer">Resources</span>
            <span className="text-second-session-footer" onClick={() => {schoolModal?.current?.handleShow(<GLAssessment />); setModalTitle('GL Assessment') }}>GL Assessment</span>
            <span className="text-second-session-footer" onClick={() => {schoolModal?.current?.handleShow(<Tiffin />); setModalTitle('Tiffin & Tiffin Girls’') }}>Tiffin & Tiffin Girls’</span>
            <span className="text-second-session-footer" onClick={() => {schoolModal?.current?.handleShow(<Sutton />); setModalTitle('Sutton SET') }}>Sutton SET</span>
          </div>
          <div className="fourth-session-footer">
           {/*  <span className="text-fourth-session-footer">0800 112 3811</span> */}
            <span>info@mockexamcentre.co.uk</span>
          </div>
        </div>

        <div className="website-information">
          <span>
            ©{year} <strong>Mock Exams Centre</strong> Technology & Solution. All rights reserved.
          </span>
        </div>
      </footer>

      <PdfModal ref={pdfModal} title={modalTitle}/>
      <SchoolModal ref={schoolModal} title={modalTitle}></SchoolModal>
    </section>
  );
}

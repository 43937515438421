import { ExamType } from '../../models/exam-type';
import { Utils } from '../../util/string-utils';
import './PlanCard.scss';

type Props = {
  examType: ExamType;
  onChoosePlan?: any;
  monthly: boolean;
};

const PlanCard: React.FC<Props> = (props: Props) => {
  console.log(props.examType);
  return (
    <section id="glacard">
      <div className="plan-box-1">
        <span className="plan-box-1-line-1">
          <span className="faded">£{(props.monthly ? props.examType?.monthlyPrice * 1.2 : props.examType?.yearlyPrice * 1.2).toFixed(2)}</span>
          <span>£{(props.monthly ? props.examType?.monthlyPrice : props.examType?.yearlyPrice )}</span>
        </span>
        <div className="plan-box-1-line-2">{props.examType?.name}</div>

        <div className="plan-box-1-text">
          <div className="plan-box-text">
            <img loading="lazy" src="/media/images/check-circle.png" alt="check-circle.png" />
            <span className="plan-box-1-line-4"> 80 High quality and realistic exam papers</span>
          </div>

          <div className="plan-box-text">
            <img loading="lazy" src="/media/images/check-circle.png" alt="check-circle.png" />
            <span className="plan-box-1-line-4">
              {props.examType?.id === 'Wa1XNOCHd6OqZ29M9K4D' ? 'English, Maths, VR and NVR (coming soon)' : 'English and Maths'}
            </span>
          </div>
          <div className="plan-box-text">
            <img loading="lazy" src="/media/images/check-circle.png" alt="check-circle.png" />
            <span className="plan-box-1-line-4">Written Solutions</span>
          </div>
          <div className="plan-box-text">
            <img loading="lazy" src="/media/images/check-circle.png" alt="check-circle.png" />
            <span className="plan-box-1-line-4">Video Solutions</span>
          </div>
        </div>

        <button id="choose-plan-button" className="plan-box-1-button" onClick={() => props?.onChoosePlan(Utils.getLinkByExamType(props.examType, props.monthly))}>
          <span className="text-button">Choose plan</span>
        </button>
      </div>
    </section>
  );
};
export { PlanCard };

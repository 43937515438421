import { ExamType } from '../../models/exam-type';
import { useStaticState } from '../../services/StaticContext';
import { Utils } from '../../util/string-utils';
import { PlanCard } from '../plan-card/PlanCard';
import './PaymentPlans.scss';

type Props = {
 onChoosePlan?: any
 monthly: boolean;
};

const PaymentPlans: React.FC<Props> = (props: Props) => {
  const { examTypes } = useStaticState();

  return (
    <section id="paymentplans">
      <div className="plan-box">
        <PlanCard monthly={props.monthly} onChoosePlan={props?.onChoosePlan} examType={examTypes?.[0]} />

        <div className="plan-box-2">
          <div className="first-section-plan-box-2 ">
            <button className="plan-box-2-button">
              <span className="first-text-button">MOST POPULAR </span>
            </button>
            <span className="plan-box-2-line-2"><span className='faded'>{props.monthly ? '£49.99' : '£499.99'}</span> <span>{props.monthly ? '£34.99' : '£375'}</span></span>
            <div className="plan-box-2-line-2">Complete Package</div>
          </div>

          <div className="plan-box-1-text">
            <div className="plan-box-text">
              <img src="/media/images/check-circle-white.png" alt="check-circle.png" />
              <span className="plan-box-2-line-4">500 High quality and realistic exam papers</span>
            </div>
            <div className="plan-box-text">
              <img src="/media/images/check-circle-white.png" alt="check-circle.png" />
              <span className="plan-box-2-line-4">English, Maths, VR and NVR (coming soon)</span>
            </div>
            <div className="plan-box-text">
              <img src="/media/images/check-circle-white.png" alt="check-circle.png" />
              <span className="plan-box-2-line-4">Written Solutions</span>
            </div>

            <div className="plan-box-text">
              <img src="/media/images/check-circle-white.png" alt="check-circle.png" />
              <span className="plan-box-2-line-4">Video Solutions</span>
            </div>
          </div>

          <button className="plan-box-2-second-button" onClick={() =>  props?.onChoosePlan(Utils.getLinkByExamType(null, props.monthly))}>
            <span className="first-text-button">Choose plan</span>
          </button>
        </div>

        <PlanCard monthly={props.monthly} onChoosePlan={props?.onChoosePlan} examType={examTypes?.[1]}/>
        <PlanCard monthly={props.monthly} onChoosePlan={props?.onChoosePlan} examType={examTypes?.[2]}/>
      </div>
    </section>
  );
}
export { PaymentPlans };
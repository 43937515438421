import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuthState } from './firebase'
import { useEffect } from 'react';

const ProtectedRoutes = (props: any) => {
  const { isAuthenticated, isLoading, user } = useAuthState()
  const location = useLocation();

  window.localStorage.setItem('currentNav', location.pathname.replace('/', ''));

  useEffect(() => {
    if (location.search.includes('facebook')) {
      window.localStorage.setItem('campaign', 'facebook');
    }
  }, [location]);

/*   if (isAuthenticated && !isLoading) {
    window.localStorage.setItem('currentNav', 'dashboard');
  } */

  return isAuthenticated || isLoading
    ? <Outlet />
    : <Navigate to="/login" />
}

export default ProtectedRoutes

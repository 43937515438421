import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const isMobile = width <= 1024;
  const isTablet = width <= 1024;
  const isMobileOnly = width < 480;
  const isTabletPortrait = width < 909; //fold 3 923, 571
  const isDesktop = width >= 1280;
  return {
    width,
    height,
    isMobile,
    isTablet,
    isDesktop,
    isMobileOnly,
    isTabletPortrait
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
import React, { useEffect, useRef, useState } from 'react';
import { getAuth, signOut, updatePassword } from 'firebase/auth';
import './ExamsManagement.scss';
import { ProtectedPages } from '../../layouts/admin/ProtectedLayout';
import { TableWidget } from '../../components/table-widget/TableWidget';
import { useNavigate } from 'react-router-dom';
import { useUserState } from '../../services/User';
import { collection, doc, orderBy, query, setDoc, where } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { QueryUtils } from '../../util/query-utils';
import { useStaticState } from '../../services/StaticContext';
import { Exam } from '../../models/exam';
import { ExamType } from '../../models/exam-type';
import { WidgetBox } from '../../components/widget-box/WidgetBox';
import { Button } from 'react-bootstrap';
import { ArrayUtils } from '../../util/array-utils';
import { Role } from '../../models/role.enum';
import { useLoader } from '../../services/LoaderProvider';

const ExamsManagement = () => {
  const user = useUserState();
  const navigate = useNavigate();
  const { startLoader, stopLoader } = useLoader();
  const { examTypes, subjects } = useStaticState();
  const [examList, setExamList] = useState<Exam[]>();

  useEffect(() => {
    startLoader();
    if (user?.id && examTypes && subjects) {
      getApi();
    }
  }, [user?.id, examTypes, subjects]);

  async function getApi() {
    const customQuery = query(collection(db, 'exam'), where('deleted', '==', false), orderBy('isPaid', 'asc'), orderBy('difficulty', 'asc'), orderBy('order', 'asc'));
    const exams = await QueryUtils.executeQuery(customQuery);

    exams.forEach((exam: any) => {
      if (!exam.order) {
        console.log(exam)
      }
    })
    setExamList(exams as any);
    stopLoader();
  }

  function createExam() {
    navigate('/exam-creation');
  }

  async function changeExamStatus(exam: Exam, online: boolean) {
    const examCopy = {...exam} as Exam;
    examCopy.isOnline = online;
    if (online && !examCopy.onlineOnce) {
      examCopy.published_date = new Date();
    }
    if (online) {
      examCopy.onlineOnce = true;
    }
    setExamList(ArrayUtils.updateObjectInArray(examList, examCopy));

    await setDoc(doc(db, 'exam', examCopy.id), examCopy);
  }

  return (
    <ProtectedPages>
      {(user.id && user.role == Role.ADMIN) && <section id="exams-management">
        <div className='add-new-container'>
          <button className='btn btn-primary' onClick={createExam}>Add new</button>
        </div>

        {examTypes?.map((type: ExamType, i: any) => (
          <TableWidget key={type.id} onStatusChange={changeExamStatus} exams={examList?.filter((e) => e.type === type.id)} isAdminPage={true} examType={type} />
        ))}

      </section>}
    </ProtectedPages>
  );
};
export default ExamsManagement;
export function GLAssessment() {
  return (
    <>
      <h1>GL assessment:</h1>
      <p>The GL Assessment 11+ tests are used to determine suitability for admission to selective secondary schools, including grammar schools and independent schools.</p>
      <p>The primary purpose of these tests is to assess a student's academic ability and potential. The exams are designed to identify students who have the aptitude for a more academically challenging education</p>
      <p>The GL Assessment 11+ tests typically cover core subjects such as English, mathematics, verbal reasoning, and non-verbal reasoning. These subjects assess a range of skills, including comprehension, problem-solving, logical reasoning, and critical thinking.</p>
      <p>The tests are typically administered in multiple-choice format, where students choose the correct answer from a set of options. The exams may consist of a combination of individual subject tests or a single composite test covering all subjects.</p>
      <p>Verbal reasoning tests assess a student's ability to understand and manipulate words and language. They often involve tasks like identifying word relationships, completing analogies, and solving puzzles based on language patterns.</p>
      <p>Non-verbal reasoning tests assess a student's ability to solve problems using visual and abstract reasoning rather than language. These tests may involve tasks such as identifying patterns, series completion, shape recognition, and spatial reasoning.</p>
      <p>
        It's important to note that different regions and schools may use different test providers or assessment methods for their 11+ exams. While the GL Assessment is a widely used test provider, specific schools may pick and choose formatting from the four key areas for their testing. Hence, it is essential to consult the specific requirements
        and guidelines provided by the schools or local education authorities in your area to understand the exact format and content of the 11+ exams your child will be undertaking.
      </p>
    </>
  );
}

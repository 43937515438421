import { useNavigate } from 'react-router-dom';
import './NotFound.scss';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <section id="notfound">
      <div className="container">
        <div className="first-line">404</div>
        <div className="second-line">
          Oops! 😖 The requested URL <br />
          was not found on this server.
        </div>
        <button className="btn">
          <span className="text-btn" onClick={() => navigate('/exams-list')}>Back to home</span>
        </button>

        <div className="logo">
          <img className="logo-mock" src="/media/images/blue-logo-mock.svg" />
        </div>
      </div>
    </section>
  );
};

import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './sidebar.scss';
import { Link, useLocation } from 'react-router-dom';
import { useUserState } from '../../services/User';
import { useAuthState } from '../../services/firebase';
import { getAuth, signOut } from 'firebase/auth';
import { Role } from '../../models/role.enum';
import useWindowDimensions from '../../services/window-dimensions';
import { useEffect, useState } from 'react';

const Sidebar = (props: any) => {
  const user = useUserState();
  const { height, width, isMobile, isTablet, isMobileOnly, isTabletPortrait, isDesktop } = useWindowDimensions();
  const { user: authUser, loading: isLoading } = useAuthState();
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState<any>('dashboard');

  const renderTooltip = (props: any, tooltip: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  );

  useEffect(() => {
    if (location.pathname) {
      setCurrentRoute(location.pathname.replace('/', ''));
    }
  }, [location]);

  function isActive(page: string) {
    if (currentRoute === page) {
      return ' active';
    } else {
      return '';
    }
  }

  function logout() {
    window.localStorage.removeItem('login');
    window.localStorage.clear();
    signOut(getAuth());
  }

  return authUser && !isLoading && !location.pathname.includes('exam-perform') && !location.pathname.includes('reset-password') && !location.pathname.includes('/landing') ? (
    <>
      <Nav className={'sidebar'} activeKey="/home" style={{ paddingTop: '3em' }}>
        <div className="sidebar-sticky">
          {isDesktop && <img alt="mock-logo" width={35} className="mock-logo" src="/logo-mock.png" />}
          {!isDesktop && <img alt="mock-logo-short" width={35} className="mock-logo-short" src="/logo-sidebar-short.png" />}

          <Nav.Item as={Link} to="/dashboard" className={'underline' + isActive('dashboard')}>
            <div className="icon-container">
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(e) => renderTooltip(e, 'Dashboard')}>
              <img className="icon-dashboard icons" alt="dashboard icon" height={20} src="/icons/icon-dasboard.png" />
              </OverlayTrigger>
            </div>
            {isDesktop && <span>Dashboard</span>}
          </Nav.Item>

          <Nav.Item as={Link} to="/profile" className={'underline' + isActive('profile')}>
            <div className="icon-container">
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(e) => renderTooltip(e, 'Profile')}>
              <img className="icon-profile icons" alt="dashboard icon" height={20} src="/icons/icon-profile.png" />
              </OverlayTrigger>

            </div>
            {isDesktop && <span>Profile</span>}
          </Nav.Item>

          <Nav.Item as={Link} to="/exams-list" className={'underline' + isActive('exams-list')}>
            <div className="icon-container">
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(e) => renderTooltip(e, 'Exams')}>
              <img className="icon-exams icons" alt="dashboard icon" height={20} src="/icons/ballot-check.png" />
              </OverlayTrigger>


            </div>
            {isDesktop && <span>Exams</span>}
          </Nav.Item>

          <Nav.Item as={Link} to="/help" className={'underline' + isActive('help')}>
            <div className="icon-container">

            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(e) => renderTooltip(e, 'Help')}>
              <img className="icon-help icons" alt="dashboard icon" height={20} src="/icons/icon-help.png" />
              </OverlayTrigger>

            </div>
            {isDesktop && <span>Help</span>}
          </Nav.Item>

          <Nav.Item onClick={logout} className={'underline' + isActive('logout')}>
            <div className="icon-container">

            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(e) => renderTooltip(e, 'Logout')}>
              <img className="icon-logout icons" alt="dashboard icon" height={20} src="/icons/icon-logout.png" />
              </OverlayTrigger>


            </div>
            {isDesktop && <span>Logout</span>}
          </Nav.Item>

          {user.role == Role.ADMIN && (
            <div className={"admin-options" + (isDesktop ? ' add-border' :'')}>
              <Nav.Item
                as={Link}
                to="/exams-management"
                className={'underline' + isActive('exams-management')}
              >
                <div className="icon-container">
                  <img className="icon-exams" alt="dashboard icon" height={20} src="/icons/list.svg" />
                </div>
                {isDesktop && <span style={{marginLeft: '-5px'}}>Manage Exams</span>}
              </Nav.Item>
            </div>
          )}

          {user.role == Role.ADMIN && (
            <div className={"admin-dash"}>
              <Nav.Item
                as={Link}
                to="/admin-dashboard"
                className={'underline' + isActive('admin-dashboard')}
              >
                <div className="icon-container">
                  <img className="icon-exams" alt="dashboard icon" height={20} src="/icons/grid2.svg" />
                </div>
                {isDesktop && <span style={{marginLeft: '-5px'}}>Admin Dashboard</span>}
              </Nav.Item>
            </div>
          )}
        </div>
      </Nav>
    </>
  ) : null;
};
//const Sidebar = withRouter(Side);
export default Sidebar;

import { EmailAuthProvider, getAuth, reauthenticateWithCredential } from 'firebase/auth';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './confirm-modal.scss'

type Props = {
  title: string,
  description?: string,
  imageUrl?: string,
  confirmBtn: string,
  onConfirm: any,
  onCancel?: any,
};

export const ConfirmModal = forwardRef((props: Props, ref) => {
  useImperativeHandle(ref, () => ({
    handleShow,
    handleCancel,
  }));

  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleCancel = () => {
    setShowAuthModal(false);
    if (props.onCancel) props.onCancel();
  };

  const handleConfirm = () => {
    setShowAuthModal(false);
    if (props.onConfirm) props.onConfirm();
  };

  const handleShow = () => setShowAuthModal(true);

  return (
    <section id="confirm-modal">
      <Modal show={showAuthModal} backdrop="static" onHide={handleCancel} centered dialogClassName="global-modal confirm-modal">
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='custom-success-content'>
            {props.imageUrl && <img className='cover-image' src={props.imageUrl} />}
            <span>{props.description}</span>
          </div>

          <div className='custom-success-footer'>
            <Button onClick={handleCancel} variant="light" className="back-btn btn-short">
                <span>Cancel</span>
              </Button>{' '}

            <Button id="button-start-modal"onClick={handleConfirm}>{props.confirmBtn}</Button>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  )
});

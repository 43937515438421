import { differenceInSeconds } from 'date-fns';
import { Exam } from '../models/exam';
import { ExamResult } from '../models/exam-result';
import { Answer } from '../models/answer';
import { v4 as uuidv4 } from 'uuid';
import { ExamType } from '../models/exam-type';

export abstract class Utils {
  public static SelectedSection = 'test';
  public static formatErrorMsg(errorMsg: string) {
    return errorMsg.replaceAll('Firebase: Error (', '').replace(')', '');
  }

  public static formatTime(time: string) {
    const minsLength = Number(time) >= 600 ? -2 : -1;
    const minutes = ('0' + Math.floor((Number(time) / 60) % 60)).slice(minsLength) + ':';
    const seconds = ('0' + Math.floor((Number(time) / 1) % 60)).slice(-2);

    return minutes + seconds;
  }

  public static getLetter(index: number) {
    const letterIndex = index;
    const letter = String.fromCharCode(letterIndex + 'A'.charCodeAt(0));
    return letter;
  }

  public static replaceDashWithSpace(str: string | null) {
    return str ? str.replace('-', ' ') : '';
  }

  public static isExamOnGoing(exam: Exam, examResult: ExamResult) {
    return !examResult.submitted_date && differenceInSeconds(new Date(), examResult.created_date.toDate()) < exam.timeMinutes * 60;
  }

  public static filterOutOnGoingExamsAndOld(exams: Exam[], examResults: ExamResult[]) {
    return examResults.filter((result) => {
      const foundExam: any = exams.find((exam) => exam.id === result.examId);
      return !this.isExamOnGoing(foundExam, result) && result.answers?.length;
    });
  }

  public static async handleCheckoutSimple(linkUrl: string, user: any) {
    const redirectUrl = `${linkUrl}?client_reference_id=${user?.id}`;

    window.location.href = redirectUrl;
  }

  public static isFaceToFace(exam: Exam | any) {
    return exam.type === '7yL8rJSN8Drr01fBtHDa';
  }

  public static faceToFaceExamObj() {
    return {
      id: '7yL8rJSN8Drr01fBtHDa',
      name: 'Face to Face',
      price: 0,
      order: 4,
      monthlyPrice: 0,
      yearlyPrice: 0,
    };
  }

  public static adminUserId = 'lE5aPVeEG1RplroFawUp01NM8xG2';

  public static convertFaceToFace(examResult: any) {
    const examValues = [];

    for (let i = 1; i <= 10; i++) {
      const key = `q_${i}`;
      if (key in examResult) {
        examValues.push(examResult[key]);
      }
    }

    const uid = uuidv4().replace(/-/gi, '');
    const resultObj = {
      id: uid,
      userId: examResult.candidateid,
      firstName: examResult.forename,
      examId: examResult.examId,
      lastName: examResult.surname,
      submitted_date: examResult.uploadDate,
      created_date: examResult.uploadDate,
      answers: examValues.map((e) => Utils.convertAnswer(e)),
    };
    return resultObj as ExamResult;
  }

  public static convertAnswer(rawAnswer: number) {
    const resp: Answer | any = {};
    resp.markedOptions = [rawAnswer];
    return resp;
  }

  public static getLinkByExamType(examType: ExamType | null, isMonthly: boolean) {
    console.log('examType', examType);
    if (examType?.id === 'Wa1XNOCHd6OqZ29M9K4D') {
      return isMonthly ? process.env.REACT_APP_LINK_GL_ASSESSMENT_MONTHLY : process.env.REACT_APP_LINK_GL_ASSESSMENT_YEARLY;
    } else if (examType?.id === 'cDaUveEJwqt084KzaCkI') {
      return isMonthly ? process.env.REACT_APP_LINK_TIFFIN_MONTHLY : process.env.REACT_APP_LINK_TIFFIN_YEARLY;
    } else if (examType?.id === 'GiXgZHoJfIwEY1ID2vrn') {
      return isMonthly ? process.env.REACT_APP_LINK_SUTTON_MONTHLY : process.env.REACT_APP_LINK_SUTTON_YEARLY;
    } else {
      return isMonthly ? process.env.REACT_APP_LINK_COMPLETE_PACKAGE_MONTHLY : process.env.REACT_APP_LINK_COMPLETE_PACKAGE_YEARLY;
    }
  }
}

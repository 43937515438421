import ChartLoader from '../../loaders/ChartLoader';
import './BarChart.scss';

type Props = {
  title?: string,
  data?: any,
};

export const BarChart= (props: Props) => {
  return (
    <section id="bar-chart">
      <div className="text-performance">
        <span className="first-line-performance">Most popular exams (Only first try counts)</span>
        {/* <span className="second-line-performance">Update 1 month ago</span> */}
      </div>
      <div className='chart-container'>
        {props.data && <ChartLoader
            height={320}
            options={props.data.options}
            series={props.data.series}
            type="bar"
            />
        }
      </div>
 
    </section>
  );
};

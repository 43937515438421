import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExamResult } from '../../models/exam-result';
import { useLoader } from '../../services/LoaderProvider';
import { useStaticState } from '../../services/StaticContext';
import './StandardDeviation.scss';
import { Exam } from '../../models/exam';

type Props = {
  exam?: Exam;
  examResults: ExamResult[];
  userCorrectAnswers: any;
  defaultResults: any;
};

const yourPositionColor = '#0249fb';
const belowAverageColor = '#EF7E7E';
const averageColor = '#FFC700';
const aboveAverageColor = '#67e1ae';

const StandardDeviation: React.FC<Props> = (props: Props) => {
  const { startLoader, stopLoader } = useLoader();
  const { examTypes, subjects } = useStaticState();
  const [meanValue, setMeanValue] = useState<number>(0);
  const [stdDeviation, setStdDeviation] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const w: any = window;

  useEffect(() => {
    if (props.examResults?.[0]?.questions?.length || props.defaultResults?.length) {
      calcDeviation(props.examResults);
    }
  }, [examTypes, subjects, props.userCorrectAnswers, props.examResults, props.defaultResults]);

  useEffect(() => {
    setTimeout(() => {
      makePositionOccupyEntireHeight();
      setLoading(false);
    }, 1000);
  }, []);

  function makePositionOccupyEntireHeight() {
    var gElement = document.querySelector('g[clip-path*="url("]');

    if (gElement) {
      var gs: any = gElement.querySelectorAll('g');

      gs.forEach((g: any) => {
        if (gs) {
          var paths = g.querySelectorAll('path');

          paths.forEach((path: any, index: any) => {
            var styleAttribute = path.getAttribute('fill');
            if (styleAttribute === yourPositionColor) {
              var currentHeight = path.getBBox().height;
              var scaleYValue = 1500 / currentHeight;

              var bbox = path.getBBox();
              var cx = bbox.x + bbox.width / 2,
                cy = bbox.y + bbox.height / 2; // finding center of element
              var scalex = 1,
                scaley = scaleYValue; // your desired scale
              var saclestr = scalex + ',' + scaley;
              var tx = -cx * (scalex - 1);
              var ty = -cy * (scaley - 1);
              var translatestr = tx + ',' + ty;

              path.setAttribute('transform', 'translate(' + translatestr + ') scale(' + saclestr + ')');
            }
          });
        }
      });
    } else {
      console.log('No matching <g> element found.');
    }
  }

  function findElementWithFill(array: any, targetFill: string) {
    for (const element of array) {
      if (element.fill === targetFill) {
        return element; // Found the element with the target fill
      }

      // If the element has children (assuming children is an array), recursively search them
      if (element && Array.isArray(element)) {
        const foundInChild: any = findElementWithFill(element, targetFill);
        if (foundInChild) {
          return foundInChild; // Found in the child elements
        }
      }
    }

    return null; // Element with the target fill not found
  }

  function calcDeviation(results: ExamResult[]) {
    let data: any;
    //let chart;
    let stndDev = 1;
    let mean = 0;
    let xMin = 0;
    let xMax = 4.1;
    let xLeft = 1;
    let xRight = 2;

    let userData = results?.length ? results.map((item) => item.correctAnswers).sort() : [];
    const sumTemp = userData?.length ? userData.reduce((total, num) => total + num) : 0;
    const meanTemp = userData ? sumTemp / userData.length : 0;

    if (meanTemp < 10 || userData.length < 4) {
      userData = props.defaultResults;
    }
    let sum = 0;

    for (let i = 0; i < userData.length; i++) {
      sum += userData[i];
    }

    mean = sum / userData.length;
    setMeanValue(Number(mean.toFixed(2)));

    let deviations = [];
    for (let i = 0; i < userData.length; i++) {
      deviations[i] = userData[i] - mean;
    }

    let squaredDeviations = [];
    for (let i = 0; i < deviations.length; i++) {
      squaredDeviations[i] = deviations[i] * deviations[i];
    }

    let sumSquaredDeviations = 0;
    for (let i = 0; i < squaredDeviations.length; i++) {
      sumSquaredDeviations += squaredDeviations[i];
    }

    let letiance = sumSquaredDeviations / (userData.length - 1);

    stndDev = Math.sqrt(letiance) || 0.5;
    setStdDeviation(stndDev);

    xMax = mean * 2;

    let zScore = 0;
    if (props.userCorrectAnswers > mean) {
      zScore = ((props.userCorrectAnswers - mean) / (results[0].questions!.length - mean)) * 100;
      zScore = mean + (zScore / 100) * (xMax - mean);
    } else if (props.userCorrectAnswers < mean) {
      zScore = ((props.userCorrectAnswers - 0) / (mean - 0)) * 100;
      zScore = 0 + (zScore / 100) * (mean - 0);
    } else {
      zScore = mean;
    }
    xLeft = zScore - xMax / 15;
    xRight = zScore + xMax / 15;

    w.google.charts.load('current', { packages: ['corechart'] });
    w.google.charts.setOnLoadCallback(() => prepareChart(data, xMin, xMax, xLeft, xRight, mean, stndDev));
  }

  function prepareChart(data: any, xMin: any, xMax: any, xLeft: any, xRight: any, mean: any, stndDev: any) {
    data = new w.google.visualization.DataTable();
    const options = setChartOptions(mean);
    addColumns(data);
    addData(data, xMin, xMax, xLeft, xRight, mean, stndDev);

    drawChart(xMin, xMax, mean, options, data);
  }

  function setChartOptions(mean: number) {
    return {
      legend: 'none',
      //colors: ['black'],
      fillOpacity: 1,
      hAxis: {
        //baselineColor: 'red',
        /* viewWindow: {
          min: 0,
          max: 180
        }, */
        //format: (t: any) => console.log(t),
        ticks: [0, mean, mean * 2],
        minorGridlines: {
          count: 5,
        },
      },
    };
  }

  function addColumns(data: any) {
    data.addColumn('number', 'X Value');
    data.addColumn('number', 'Y Value');
    //data.addColumn({ type: "boolean", role: "scope" });
    data.addColumn({ type: 'string', role: 'style' });
  }

  function addData(data: any, xMin: any, xMax: any, xLeft: any, xRight: any, mean: any, stndDev: any) {
    data.addRows(createArray(xMin, xMax, xLeft, xRight, mean, stndDev));
  }

  function createArray(xMin: any, xMax: any, xLeft: any, xRight: any, mean: any, stndDev: any) {
    let chartData: any = new Array([]);
    let index = 0;
    let enteredXLeft = 0;
    let offSetLeft = xLeft <= 1.5 ? (1.5) : xLeft;

    for (let i = xMin; i <= xMax; i += 0.1) {
      chartData[index] = [];
      chartData[index][0] = i;

      if (i >= offSetLeft && i <= xRight && enteredXLeft <= (xMax / 5)) {
        //&& enteredXLeft <= 3
        // User position
        chartData[index][1] = w.jStat.normal.pdf(i, mean, stndDev);
        chartData[index][2] = `opacity: 1; + color: ${yourPositionColor}; `;
        //console.log(chartData[index][2]);
        enteredXLeft++;
      } else {
        // Other positions
        chartData[index][1] = w.jStat.normal.pdf(i, mean, stndDev);
        if (percent(i, xMax) < 35) {
          // Lowest
          chartData[index][2] = `opacity: 0.5; + color: ${belowAverageColor}; `;
        } else if (percent(i, xMax) <= 45) {
          chartData[index][2] = `opacity: 0.5; + color: ${averageColor}; +  `;
        } else if (percent(i, xMax) <= 65) {
          chartData[index][2] = `opacity: 0.5; + color: ${averageColor}; `;
        } else {
          chartData[index][2] = `opacity: 0.5; + color: ${aboveAverageColor}; `;
        }
      }

      index++;
    }

    return chartData;
  }

  function percent(quantity: number, total: number) {
    return (quantity / total) * 100;
  }

  function drawChart(xMin: any, xMax: any, mean: any, options: any, data: any) {
    let container: any = document.getElementById('stand-chart');

    const chart = new w.google.visualization.AreaChart(container);

    chart.draw(data, options);
    //console.log(xMax);

    replaceXValue(xMax.toFixed(2), 140);
    replaceXValue(xMin, 70);
    replaceXValue(mean.toFixed(2), 100);
  }

  function replaceXValue(fromValue: number, toValue: number) {
    const results = Array.from(document.querySelectorAll('text')).forEach((el) => {
      if (el.textContent && Number(el.textContent).toFixed(2) == String(fromValue)) {
        //acc.push(el);
        //  console.log(el);
        el.innerHTML = String(toValue);
      }
    }, []);
    return results;
  }

  return (
    <section id="standard-deviation">
      <span>Mean: {meanValue}</span>
      {/*  <span>Standard deviation: {stdDeviation}</span> */}
      <span>Your score: {props.userCorrectAnswers}</span>
      <span>Total questions: {props.exam?.questions?.length}</span>
      <div className="graphic-legend-container">
        {/* <div className="graphic-legend">
          <div className="color-legend-above-average"></div>
          <span className="text-legend">Your position</span>
        </div> */}
        <div className="graphic-legend">
          <div style={{ backgroundColor: belowAverageColor }} className="color-legend-below-average"></div>
          <span className="text-legend">Below average</span>
        </div>
        <div className="graphic-legend">
          <div style={{ backgroundColor: averageColor }} className="color-legend-average"></div>
          <span className="text-legend">Average</span>
        </div>

        <div className="graphic-legend">
          <div style={{ backgroundColor: aboveAverageColor }} className="color-legend-your-position"></div>
          <span className="text-legend">Above average </span>
        </div>
      </div>

      <div className={"standardized-chart " + (loading ? 'hidden' : 'show')} id={'stand-chart'}></div>
      <div className="your-pos-div">
        <div style={{ backgroundColor: yourPositionColor }} className="color-legend-above-average"></div>
        <span className="text-legend">Your position</span>
      </div>
    </section>
  );
};

export default StandardDeviation;

import React, { useRef, useEffect } from 'react';
import { useStaticState } from '../../services/StaticContext';
import { PlanCard } from '../plan-card/PlanCard';
import './SwiperPayment.scss';
// DO NOT DELETE
import { register } from 'swiper/element/bundle';
register();

type Props = {
  onChoosePlan?: any;
  monthly: boolean;
};

export const SwiperPayment: React.FC<Props> = (props: Props) => {
  const buttonRef = useRef();
  const { examTypes } = useStaticState();

  return (
    <section id="swiper-payment">
      <swiper-container pagination="true" centeredSlides="true">
        <swiper-slide>
          <PlanCard monthly={props.monthly} onChoosePlan={props?.onChoosePlan} examType={examTypes?.[0]} />
        </swiper-slide>
        <swiper-slide>
          <div className="plan-box-2">
            <div className="first-section-plan-box-2 ">
              <button className="plan-box-2-button">
                <span className="first-text-button">MOST POPULAR </span>
              </button>
              <span className="plan-box-2-line-2"><span className='faded'>{props.monthly ? '£49.99' : '£499.99'}</span> <span>{props.monthly ? '£34.99' : '£375'}</span></span>
              <div className="plan-box-2-line-2">Complete Package</div>
            </div>

            <div className="plan-box-1-text">
              <div className="plan-box-text">
                <img src="/media/images/check-circle-white.png" alt="check-circle.png" />
                <span className="plan-box-2-line-4">500 High quality and realistic exam papers</span>
              </div>
              <div className="plan-box-text">
                <img src="/media/images/check-circle-white.png" alt="check-circle.png" />
                <span className="plan-box-2-line-4">English, Maths, VR and NVR (coming soon)</span>
              </div>
              <div className="plan-box-text">
                <img src="/media/images/check-circle-white.png" alt="check-circle.png" />
                <span className="plan-box-2-line-4">Written Solutions</span>
              </div>

              <div className="plan-box-text">
                <img src="/media/images/check-circle-white.png" alt="check-circle.png" />
                <span className="plan-box-2-line-4">Video Solutions</span>
              </div>
            </div>

            <button className="plan-box-2-second-button" onClick={() => props.onChoosePlan(props.monthly ? process.env.REACT_APP_LINK_COMPLETE_PACKAGE_MONTHLY : process.env.REACT_APP_LINK_COMPLETE_PACKAGE_YEARLY)}>
              <span className="first-text-button">Choose plan</span>
            </button>
          </div>
        </swiper-slide>
        <swiper-slide>
          <PlanCard monthly={props.monthly} onChoosePlan={props?.onChoosePlan} examType={examTypes?.[1]} />
        </swiper-slide>
        <swiper-slide>
          <PlanCard monthly={props.monthly} onChoosePlan={props?.onChoosePlan} examType={examTypes?.[2]} />
        </swiper-slide>
      </swiper-container>
    </section>
  );
};

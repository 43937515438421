import { ImgHTMLAttributes } from 'react';
import './WidgetBox.scss';

type Props = {
  title: string;
  subtitle: string;
  text1: string;
  text2: string;
  text3?: string;
  hideImg?: boolean;
  isDate?: boolean;
};

export const WidgetBox = (props: Props) => {
  return (
    <section id="widget-box">
      <div className="performance-box">
        <div className="box-performance-data">
          <div className="title-performance">
            {/* {!props.hideImg && <img className="performance-graphic-icon" src="/icons/icon-performace.png" alt="icon-performace" />} */}
            {!props.hideImg && <img src="/icons/calendar-box.svg" alt="calendar-icon" />}

            <div className="text-performace">
              <span className="first-line-performance">{props.title}</span>
              <span className="second-line-performance">{props.subtitle}</span>
            </div>
          </div>

          {!props.isDate && (
            <div className="performance-data">
              <span>{props.text1}</span>
              <span>{props.text2}</span>
              {props.text3 && <span>{props.text3}</span>}
            </div>
          )}

          {props.isDate && (
            <>
              <div className="date-panel">
                <span className="date-text">21/10</span>
                <div className="images">
                  <img src="icons/circle.svg" />
                  <img src="icons/vertical-line.svg" />
                </div>
                <span className="exam-text">Math exam</span>
              </div>
              <div className="date-panel">
                <span className="date-text">02/11</span>
                <div className="images">
                  <img src="icons/circle.svg" />
                  <img src="icons/vertical-line.svg" />
                </div>
                <span className="exam-text">Spelling exam</span>
              </div>
              <div className="date-panel">
                <span className="date-text">26/11</span>
                <div className="images">
                  <img src="icons/circle.svg" />
                  <img src="icons/vertical-line.svg" />
                </div>
                <span className="exam-text">Math exam</span>
              </div>
            </>
          )}
        </div>
        <div></div>
      </div>
    </section>
  );
};

import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthState } from '../../services/firebase';
import './auth.scss';

export const Auth = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user: authUser, loading: isLoading } = useAuthState();

  return (!authUser?.uid ||location.pathname.includes('reset-password')) && !isLoading ? (
    <>
      <div style={{height: '100vh'}} className="d-flex flex-root">
        <div className="login-container">
        <div className="d-flex flex-column bg-image blue-panel" onClick={() => window.location.href = 'https://mockexamcentre.co.uk/'}>
            <div className="d-flex flex-row-fluid flex-center text-center mb-5">
              {/*    <h3 className="fw-bolder text-dark display-6 main-title">Mock Exam Centre</h3> */}

              <div className="login-elements">
                <img className="logo-mock" src="/logo-mock.svg" alt="logo-mock" />

                <h3 className="fw-light marketing-text">
                  Professionally written, <br />
                  realistic & school specific <br /> test papers.
                </h3>
              </div>
            </div>

            <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
              <img className="login-image" src="/media/images/login-image.svg" alt="login-image.png" />
            </div>
          </div>

          {children}
        </div>
      </div>
    </>
  ) : null;
};

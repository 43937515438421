export function Sutton() {
  return (
    <>
      <h1>Sutton SET:</h1>
      <p>The participating schools for the Selective Eligibility Test (known as the SET exams), are:</p>
      <ul>
        <li>Nonsuch High School for Girls</li>
        <li>Sutton Grammar School</li>
        <li>Wallington County Grammar School</li>
        <li>Wallington High School for Girls</li>
        <li>Wilson’s School.</li>
        <li>Greenshaw High School</li>
      </ul>

      <p>The exams consist of two multiple choice exam papers, each over a 45-minute period. Children who pass the first stage will be invited back for the second stage, which is an open-answer exam, with a creative writing element.</p>
      <p>
        The stage one Maths exam will test candidates in areas such as basic arithmetic, problem-solving and numerical reasoning. The English exam covers four key sections: spelling, two comprehension tests and one section where the two of the tests are compared. The stage two exams are non-multiple-choice tests, with comprehension and creative
        writing.
      </p>
      <p>The Sutton tests are written and arranged by the school themselves. They do not publish any practice papers nor provide much information other than what is on their websites.</p>
    </>
  );
}

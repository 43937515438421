import { differenceInSeconds } from 'date-fns';
import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { ArrowLeftShort, StarFill } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { GlaCard } from '../../components/answers-card/gla-card/GlaCard';
import { Type3AnswersCard } from '../../components/answers-card/sutton/SuttonCard';
import { StopWatch } from '../../components/stop-watch/StopWatch';
import { Exam } from '../../models/exam';
import { Question } from '../../models/question';
import { ExamResult } from '../../models/exam-result';
import { db } from '../../services/firebase';
import './ExamPerform.scss';
import { ConfirmModal } from '../../components/confirm-modal/ConfirmModal';
import { Subject } from '../../models/subject';
import { useStaticState } from '../../services/StaticContext';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ExamResultsModal } from '../../components/exam-results-modal/ExamResultsModal';
import { useUserState } from '../../services/User';
import { ToastMessage } from '../../components/toast/ToastMessage';
import { useLoader } from '../../services/LoaderProvider';
import { TiffinCard } from '../../components/answers-card/tiffin-card/TiffinCard';
import { ArrayUtils } from '../../util/array-utils';

const ExamPerform = () => {
  const navigate = useNavigate();
  const { startLoader, stopLoader } = useLoader();
  const user = useUserState();
  const stopWatchRef: any = useRef(null);
  const confirmSubmitRef: any = useRef(null);
  const examResultModalRef: any = useRef(null);
  const [reportText, setReportText] = useState<string>('');
  const toastRef: any = useRef(null);
  const [loadingReport, setLoadingReport] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  let count = 0;

  const [showZoomModal, setShowZoomModal] = useState(false);
  const handleClose = () => setShowZoomModal(false);
  const handleShow = () => setShowZoomModal(true);

  let { examId }: any = useParams();
  let { resultId }: any = useParams();
  let { resultCollection }: any = useParams();
  const [examSubjects, setExamSubjects] = useState<Subject[]>();
  const { examTypes, subjects, subSubjects } = useStaticState();
  const [selectedImage, setSelectedImage] = useState<any>();

  const [exam, setExam] = useState<Exam>();
  const [examResult, setExamResult] = useState<ExamResult>();

  const renderTooltip = (props: any, e: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {e}
    </Tooltip>
  );

  useEffect(() => {
    startLoader();
    if (examTypes?.length && subjects?.length) {
      fetchApis();
    }
  }, [examTypes?.length, subjects?.length]);

  async function fetchApis() {
    const examVar = await fetchExamApi();

    fetchResultApi(examVar!);
    if (examVar) {
      buildExamSubjects(examVar);
    }
  }

  async function fetchExamApi() {
    const docRef = doc(db, 'exam', examId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const examVar = { ...(docSnap.data() as Exam), id: docSnap.id };
      setExam({ ...examVar });
      return examVar;
    } else {
      console.log('No such document!');
    }
  }

  async function fetchResultApi(examVar: Exam) {
    const docRef = doc(db, resultCollection, resultId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const resultVar = { ...(docSnap.data() as ExamResult), id: docSnap.id };

      const remainingTime = calcRemainingTime(examVar, resultVar);

      if (remainingTime && remainingTime > 0) {
        setExamResult({ ...resultVar });

        setTimeout(() => {
          if (stopWatchRef.current) {
            stopWatchRef.current.handleStart();
          } else {
            setTimeout(() => {
              stopWatchRef.current.handleStart();
            }, 2500);
          }
        }, 1000);
      } else {
        toastRef.current.toggleShow('Time has expired, redirecting...');
        setTimeout(() => {
          navigate('/exams-list');
        }, 3000);
      }
    } else {
      console.log('No such document!');
    }

    stopLoader();
  }

  function buildExamSubjects(examVar: Exam) {
    const examSubjects: Subject[] = [];
    examVar?.questions.forEach((question) => {
      if (!examSubjects.some((s) => s.id === question.subject)) {
        const found = subjects.find((s) => s.id === question.subject);
        if (found) {
          examSubjects.push(found);
        }
      }
    });

    setExamSubjects(examSubjects);
  }

  function calcRemainingTime(examVar?: any, resultVar?: any) {
    const res = resultVar || examResult;
    const ex = examVar || exam;
    if (ex && res) {
      const time = differenceInSeconds(new Date(), new Date(res.created_date.toDate()));
      return ex.timeMinutes * 60 - time;
    }
  }

  function fetchSelectedImage(imageUrl: string) {
    setSelectedImage(imageUrl);
    handleShow();
  }

  async function showExamResult(exam: Exam) {
    window.localStorage.setItem('currentNav', 'exam-perform');

    let graphData: any[] = [];
    exam.questions.forEach((question) => {
      const foundItem = graphData.find((item) => item.subSubject === question.subSubject);
      if (!foundItem) {
        graphData.push({ subSubject: question.subSubject, correctAnswers: 0, total: exam.questions.filter((e) => e.subSubject === question.subSubject)?.length });
      }

      if (examResult && ArrayUtils.answeredCorrectly(question, examResult)) {
        const item = graphData.find((item) => item.subSubject === question.subSubject);
        item.correctAnswers++;
      }
    });

    graphData = graphData.map((item) => {
      return { ...item, subSubject: subSubjects.find((s) => s.id === item.subSubject)?.name };
    });

    return { examId, graphData, examResult };
  }

  async function onAnswerQuestion(question: Question, option: number, marked: boolean) {
    const eResult = { ...examResult } as ExamResult;

    if (!eResult) return;
    if (eResult.submitted_date) return;
    if (!eResult?.answers?.length) eResult.answers = [];
    const answer = eResult?.answers.find((a) => a.questionId === question.id);
    if (answer) {
      const markedOption = answer.markedOptions?.find((m) => m === option);

      if (markedOption !== undefined) {
        answer.markedOptions = answer.markedOptions?.filter((m) => m !== option);
      } else {
        if (!answer?.markedOptions) answer.markedOptions = [];
        answer.markedOptions.push(option);
      }
    } else {
      eResult.answers.push({ questionId: question.id, markedOptions: [option] });
    }

    //answeredCorrectly(question, eResult);

    setExamResult(eResult);

    await setDoc(doc(db, resultCollection, resultId), eResult);
  }

  async function submitExam() {
    startLoader();

    const examResultCopy = { ...examResult } as ExamResult;
    if (examResultCopy.submitted_date) {
      toastRef.current.toggleShow('Exam already submitted, redirecting...');
      setTimeout(() => {
        stopLoader();
        navigate('/exams-list');
      }, 3000);
      return;
    }

    examResultCopy.submitted_date = new Date();
    setExamResult(examResultCopy);
    await setDoc(doc(db, resultCollection, resultId), examResultCopy);

    examResultModalRef.current.handleShow(await showExamResult(exam!));

    stopLoader();
  }

  function confirmModal() {
    navigate('/exams-list');
  }

  function showSubmitConfirmModal() {
    confirmSubmitRef.current.handleShow();
  }

  function incrementCount() {
    count++;
  }

  async function confirmReport() {
    setLoadingReport(true);
    await addDoc(collection(db, 'reports'), {
      userId: user?.id || 'not logged in',
      examId: exam?.id,
      examName: exam?.title,
      date: new Date(),
      report: reportText
    });

    setReportText('');

    toastRef.current.toggleShow('Report sent successfully!');
    setLoadingReport(false)
    setShowModal(false)
  }

  /* async function pauseOrResume() {
    const examResultCopy = {...examResult} as Result;
    examResultCopy.paused = !examResultCopy.paused;
    setExamResult(examResultCopy);
  } */

  return (
    <>
    <div>
      {exam && examResult && (
        <section id="exam-perform">
          <header className="exam-top-header">
            <div>
              <span className="exam-title">
                {exam?.title} : {examTypes?.find((e) => e.id === exam.type)?.name}
              </span>
            </div>
            <div className="header-elements">
              <div className="difficulty">
                <div>
                  <span className="difficulty-text">Difficulty </span>
                </div>
                <div className="difficulty-level">
                  <div className="text-difficulty">
                    <StarFill className="first-star" />
                    <StarFill className={exam.difficulty >= 2 ? '' : 'faded-star'} />
                    <StarFill className={exam.difficulty < 3 ? 'faded-star' : ''} />
                  </div>
                </div>
              </div>
              <button className="btn btn-primary button-back" onClick={() => navigate(-1)}>
                <ArrowLeftShort className="arrow-left" />
                <span>Back</span>
              </button>
              <button className="btn btn-primary button-submit" onClick={showSubmitConfirmModal}>
                Submit
              </button>
              <div className="exam-time">
                <div className="watch-box">
                  <StopWatch key={'watch1'} runUntil={calcRemainingTime()} reachEnd={submitExam} ref={stopWatchRef} />
                </div>
              </div>

              <div className="report-icon" onClick={() => setShowModal(true)}>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={(e: any) => renderTooltip(e, 'Report')}>
                  <img className="report-img" src="/icons/report-icon.png" alt="report-icon" />
                </OverlayTrigger>
              </div>
            </div>
          </header>

          <main className="exam-container">
            <div className="exam-questions">
              {examSubjects?.map((subject: any, i) => (
                <div className="subject-container-parent" key={subject.id}>
                  <div className="header-box">
                    <span className="header-span">{subject.name}</span>
                  </div>
                  {exam?.questions
                    ?.filter((q) => q.subject === subject.id)
                    ?.map((question: any, i: number) => (
                      <div key={'quest' + i} className="question-box">
                        <div className="question-header-1">
                          {question.headerImageUrl && (
                            <div className="question-image header">
                              <img onClick={() => fetchSelectedImage(question.headerImageUrl)} src={question.headerImageUrl} alt="" />
                              <div className="magnifying-glass-icon">
                                <img onClick={() => fetchSelectedImage(question.headerImageUrl)} src="/icons/magnifying-glass-icon.png" alt="magnifying-glass-icon" />
                              </div>
                            </div>
                          )}
                          <span>Question: {i + 1}</span>
                          {incrementCount()}
                        </div>

                        <div className="question-image">
                          <img src={question.questionImageUrl} alt="" />
                        </div>

                        <div className="magnifying-glass-icon">
                          <img onClick={() => fetchSelectedImage(question.questionImageUrl)} src="/icons/magnifying-glass-icon.png" alt="magnifying-glass-icon" />
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>

            {examResult && exam?.type == 'cDaUveEJwqt084KzaCkI' && (
              <div className="answer-section-container">
                {' '}
                <TiffinCard result={examResult} onAnswer={onAnswerQuestion} exam={exam} />{' '}
              </div>
            )}
            {examResult && exam?.type == 'Wa1XNOCHd6OqZ29M9K4D' && (
              <div className="answer-section-container">
                <GlaCard result={examResult} onAnswer={onAnswerQuestion} exam={exam} />
              </div>
            )}
            {examResult && exam?.type == 'GiXgZHoJfIwEY1ID2vrn' && (
              <div className="answer-section-container">
                <Type3AnswersCard result={examResult} onAnswer={onAnswerQuestion} exam={exam} />
              </div>
            )}
          </main>
        </section>
      )}

      {selectedImage && (
        <Modal show={showZoomModal} onHide={handleClose} centered dialogClassName={'zoom-modal'}>
          <Modal.Body>
            <div>
              <img src={selectedImage} />
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ToastMessage ref={toastRef} />
      <Modal show={showModal} onHide={toggleModal} dialogClassName="global-modal confirm-parental-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Report Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea className="form-control form-control-lg form-control-solid" rows={5}
            placeholder="Please let us know the issue you've found..." value={reportText} onChange={(e) => setReportText(e.target.value)}></textarea>

          <div className="custom-success-footer">
            <Button onClick={toggleModal} variant="light" className="back-btn btn-short">
              <span>Cancel</span>
            </Button>

            <Button id="button-start-modal" style={{minWidth: '90px'}} onClick={() => confirmReport()}>
              {!loadingReport && <span className="indicator-label">Send</span>}
                {loadingReport && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    Sending... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <ExamResultsModal date={examResult?.submitted_date} showFirstScreen={resultCollection === 'public-results'} onConfirm={confirmModal} onHide={confirmModal} ref={examResultModalRef} title={exam?.title + ' results'} confirmBtn="Confirm" />
    </div>
    <ConfirmModal ref={confirmSubmitRef} onConfirm={submitExam} title="Exam Submission" confirmBtn="Confirm" description={'Are you sure you want to submit the exam?'} />
    </>
  );
};

export default ExamPerform;
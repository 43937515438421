import { getDocs, Query } from "firebase/firestore";
import { Utils } from "./string-utils";
//const axios = require('axios').default;

export abstract class QueryUtils {
  public static async setQuery(query: Query, setFunc: any, setVar?: any) {
    const list: any[] = [];
    const querySnapshot = await getDocs(query);
    querySnapshot.forEach(doc => {
      list.push({...doc.data(), id: doc.id})
    });
    setFunc(list);

    return list;
  }

  public static async executeQuery(query: Query) {
    const list: any[] = [];
    const querySnapshot = await getDocs(query);
    querySnapshot.forEach(doc => {
      list.push({...doc.data(), id: doc.id})
    });

    return list;
  }
}
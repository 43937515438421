import { forwardRef, useImperativeHandle, useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';

type Props = {
  onSuccess?: any,
};

export const ToastMessage = forwardRef((props: Props, ref)  => {
  const [state, setState] = useState<any>({show: false});

  const toggleShow = (bodyMsg: string, isError = false) => {
    setState({show: true, body: convertMsg(bodyMsg), error: isError ? 'warning' : false});
  }

  function convertMsg(str: string) {
    const newStr = str.replace('Firebase: Error (', '').replace(').', '').replace('.', '');
    switch (newStr) {
      case 'auth/wrong-password':
      case 'auth/user-mismatch':
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        return 'Incorrect credentials';
      case 'Firebase: Password should be at least 6 characters (auth/weak-password':
        return 'Password should be at least 6 characters';

      case 'auth/email-already-in-use':
        return 'Email already in use';

      case 'auth/invalid-email':
      case 'auth/internal-error':
      case 'auth/missing-email':
        return 'Invalid credentials'
      default:
        return str;
    }
  }

  useImperativeHandle(ref, () => ({
    toggleShow,
  }));

  return (
    <ToastContainer position="top-end" className="position-fixed toast-pos">
      <Toast bg={state.error} onClose={() => setState({...state, show: false})} show={state.show} delay={3000} autohide>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Message</strong>
        {/*   <small>11 mins ago</small> */}
        </Toast.Header>
        <Toast.Body>{state.body}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
})
import { createContext, useContext, useEffect, useState } from 'react';
import { db, useAuthState } from './firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { User } from '../models/user';
import { useGlobalState } from './globals';

export const UserContext = createContext({});

export const UserContextProvider = (props: any) => {
  //console.log(props);
  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState<boolean>(true);
  //const [error, setError] = useState()

  const { user: authUser, loading: isLoading } = useAuthState();
  //const { userNameInput } = useGlobalState();

  useEffect(() => {
    if (!authUser) {
      setUser(null);
      if (!isLoading) {
        setLoading(false);
      }
      return;
    }

    const unsubscribe = onSnapshot(doc(db, 'user', authUser.uid), (doc) => {
      const data = doc?.data();
      if (!data) {
        setLoading(false);
        return;
      }
      const user = { ...(data as User) };

      //const login = userNameInput || window.localStorage.getItem('login');

      setUser(user);
      /*  if (user.login === login) {
        setUser(user);
        window.localStorage.setItem('login', login);
      } */
      setLoading(false);
    });
    return () => unsubscribe();
  }, [authUser]); //userNameInput

  return <UserContext.Provider value={{ user, loading }} {...props} />;
};

export const useUserState = () => {
  const user: any = useContext(UserContext);
  return { ...user.user, loading: user.loading };
};
//export default UserContext;

import { useCallback, useEffect, useRef, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import Button from 'react-bootstrap/Button';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './reset-password.scss';
import { Auth } from '../../layouts/auth/auth';
import { ToastMessage } from '../../components/toast/ToastMessage';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useToast } from '../../services/ToastContext';
//import jwt from 'jsonwebtoken';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const toastRef: any = useRef(null);
  const {showToastMessage} = useToast();
  let { token }: any = useParams();
  const [saveLoading, setSaveLoading] = useState(false);
  const location = useLocation();

  //check if url location contains 'parental' text, if so set it in a state
  const [isParental, setIsParental] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('parental')) {
      setIsParental(true);
    }
  }, [location.pathname]);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  async function save() {
    if (saveLoading) return;
    if (!password || !confirmPassword) {
      toastRef.current.toggleShow('Please fill all fields', true);
      return;
    }

    if (password !== confirmPassword) {
      toastRef.current.toggleShow('Passwords do not match', true);
      return;
    }

    if (password.length < 6) {
      toastRef.current.toggleShow('Password must be at least 6 characters', true);
      return;
    }
    setSaveLoading(true);

    try {
      //const respDecrypt = await axios.post('https://us-central1-mock-exam-centre-prod.cloudfunctions.net/decryptResetToken', {token: token});
      const functions = getFunctions();
      const decryptToken = httpsCallable(functions, 'decryptResetToken');
      const respDecrypt: any = await decryptToken({ token: token });
      //const respDecrypt = await callableFunction({token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYUpYVWhKYzJ6c1g1NzZZOVJ1eGNEZmlMMVExMiIsImlhdCI6MTY3ODk3MDc5OCwiZXhwIjoxNjc5MDU3MTk4fQ.5X90WmCnQd81wVuBRk5hY-CLbKwj7GgWcHvPkr0esfk'});

      if (respDecrypt.data.status.exp * 1000 < new Date().getTime()) {
        toastRef.current.toggleShow('Link expired', true);
        return;
      } else {
        //const url = isParental ? 'https://us-central1-mock-exam-centre-prod.cloudfunctions.net/updateParentalPassword' : 'https://us-central1-mock-exam-centre-prod.cloudfunctions.net/updatePassword';
        const resetPwdFunction = httpsCallable(functions, isParental ? 'updateParentalPassword' : 'updatePassword');
        const resp: any = await resetPwdFunction({userId: respDecrypt.data.status.user_id, newPass: password});
        if (resp.data.status === 'success') {
          showToastMessage('Password updated successfully');
          navigate('/login');
        } else {
          toastRef.current.toggleShow('Something went wrong', false);
        }
        setSaveLoading(false);
      }
    } catch(e: any) {
      console.log(e)
      toastRef.current.toggleShow(e.response.data, false);
      setSaveLoading(false);
    }
  }

  //TODO convert to react bootstrap form
  return (
    <Auth>
      <section id="reset-password" className="bg-white">
        <div>
          <span className='reset-password'>Reset {isParental ? 'Parental' : ''} Password</span>
        </div>
        <div className='new-password'>
          <span className='first-line'>Enter your new password</span>
          <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} className="form-control form-control-lg form-control-solid"></input>
        </div>

        <div className='confirm-new-password'>
          <span className='second-line'>Confirm new password</span>
          <input type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}  className="form-control form-control-lg form-control-solid"></input>
        </div>

        <div className='btns-container'>
        <Button variant="light" onClick={() => navigate('/exams-list')} className="back-btn btn-short">
          <span>Cancel</span>
        </Button>
        <button className="btn btn-primary fs-6 px-8 py-4 me-3 second-button" onClick={save}>
          {!saveLoading && <span className="indicator-label">Save</span>}
          {saveLoading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Save <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        </div>
      </section>
      <ToastMessage ref={toastRef} />
    </Auth>
  );
};

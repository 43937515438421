export function TermsAndConditions() {
  return (
    <>
      <h1>Terms of Service</h1>
      <p>
        These Terms of Service govern your use of the website located at <a href="https://www.mockexamcentre.com">https://www.mockexamcentre.com</a> and any related services provided by Mock Exam Centre.
      </p>
      <p>
        By accessing <a href="https://www.mockexamcentre.com">https://www.mockexamcentre.com</a>, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations. If you do not agree with these Terms of Service, you are prohibited from using or accessing this website or using any other services provided by Mock
        Exam Centre.
      </p>
      <p>We, Mock Exam Centre, reserve the right to review and amend any of these Terms of Service at our sole discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will take effect immediately from the date of publication.</p>
      <p>These Terms of Service were last updated on 20 December 2022.</p>

      <h2>Description of Service</h2>
      <p>Mock Exam Centre is an online learning platform that allows students to gain access to practice tools and mock papers that will help students in preparation for final exams in various stages of their education. Additionally, we offer private tutoring, as well as summer holiday revision classes.</p>

      <h2>Limitations of Use</h2>
      <p>By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:</p>
      <ul>
        <li>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</li>
        <li>remove any copyright or other proprietary notations from any materials and software on this website;</li>
        <li>transfer the materials to another person or “mirror” the materials on any other server;</li>
        <li>knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service Mock Exam Centre provides;</li>
        <li>use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</li>
        <li>use this website or its associated services in violation of any applicable laws or regulations;</li>
        <li>use this website in conjunction with sending unauthorised advertising or spam;</li>
        <li>harvest, collect or gather user data without the user’s consent; or</li>
        <li>use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
      </ul>

      <h2>Intellectual Property</h2>
      <p>The intellectual property in the materials contained in this website are owned by or licensed to Mock Exam Centre and are protected by applicable copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use.</p>
      <p>This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by Mock Exam Centre at any time.</p>

      <h2>Registration and Payments</h2>
      <p>
        In order to use our services, you need to create an account. Any information provided needs to be accurate and updated if necessary. Users are liable for maintaining their accounts and are fully responsible for all activities occurring under their accounts. Should anybody gain unauthorised access to your account, you need to notify us
        immediately. Mock Exam Centre is not liable for any loss or damage arising from failure to maintain the confidentiality of your account.
      </p>
      <p>When creating your Mock Exam Centre account you are required to provide your financial information relevant to the chosen payment method, in some cases, you may be requested to provide your credit card details.</p>
      <h2>Liability</h2>
      <p>
        Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law, Mock Exam Centre makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose,
        or non-infringement of intellectual property, or other violation of rights.
      </p>
      <p>
        In no event shall Mock Exam Centre or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if Mock Exam Centre or an authorised representative has been notified, orally or in writing, of the possibility
        of such damage.
      </p>
      <p>
        In the context of this agreement, “consequential loss” includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute,
        contract, equity, tort (including negligence), indemnity or otherwise.
      </p>
      <p>Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
      <h2>Accuracy of Materials</h2>
      <p>
        The materials appearing on our website are not comprehensive and are for general information purpose only. Mock Exam Centre does its best to offer relevant and accurate materials based on general feedback, but, due to the changing nature of the 11+ tests, cannot not warrant or make any representations concerning future accuracy, likely
        results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website.
      </p>
      <h2>Availability of Materials</h2>
      <p>Mock Exam Centre will make sure that the learning materials present on our website are available at all times, except for any planned or unscheduled maintenance. We have the right to alter the learning materials present on our website without prior notice.</p>
      <h2>Links</h2>
      <p>
        Mock Exam Centre has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval or control by Mock Exam Centre of the site. Use of any such linked site is at your own risk and we strongly advise you make your own
        investigations with respect to the suitability of those sites.
      </p>
      <h2>Inaccuracies and Mistakes</h2>
      <p>
        Please note that the content provided on our website may occasionally contain typographical errors, inaccuracies or mistakes that are related to service descriptions, pricing, promotions, offers, and availability. We have the right to amend said information at any time without prior notice. At the same time, we are not obliged to clarify
        any of the information about our website and service, except in regard to pricing information, as required by law.
      </p>
      <h2>Right to Terminate</h2>
      <p>We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any breach of these Terms of Service.</p>
      <h2>Severance</h2>
      <p>Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected.</p>
      <h2>Governing Law</h2>
      <p>These Terms of Service are governed by and construed in accordance with the laws of the United Kingdom. You irrevocably submit to the exclusive jurisdiction of the courts in that location.</p>
    </>
  );
}

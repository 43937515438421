import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { ProtectedPages } from '../../layouts/admin/ProtectedLayout';
import './exam-creation.scss';
import * as Yup from 'yup';
import { Exam } from '../../models/exam';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useStaticState } from '../../services/StaticContext';
import { ExamType } from '../../models/exam-type';
import { addDoc, collection, doc, getDoc, query, setDoc, where } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useUserState } from '../../services/User';
import { QueryUtils } from '../../util/query-utils';
import { Button } from 'react-bootstrap';
import { ArrowLeftShort } from 'react-bootstrap-icons';
import { ref, getDownloadURL, uploadBytesResumable, getStorage, uploadBytes } from 'firebase/storage';
import { useLoader } from '../../services/LoaderProvider';

type Props = {
  examId?: string;
};

const loginSchema = Yup.object().shape({
  title: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Title is required'),
  timeMinutes: Yup.number().required('Time in minutes is required'),
  //username: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Username is required'),
  //password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
});

let initialValues = {
  title: '',
  timeMinutes: 5,
  expectedDate: null,
  difficulty: '',
};

const ExamCreation = (props: Props) => {
  let { examId }: any = useParams();
  const navigate = useNavigate();
  const user = useUserState();
  const inputFileRef: any = useRef(null);
  const imageFileRef: any = useRef(null);
  const { startLoader, stopLoader } = useLoader();
  let examVar: Exam;

  if (!examId) {
    initialValues = {
      title: '',
      timeMinutes: 60,
      expectedDate: null,
      difficulty: '',
    };
  }

  const { examTypes, subjects, subSubjects } = useStaticState();
  const [exam, setExam] = useState<Exam>();
  const [loading, setLoading] = useState(false);
  const [selectedExamType, setSelectedExamType] = useState<ExamType>();
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [examList, setExamList] = useState<Exam[]>();
  const [order, setOrder] = useState<number>(1);

  const difficultyList = [1, 2, 3];

  useEffect(() => {
    if (examTypes?.length && !examId) {
      setSelectedExamType(examTypes[0]);
      getAllExams(examTypes[0]);
    }

    if (examId !== undefined && examTypes?.length) {
      fetchApi();
    }
  }, [user?.id, examTypes]);

  async function getAllExams(examType?: ExamType) {
    const customQuery = query(collection(db, 'exam'), where('deleted', '==', false), where('type', '==', examType?.id));
    const exams = await QueryUtils.executeQuery(customQuery);
    setExamList(exams as any);
  }

  async function fetchApi() {
    const docRef = doc(db, 'exam', examId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      examVar = { ...(docSnap.data() as Exam), id: docSnap.id };
      setExam({ ...examVar });

      initialValues.title = examVar.title;
      initialValues.timeMinutes = examVar.timeMinutes;
      initialValues.expectedDate = examVar.expectedDate;
      setIsPaid(examVar.isPaid);

      setSelectedExamType(examTypes.find((examType: any) => examType.id === examVar.type));
      getAllExams(examTypes.find((examType: any) => examType.id === examVar.type));

      if (examVar.order) {
        setOrder(examVar.order);
      }
    } else {
      console.log('No such document!');
    }
  }

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: loginSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      createExamOnDb(values);
    },
  });

  function inputBuilder(fieldName: string, placeholder: string, disabled = false) {
    const touched: any = formik.touched;
    const touchedField: any = touched[fieldName];
    const errors: any = formik.errors;
    const errorsField = errors[fieldName];

    return (
      <>
        <input
          placeholder={placeholder}
          {...formik.getFieldProps(fieldName)}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': touchedField && errorsField },
            {
              'is-valid': touchedField && !errorsField,
            }
          )}
          disabled={disabled}
          type={fieldName}
          name={fieldName}
          autoComplete="off"
        />
        {touchedField && errorsField && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{errorsField}</div>
          </div>
        )}
      </>
    );
  }

  async function createExamOnDb(data: any) {
    const { title, timeMinutes, expectedDate } = data;

    //Debugging
    console.log(expectedDate);
    console.log(data);
    console.log(exam);
    console.log(selectedExamType);

    if (!examId) {
      const resp = await addDoc(collection(db, 'exam'), {
        title,
        timeMinutes: Number(timeMinutes),
        date_added: new Date(),
        isOnline: exam?.isOnline || false,
        deleted: false,
        order: order,
        expectedDate: expectedDate || null,
        isPaid: isPaid,
        type: selectedExamType?.id,
        difficulty: exam?.difficulty ? Number(exam?.difficulty) : 1,
      });
      examId = resp.id;
    } else {
      await setDoc(doc(db, 'exam', examId), {
        ...exam,
        title,
        timeMinutes: Number(timeMinutes),
        questions: exam?.questions || [],
        isPaid: isPaid,
        expectedDate: expectedDate || null,
        order: order,
        type: selectedExamType?.id || exam?.type,
        difficulty: Number(exam?.difficulty),
      });
    }

    setLoading(false);
    navigate('/exam-creation-subject/' + examId);
  }

  function handleExamTypeUpdate(e: any) {
    const found = examTypes.find((examType: any) => examType.id === e.target.value);
    getAllExams(found);
    setOrder(1);
    if (found) setSelectedExamType(found);
  }

  function handleDifficultyUpdate(e: any) {
    const examCopy = { ...exam } as Exam;
    examCopy.difficulty = e.target.value;
    setExam(examCopy);
  }

  function backBtn() {
    navigate('/exams-management');
  }

  function sendImage(e: any) {
    startLoader();
    e.preventDefault();
    const file = e.target?.files?.[0];
    console.log(file);

    const storage = getStorage();
    const storageRef = ref(storage, `exams/${examId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot: any) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error: any) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setExam({ ...exam, coverImageUrl: downloadURL } as any);
          await setDoc(doc(db, 'exam', examId), { ...exam, coverImageUrl: downloadURL });
          stopLoader();
        });
      }
    );
  }

  return (
    <ProtectedPages>
      <div id="exam-creation">
        <section className="main-content">
          <div className="box-password">
            <form className="form w-100" onSubmit={formik.handleSubmit}>
              {/*   <h3 className="text-profile">Profile</h3> */}
              <div className="profile-data-container">
                <div className="title-box">
                  <label className="form-label fs-6 fw-bolder text-dark">Title</label>
                  {inputBuilder('title', '')}
                </div>

                <div className="time-minutes-box">
                  <label className="form-label fs-6 fw-bolder text-dark">Time in minutes</label>
                  {inputBuilder('timeMinutes', '')}
                </div>

                <div className="title-box">
                  <label className="form-label fs-6 fw-bolder text-dark">Type</label>
                  <select
                    className="form-select form-select-lg form-select-solid"
                    data-control="select2"
                    data-placeholder="Select Type..."
                    value={selectedExamType?.id}
                    onChange={handleExamTypeUpdate}
                  >
                    {examTypes?.map((examType: ExamType, i: number) => (
                      <option key={examType.id} value={examType.id}>
                        {examType.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="time-minutes-box">
                  <label className="form-label fs-6 fw-bolder text-dark">Difficulty</label>
                  <select
                    className="form-select form-select-lg form-select-solid"
                    data-control="select3"
                    data-placeholder="Select Type..."
                    value={exam?.difficulty}
                    onChange={handleDifficultyUpdate}
                  >
                    {difficultyList?.map((diff: number) => (
                      <option key={diff} value={diff}>
                        {diff}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="time-minutes-box">
                  <label className="form-label fs-6 fw-bolder text-dark">Order</label>
                  <select
                    className="form-select form-select-lg form-select-solid"
                    data-control="select3"
                    data-placeholder="Select Type..."
                    value={order}
                    onChange={(e) => setOrder(Number(e.target.value))}
                  >
                    {examList?.map((exam: Exam, i: number) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}

                    {!examList?.length && <option value={1}>1</option>}
                  </select>
                </div>

                <div className="form-check form-check-custom form-check-solid me-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    checked={!isPaid}
                    onChange={() => {
                      setIsPaid(!isPaid);
                    }}
                  />
                  <label className="form-check-label fw-bold" htmlFor="inlineCheckbox1">
                    Free
                  </label>

                  <input
                    className="form-check-input ms-5"
                    type="checkbox"
                    id="inlineCheckbox2"
                    checked={isPaid}
                    onChange={() => {
                      setIsPaid(!isPaid);
                    }}
                  />
                  <label className="form-check-label fw-bold" htmlFor="inlineCheckbox2">
                    Paid
                  </label>
                </div>

                <div>
                  <label className="form-label fs-6 fw-bolder text-dark">Expected Date(Optional)</label>
                  <input
                    type="date"
                    autoComplete="off"
                    {...formik.getFieldProps('expectedDate')}
                    className={clsx('form-control form-control-lg form-control-solid')}
                  />
                </div>

                <div className="image-upload-container">
                  <label className="form-label fs-6 fw-bolder text-dark">Cover image</label>
                  {!exam?.coverImageUrl && (
                    <div className={'upload-box'}>
                      <img className="upload-img" src="/icons/upload.svg" onClick={() => inputFileRef.current.click()} />
                      <input
                        className="upload-input"
                        name="coverImage"
                        ref={inputFileRef}
                        type="file"
                        onChange={(e) => {
                          sendImage(e);
                        }}
                      />
                    </div>
                  )}

                  {exam?.coverImageUrl && (
                    <div className={'img-container'}>
                      <img
                        className="uploaded-file"
                        src={exam?.coverImageUrl}
                        alt="uploaded file"
                        onClick={() => imageFileRef.current.click()}
                      />
                      <input
                        className="upload-input secondary"
                        name="headerImage"
                        ref={imageFileRef}
                        type="file"
                        onChange={(e) => {
                          sendImage(e);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* end::Form group */}

              {/* begin::Action */}
              <div className="update-profile-btn">
                <Button variant="light" className="back-btn" onClick={backBtn}>
                  <ArrowLeftShort className="arrow-left" />
                  <span>Back</span>
                </Button>
                <button
                  type="submit"
                  id="kt_login_signin_form_submit_button"
                  className="btn btn-primary fw-bolder fs-6 px-8 my-3 me-3"
                  disabled={formik.isSubmitting || !formik.isValid || (!formik.dirty && !examId)}
                >
                  {!loading && <span className="indicator-label">Next</span>}
                  {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Action */}
            </form>
          </div>
        </section>
      </div>
    </ProtectedPages>
  );
};

export default ExamCreation;

import './GlaCard.scss';
import { Exam } from '../../../models/exam';
import { useEffect, useState } from 'react';
import { ExamResult } from '../../../models/exam-result';
import { Question } from '../../../models/question';
import { Subject } from '../../../models/subject';
import { useStaticState } from '../../../services/StaticContext';
import { useUserState } from '../../../services/User';
import { User } from '../../../models/user';
import { format } from 'date-fns';

type Props = {
  exam: Exam | undefined;
  result: ExamResult;
  onAnswer: any;
};

export const GlaCard = (props: Props) => {
  const { examTypes, subjects, subSubjects } = useStaticState();
  const [examSubjects, setExamSubjects] = useState<Subject[]>();
  const user: User = useUserState();
  const [dateOfBirth, setDateOfBirth] = useState<any>();
  const [dateOfTest, setDateOfTest] = useState<any>();

  useEffect(() => {
    if (user?.birthDate) {
      setDateOfTest(format(new Date(), 'ddMMyy').toString());
      setDateOfBirth(format(new Date(user.birthDate), 'ddMMyy').toString());
    } else {
      setDateOfTest(format(new Date(), 'ddMMyy').toString());
      setDateOfBirth('111111');
    }
  }, [user]);

  useEffect(() => {
    if (subjects?.length) {
      buildExamSubjects();
    }
  }, [subjects?.length]);

  function buildExamSubjects() {
    const examSubjects: Subject[] = [];
    props.exam?.questions.forEach((question) => {
      if (!examSubjects.some((s) => s.id === question.subject)) {
        const found = subjects.find((s) => s.id === question.subject);
        if (found) {
          examSubjects.push(found);
        }
      }
    });
    setExamSubjects(examSubjects);
  }

  function getLetter(index: number) {
    const letterIndex = index;
    const letter = String.fromCharCode(letterIndex + 'A'.charCodeAt(0));
    return letter;
  }

  function getWidth(size: number) {
    return 56 * Math.ceil(size / 5) + 'px';
  }

  function onAnswerSelect(question: Question, option: any, marked: boolean) {
    props.onAnswer(question, option, marked);
  }

  function isMarked(question: Question, option: any) {
    const found = props.result.answers?.find((ans) => ans.questionId === question.id);
    return found && found.markedOptions.find((m) => m === option) !== undefined;
  }

  function generateRandomNumber() {
    var min = Math.pow(10, 7); // Minimum 8-digit number (10^7)
    var max = Math.pow(10, 8) - 1; // Maximum 8-digit number (10^8 - 1)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const pupilNumber = user?.id ? user.id.substring(0, 8) : generateRandomNumber().toString();

  return (
    <section id="exam-perform-gla">
      <div className="header">
        <div className="header-first-session">
          <div className="lines-header">
            <div className="pupil-name">
              <div className="line-1-header">
                <span>Pupil`s Name </span>
                <div className="student-name">{user?.firstName || 'Demo User'} </div>
              </div>
            </div>
            <div className="line-2-header">
              <span>School Name</span>
              {props?.exam?.type && <div className='student-name'>{examTypes?.find((e) => e.id === props!.exam!.type)?.name}</div>}
            </div>
          </div>
          <div className="container-date">
            <div className="date">DATE OF TEST</div>
            <div className="date-text-header">
              <span className="date-text">DAY</span>
              <span className="date-text">MONTH</span>
              <span className="date-text">YEAR</span>
            </div>

            <div className="boxs-date">
              {dateOfTest &&
                Array.from(dateOfTest)?.map((char: any, i: number) => (
                  <div key={i} className="box-date">
                    {char}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div>
          <div className="second-section-header">
            <div className="uniqui-numbers-pupil">
              <div className="line-3-header">UNIQUE PUPIL NUMBER</div>
              <div className="numbers-pupil">
                {Array.from(pupilNumber)?.map((char: any, i: number) => (
                  <div key={i} className="number-pupil">
                    {char}
                  </div>
                ))}
              </div>
            </div>

            <div className="uniqui-numbers-pupil">
              <div className="line-3-header">SCHOOL NUMBER</div>
              <div className="numbers-pupil">
                <div className="number-pupil"></div>
                <div className="number-pupil"></div>
                <div className="number-pupil"></div>
                <div className="number-pupil"></div>
                <div className="number-pupil"></div>
                <div className="number-pupil"></div>
                <div className="number-pupil"></div>
                <div className="number-pupil"></div>
              </div>
            </div>

            <div className="container-date">
              <div className="date">DATE OF BIRTH</div>
              <div className="date-text-header">
                <span className="date-text">DAY</span>
                <span className="date-text">MONTH</span>
                <span className="date-text">YEAR</span>
              </div>

              <div className="boxs-date">
                {dateOfBirth &&
                  Array.from(dateOfBirth)?.map((char: any, i: number) => (
                    <div key={i} className="box-date">
                      {char}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="answer-card">
        <div className="gla-answers-card">
          {examSubjects?.map((subject, i) => (
            <div className="subject-container" key={subject.id}>
              <span className="header-section">
                <strong>{subject.name}</strong>
              </span>

              <div className="answer-card-gla">
                {props.exam?.questions
                  ?.filter((q) => q.subject === subject.id)
                  .map((question: any, i: number) => (
                    <div key={'question' + i} className="answer-container">
                      <div className="left-panel">
                        <span className="number">{i + 1}</span>
                        <div className="opts">
                          {question?.options?.map((option: any, i: number) => (
                            <span key={i}>{option || getLetter(i)}</span>
                          ))}
                        </div>
                      </div>
                      <div className="right-panel">
                        {question?.options?.map((option: any, i: number) => (
                          <div className="outer-option" key={i}>
                            <div className={'inner-option'} onClick={() => onAnswerSelect(question, i, true)}>
                              {!isMarked(question, i) && <img src="/icons/gla.svg" height={10} />}
                              {isMarked(question, i) && <img src="/icons/gla-checked.svg" height={10} />}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

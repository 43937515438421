import { Exam } from '../../../models/exam';
import { Question } from '../../../models/question';
import { ExamResult } from '../../../models/exam-result';
import './Type3AnswerCard.scss';
import { useEffect, useState } from 'react';
import { Subject } from '../../../models/subject';
import { useStaticState } from '../../../services/StaticContext';
import { AnswersCardHeader } from '../../answers-card-header/AnswersCardHeader';

type Props = {
  exam: Exam | undefined;
  result: ExamResult;
  onAnswer: any;
};

export const Type3AnswersCard = (props: Props) => {
  const { examTypes, subjects, subSubjects } = useStaticState();
  const [examSubjects, setExamSubjects] = useState<Subject[]>();

  useEffect(() => {
    if (subjects?.length) {
      buildExamSubjects();
    }
  }, [subjects?.length]);

  function buildExamSubjects() {
    const examSubjects: Subject[] = [];
    props.exam?.questions.forEach((question) => {
      if (!examSubjects.some((s) => s.id === question.subject)) {
        const found = subjects.find((s) => s.id === question.subject);
        if (found) {
          examSubjects.push(found);
        }
      }
    });
    setExamSubjects(examSubjects);
  }

  function onAnswerSelect(question: Question, option: any, marked: boolean) {
    props.onAnswer(question, option, marked);
  }

  function isMarked(question: Question, option: any) {
    const found = props.result.answers?.find((ans) => ans.questionId === question.id);
    return found && found.markedOptions.find((m) => m === option) !== undefined;
  }

  return (
    <section id="exam-perform-type-3">
      <AnswersCardHeader />

      <div className="answer-card">
        <div className="type-3-answers-card">
          {examSubjects?.map((subject, i) => (
            <div className="subject-container" key={subject.id}>
              <span className="header-section">
                <strong>{subject.name}</strong>
              </span>
              <main className="content-answers">
                <div className="answers-options-description">
                  <span>A</span>
                  <span>B</span>
                  <span>C</span>
                  <span>D</span>
                  <span>E</span>
                </div>

                <div className="answers-container">
                  {props.exam?.questions
                    ?.filter((q) => q.subject === subject.id)
                    ?.map((question: any, i: number) => (
                      <div key={question.id} className="answers-container">
                        <div className="answers-options">
                          <span className="question-number">{i + 1}</span>
                          {question?.options?.map((option: any, i: number) => (
                            <div key={i}>
                              {!isMarked(question, i) ? <img src="/icons/suton-check.png" onClick={() => onAnswerSelect(question, i, true)} />
                              : isMarked(question, i) && <img src="/icons/suton-checked.png" onClick={() => onAnswerSelect(question, i, false)} />}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </main>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

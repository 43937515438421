import { useContext, createContext, useRef, useMemo } from 'react'
import Loader from '../components/global-loading/Loader';

const LoaderContext = createContext(null);

export function LoaderProvider({children}: any) {
  const ref: any = useRef();
  const startLoader = () => ref.current.start();
  const stopLoader = () => ref.current.stop();
  const value: any = useMemo(
    () => ({ref, startLoader, stopLoader}),
    [ref, startLoader, stopLoader]
  );

  return (
    <LoaderContext.Provider value={value}>
      {children}
      <Loader ref={ref} />
    </LoaderContext.Provider>
  );
}

//export const useLoader = () => useContext(LoaderContext);
export const useLoader = () => {
  const auth: any = useContext(LoaderContext)
  return { ...auth, test: 'test' }
}
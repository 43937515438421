export function Tiffin() {
  return (
    <>
      <h1>Tiffin & Tiffin Girls’:</h1>
      <p>Tiffin School and Tiffin Girls’ are selective grammar schools located in Kingston upon Thames, Surrey. They are both known for their rigorous academic standards and highly competitive admissions process.</p>
      <p>Taking place over two stages, their tests are designed to assess students' ability in the following areas:</p>
      <ul>
        <li>Mathematics: The mathematics section evaluates students' skills in areas such as arithmetic, problem-solving, critical thinking and numerical reasoning.</li>
        <li>English: The English section tests students' comprehension, grammar, vocabulary, and writing skills.</li>
        <li>Reasoning: The reasoning section examines students' logical thinking and analytical skills through various question types, such as verbal reasoning and non-verbal reasoning.</li>
      </ul>

      <p>Stage one is a multiple-choice exam. Children who perform well are invited back to the stage two test which is non-multiple choice, with a creative writing element.</p>
      <p>The Tiffin tests are written by the school themselves. They do not publish any practice papers or provide much information other than what is on their websites.</p>
    </>
  );
}

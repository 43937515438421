import { doc, updateDoc } from '@firebase/firestore';
import { addMinutes } from 'date-fns';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential } from 'firebase/auth';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { db } from '../../services/firebase';
import { useUserState } from '../../services/User';
import './success-modal.scss';

type Props = {
  title?: string;
  description?: string;
  onCancel?: any;
  loading?: any;
};

export const SuccessModal = forwardRef((props: Props, ref) => {
  const navigate = useNavigate();
  useImperativeHandle(ref, () => ({
    handleShow,
    handleCancel,
  }));

  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleCancel = async () => {
    setShowAuthModal(false);
    //props.loading = false;
    //sessionStorage.setItem('unlockedUntil')
    //await updateDoc(doc(db, 'user', user.id), { tempUnlock: addMinutes(new Date(), 10) });
    navigate('/exams-list');
    if (props.onCancel) props.onCancel();
  };

  const handleConfirm = () => {
    setShowAuthModal(false);
    // if (props.onConfirm) props.onConfirm();
  };

  const handleShow = () => setShowAuthModal(true);

  return (
    <section id="success-modal">
      <Modal show={showAuthModal} onHide={handleCancel} centered dialogClassName="global-modal success-modal">
        <Modal.Body>
          <div className="container-modal">
            {!props.loading && (
              <>
                <div>
                  <img src="/media/images/image-check.png" alt="image-check" />
                </div>
                <div className="first-line-modal">
                  Your transaction has been <br /> sent with success!
                </div>
              </>
            )}

            {props.loading && (
              <>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>

                <div className="first-line-modal black">
                  Please wait while we process your order... <br /> The process may take a couple minutes.
                </div>
              </>
            )}

            {/*   <div className="second-line-modal">Show details</div> */}
          </div>

          {/*  <div className="text-description">
            <span>Type</span>
            <span>Sent</span>
          </div>
          <div className="text-description-2">
            <span>Amout</span>
            <span>£ 49,99</span>
          </div>

          <div className="text-description-3">
            <span>Descritions</span>
            <span>Plan Pro Studant</span>
          </div> */}

          <div className="success-footer">
          {!props.loading && <Button onClick={handleCancel}>Back to panel</Button>}
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
});

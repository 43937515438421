import { addMinutes, format } from 'date-fns';
import { addDoc, collection, doc, getDoc, orderBy, query, where } from 'firebase/firestore';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import ChartLoader from '../../loaders/ChartLoader';
import { Exam } from '../../models/exam';
import { ExamResult } from '../../models/exam-result';
import { Question } from '../../models/question';
import { Subject } from '../../models/subject';
import StandardDeviation from '../../pages/standardDeviation/StandardDeviation';
import { useStaticState } from '../../services/StaticContext';
import { useUserState } from '../../services/User';
import { db } from '../../services/firebase';
import { ArrayUtils } from '../../util/array-utils';
import { QueryUtils } from '../../util/query-utils';
import { Utils } from '../../util/string-utils';
import './exam-results-modal.scss';
import ResultAnswers from '../results-answers/ResultsAnswers';
import { useToast } from '../../services/ToastContext';

type Props = {
  title: string;
  confirmBtn?: string;
  date?: string;
  onConfirm?: any;
  onHide?: any;
  showFirstScreen?: boolean;
};

export const ExamResultsModal = forwardRef((props: Props, ref) => {
  const user = useUserState();
  useImperativeHandle(ref, () => ({
    handleShow,
    handleHide,
  }));
  let userIndex: number;

  const { examTypes, subjects, subSubjects } = useStaticState();
  const { showToastMessage } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [propsData, setPropsData] = useState<any>();
  const [chartData, setChartData] = useState<any>();
  const [exam, setExam] = useState<Exam>();
  const [positionChartData, setPositionChartData] = useState<any>();
  const [selectedChart, setSelectedChart] = useState<any>('subjects');
  const [examResultList, setExamResultList] = useState<ExamResult[]>();
  const [selectedHeader, setSelectedHeader] = useState<any>('ranking');
  const [examSubjects, setExamSubjects] = useState<Subject[]>();
  const [animationDone, setAnimationDone] = useState<boolean>(false);
  const [selectedExamResult, setSelectedExamResult] = useState<ExamResult>();
  const [firstScreen, setFirstScreen] = useState<boolean>(props?.showFirstScreen || false);
  const [respResults, setRespResults] = useState<ExamResult[]>();
  const [userCorrectAnswers, setUserCorrectAnswers] = useState<any>();

  const [emailContact, setEmailContact] = useState<string>('');
  const [dateOfBirthContact, setDateOfBirthContact] = useState<string>('');

  const [showZoomModal, setShowAuthModal] = useState(false);
  const handleCloseZoom = () => setShowAuthModal(false);
  const handleShowZoom = () => setShowAuthModal(true);

  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleCloseVideo = () => setShowVideoModal(false);
  const handleShowVideo = () => setShowVideoModal(true);

  const [selectedImage, setSelectedImage] = useState<any>();
  const [selectedVideo, setSelectedVideo] = useState<any>();
  useEffect(() => {
    if ((examTypes?.length && subjects?.length, propsData)) {
      //&& selectedHeader === 'answers'
      fetchApis();
    }
  }, [examTypes?.length, subjects?.length, selectedHeader, showZoomModal, propsData]);

  async function fetchApis() {
    const examVar = await fetchExamApi();
    if (examVar) {
      fetchResultApi(examVar);
      buildExamSubjects(examVar);
    }
  }

  async function fetchExamApi() {
    const docRef = doc(db, 'exam', propsData.examId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const examVar = { ...(docSnap.data() as Exam), id: docSnap.id };
      setExam({ ...examVar });
      return examVar;
    } else {
      console.log('No such document!');
    }
  }

  async function fetchResultApi(examVar: Exam) {
    if (!propsData.examResult) {
      let eResults;
      if (!Utils.isFaceToFace(examVar)) {
        const customQuery = query(
          collection(db, 'results'),
          where('examId', '==', propsData.examId),
          where('userId', '==', user.id),
          orderBy('created_date', 'asc')
        );
        const results = ((await QueryUtils.executeQuery(customQuery)) as ExamResult[]).filter(
          (res) => !Utils.isExamOnGoing(examVar, res)
        );

        const customQuery2 = query(
          collection(db, 'additional-results'),
          where('examId', '==', propsData.examId),
          where('userId', '==', user.id),
          orderBy('created_date', 'asc')
        );
        const results2 = ((await QueryUtils.executeQuery(customQuery2)) as ExamResult[]).filter(
          (res) => !Utils.isExamOnGoing(examVar, res)
        );

        eResults = results2.concat(results).sort((a, b) => (a.created_date > b.created_date ? 1 : -1));
      } else {
        eResults = await loadF2FResults(examVar);
      }

      eResults.forEach((r) => {
        if (!r.submitted_date && examVar?.timeMinutes) {
          r.submitted_date = addMinutes(r.created_date.toDate(), examVar.timeMinutes);
        }
      });

      setExamResultList(eResults);

      setSelectedExamResult(eResults[0]);
    } else {
      setSelectedExamResult(propsData.examResult);
    }
  }

  async function loadF2FResults(exam: Exam) {
    const customQuery3 = query(collection(db, 'results-face-to-face'), where('examId', '==', exam.id)); //where('surname', '==', user?.lastName), where('forename', '==', user?.firstName)
    let results3 = (await QueryUtils.executeQuery(customQuery3)) as ExamResult[];
    results3 = results3.map((r) => Utils.convertFaceToFace(r));

    const filteredResults = results3.filter(
      (r: any) =>
        r?.lastName?.toLowerCase() === user?.lastName.toLowerCase() && r?.firstName?.toLowerCase() === user?.firstName?.toLowerCase()
    );

    return filteredResults.sort((a, b) => (a.created_date > b.created_date ? 1 : -1));
  }

  function buildExamSubjects(examVar: Exam) {
    const examSubjects: Subject[] = [];
    examVar?.questions.forEach((question) => {
      if (!examSubjects.some((s) => s.id === question.subject)) {
        const found = subjects.find((s) => s.id === question.subject);
        if (found) {
          examSubjects.push(found);
        }
      }
    });

    setExamSubjects(examSubjects);

    buildPositionData(propsData, examVar);
  }

  const handleHide = () => {
    setShowModal(false);
    setAnimationDone(false);
    setRespResults(undefined);

    const element = document.getElementById('sdasd');
    if (element) element.remove();
    if (props.onHide) props.onHide();
  };

  const handleConfirm = () => {
    changeSelectedHeader('ranking');

    const element = document.getElementById('sdasd');
    if (element) element.remove();
    setShowModal(false);
    if (props.onConfirm) props.onConfirm();
  };

  const handleShow = async (data: any) => {
    setTimeout(() => {
      setAnimationDone(true);
    }, 500);
    setPropsData(data);
    const docRef = doc(db, 'exam', data.examId);
    const docSnap = await getDoc(docRef);
    const respExam = docSnap.data() as Exam;
    setExam(respExam);
    buildSubjectChartData(data.graphData);
    setShowModal(true);
  };

  function getHitRangeIndex(hitsPct: number) {
    if (hitsPct <= 16) {
      return 0;
    } else if (hitsPct > 16 && hitsPct <= 33) {
      return 1;
    } else if (hitsPct > 33 && hitsPct <= 50) {
      return 2;
    } else if (hitsPct > 50 && hitsPct <= 66) {
      return 3;
    } else if (hitsPct > 66 && hitsPct <= 83) {
      return 4;
    } else if (hitsPct > 83) {
      return 5;
    }

    return 0;
  }

  async function buildPositionData({ examId, graphData }: any, exam: Exam) {
    const querySub = query(collection(db, 'results'), where('examId', '==', examId));
    let respResults = (await QueryUtils.executeQuery(querySub)) as ExamResult[];

    const dataInPct: any[] = [
      { label: '0-16%', quantity: 0 },
      { label: '16%-33%', quantity: 0 },
      { label: '33%-50%', quantity: 0 },
      { label: '50%-66%', quantity: 0 },
      { label: '66%-83%', quantity: 0 },
      { label: '83%-100%', quantity: 0 },
    ];

    respResults.forEach((userResult) => {
      let hits = 0;
      exam?.questions.forEach((question) => {
        if (ArrayUtils.answeredCorrectly(question, userResult)) {
          hits++;
        }
      });
      userResult.questions = exam?.questions;
      userResult.correctAnswers = hits;
      let hitsPct = percent(hits, exam?.questions?.length || 1);

      dataInPct[getHitRangeIndex(hitsPct)].quantity++;
    });

    setRespResults(respResults);
    setUserCorrectAnswers(
      propsData?.graphData?.map((item: any) => item.correctAnswers).reduce((acc: any, currentValue: any) => acc + currentValue)
    );

    if (exam) {
      const userHits = propsData?.graphData
        ?.map((item: any) => item.correctAnswers)
        .reduce((acc: any, currentValue: any) => acc + currentValue);
      const total = exam.questions?.length;
      const userIndex = getHitRangeIndex(percent(userHits, total));
      if (userIndex !== undefined && !dataInPct[userIndex]?.quantity) dataInPct[userIndex].quantity = 1;
    }

    const categories: any[] = dataInPct.map((item: any) => item.label);
    const series = [
      { name: 'Correct Answers', type: 'bar', data: dataInPct.map((item: any) => item.quantity) },
      //{name: 'Wrong Answers', type: 'bar', data: chartData.map((item: any) => item.total - item.correctAnswers) },
    ];

    const options = {
      colors: ['purple'],
      chart: {
        id: 'chart-line',
        events: {
          mounted: (chart: any) => {
            setSelectCol();
          },
        },
      },
      ions: {
        bar: {
          borderRadius: 10,
          //columnWidth: '20%',
          dataLabels: {
            //position: 'top'
          },
        },
      },
      dataLabels: {
        formatter: (val: any, opts: any) => {
          return userIndex == opts.dataPointIndex ? 'You!' : '';
        },
      },
      stacked: false,
      xaxis: { categories },
      yaxis: [
        {
          axisBorder: {
            show: true,
            color: '#008FFB',
          },
          labels: {
            style: {
              colors: 'red',
            },
          },
        },
      ],
    };

    //console.log({ series, options })
    setPositionChartData({ series, options });
  }

  function setSelectCol() {
    if (!exam) return;
    const userHits = propsData?.graphData
      ?.map((item: any) => item.correctAnswers)
      .reduce((acc: any, currentValue: any) => acc + currentValue);
    const total = exam.questions?.length;
    userIndex = getHitRangeIndex(percent(userHits, total));

    const series: any = document.querySelector('.apexcharts-series')?.children;
    if (series?.length) {
      Array.from(series).forEach((item: any, i: number) => {
        if (i === userIndex) {
          item.style.fill = 'rgba(0, 143, 251, 0.85)';
          item.style.filter = 'none';
        }
        /* else {
          item.style.fill = 'rgba(0, 227, 150, 0.85)';
          item.style.opacity = '1';
          item.style.filter = 'none';
        } */
      });
    }
  }

  function percent(quantity: number, total: number) {
    return (quantity / total) * 100;
  }

  function buildSubjectChartData(chartData: any) {
    const categories: any[] = chartData.map((item: any) => item.subSubject || 'other');
    const series = [
      { name: 'Correct Answers', type: 'bar', data: chartData.map((item: any) => item.correctAnswers) },
      { name: 'Wrong Answers', type: 'bar', data: chartData.map((item: any) => item.total - item.correctAnswers) },
    ];

    const options = {
      colors: ['rgba(0, 227, 150, 0.85)', '#b84644'],
      chart: { id: 'chart-line' },
      toolbar: {
        show: false,
      },
      stacked: false,
      plotOptions: {
        bar: {
          //borderRadius: 10,
          //columnWidth: '20%',
          dataLabels: {
            //position: 'top'
          },
        },
      },
      xaxis: { categories },
      yaxis: [
        {
          axisBorder: {
            show: true,
            color: '#008FFB',
          },
          labels: {
            style: {
              colors: '#008FFB',
            },
          },
        },
      ],
    };

    setChartData({ series, options });
  }

  function changeSelectedHeader(header: string) {
    setSelectedHeader(header);
  }

  function fetchSelectedImage(imageUrl?: string) {
    if (imageUrl) {
      setSelectedImage(imageUrl);
      handleShowZoom();
    }
  }

  function fetchSelectedVideo(question: Question) {
    setSelectedVideo(question.videoUrl);
    handleShowVideo();
  }

  function getDateFormat(examRes: ExamResult) {
    if (examRes.submitted_date) {
      var result = format(
        new Date(examRes.submitted_date instanceof Date ? examRes.submitted_date : examRes.submitted_date.toDate()),
        'd MMMM yyyy HH:mm:ss'
        //enGB!.formatLong!.date({width:"long"})
      );

      return result;
    }
    //return examRes.created_date.id;
  }

  function setSelectedDate(e: any) {
    setSelectedExamResult(examResultList?.find((r) => r.id === e.target.value));
  }

  function formatForCurve(respResults: any) {
    const ret = respResults?.filter((r: any) => r.userId !== 'lE5aPVeEG1RplroFawUp01NM8xG2' && r.correctAnswers > 0);
    if (ret?.length) {
      const zeroNote = { ...ret[ret.length - 1] };
      zeroNote.correctAnswers = 0;
      ret.push(zeroNote);
    }

    return ret;
  }

  async function sendEmail() {
    if (!emailContact || emailContact === '' || !isValidEmail(emailContact)) {
      showToastMessage('Please enter a valid email address');
      return;
    }
    await addDoc(collection(db, 'email-contacts'), {
      date: new Date(),
      email: emailContact,
      dateOfBirth: dateOfBirthContact || '',
    });

    setEmailContact('');
    setDateOfBirthContact('');

    setFirstScreen(false)
  }

  function isValidEmail(email: string) {
    // Regular expression to check if the email format is valid
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(String(email).toLowerCase());
  }

  return (
    <>
      <section id="exam-results-modal">
        <Modal show={showModal} onHide={handleHide} dialogClassName={'exam-results-modal' + (animationDone ? ' anim-done' : '')}>
          <Modal.Header closeButton>
            <div className="title-container">
              <span
                onClick={() => changeSelectedHeader('ranking')}
                className={selectedHeader === 'ranking' && !firstScreen ? 'selected' : ''}
              >
                Ranking
              </span>
              <span
                onClick={() => changeSelectedHeader('answers')}
                className={selectedHeader === 'answers' && !firstScreen ? 'selected' : ''}
              >
                Answers
              </span>
              <span
                onClick={() => changeSelectedHeader('performance')}
                className={selectedHeader === 'performance' && !firstScreen ? 'selected' : ''}
              >
                Performance
              </span>
            </div>
          </Modal.Header>
          <Modal.Body>
            {firstScreen && (
              <div className="client-info">
                <span>
                  Please complete the details below to view your child’s age-based standardised score. Be sure to keep an eye open for
                  our emails where we’ll be sending you special offers, services and discounts on our products over the months ahead.
                </span>

                <div>
                  <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                  <input value={emailContact} onChange={(e) => setEmailContact(e.target.value)} className="form-control form-control-lg form-control-solid" type="email" name="email" />
                </div>

                <div>
                  <label className="form-label fs-6 fw-bolder text-dark">Date of birth</label>
                  <input type="date" value={dateOfBirthContact} onChange={(e) => setDateOfBirthContact(e.target.value)} className="form-control form-control-lg form-control-solid" name="date" />
                </div>

                <div className="btns-panel">
                  <Button onClick={() => sendEmail()}>Send</Button>
                  <Button onClick={() => setFirstScreen(false)} className="cancel">
                    Not right now
                  </Button>
                </div>
              </div>
            )}
            {!firstScreen && (
              <div>
                {selectedHeader === 'performance' && (
                  <div className="chart-container">
                    <header>
                      <span>{exam?.title} results by subject</span>
                    </header>
                    <div>
                      {chartData && selectedChart === 'subjects' && (
                        <ChartLoader options={chartData?.options} series={chartData?.series} type="bar" height={450} />
                      )}
                    </div>
                  </div>
                )}

                {selectedHeader === 'ranking' && (
                  <div className="chart-container">
                    <header>
                      <span>Standardized chart:</span>
                    </header>
                    <div style={{maxWidth: '800px'}}>
                      {positionChartData && respResults && userCorrectAnswers !== undefined && (
                        <StandardDeviation
                          exam={exam}
                          examResults={formatForCurve(respResults)}
                          defaultResults={exam?.defaultResults}
                          userCorrectAnswers={userCorrectAnswers}
                        />
                      )}
                    </div>
                  </div>
                )}
                {selectedHeader === 'answers' && (
                  <div className="answers-container">
                    {!!examResultList?.length && (
                      <div className="exam-date-container">
                        <span>Exam date:</span>
                        <select
                          className="form-select form-select-lg form-select-solid"
                          data-control="select2"
                          data-placeholder="Select Type..."
                          value={selectedExamResult?.id}
                          onChange={setSelectedDate}
                        >
                          {examResultList?.map((examRes, i: number) => (
                            <option key={examRes.id} value={examRes.id} className={i === 0 ? 'official' : ''}>
                              {getDateFormat(examRes)} {i === 0 && '(First try)'}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                   <ResultAnswers fetchSelectedImage={fetchSelectedImage} fetchSelectedVideo={fetchSelectedVideo} exam={exam} examSubjects={examSubjects} selectedExamResult={selectedExamResult}></ResultAnswers>
                  </div>
                )}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </section>
      {selectedImage && (
        <Modal show={showZoomModal} onHide={handleCloseZoom} centered dialogClassName={'zoom-modal'}>
          <Modal.Body>
            <div>
              <img src={selectedImage} />
            </div>
          </Modal.Body>
        </Modal>
      )}

      {selectedVideo && (
        <Modal show={showVideoModal} onHide={handleCloseVideo} centered dialogClassName={'zoom-modal'}>
          <Modal.Body>
            <div>
              <div>
                <iframe height={600} width={900} src={selectedVideo} allowFullScreen></iframe>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
});

import { useRef, useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, query, setDoc, where } from 'firebase/firestore';
import './signUp.scss';
import { db } from '../../services/firebase';
import { doc } from 'firebase/firestore';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics';
import { useGlobalState } from '../../services/globals';
import { Auth } from '../../layouts/auth/auth';
import { ToastMessage } from '../../components/toast/ToastMessage';
import CryptoJS from 'crypto-js';
import { PdfModal } from '../../components/pdf-modal/PdfModal';
import { TermsAndConditions } from '../../components/pdf-modal/terms-and-conditions/terms-and-conditions';
import { QueryUtils } from '../../util/query-utils';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  birthDate: '',
  parentalPassword: '',
  acceptTerms: false,
};

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .matches(/^[^\s]+$/, 'Spaces are not allowed')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
  lastname: Yup.string()
    .matches(/^[^\s]+$/, 'Spaces are not allowed')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  birthDate: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Date of birth is required'),
  password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  parentalPassword: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Parental Password is required'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});

export const SignUp = () => {
  const toastRef: any = useRef(null);
  const pdfModal: any = useRef(null);
  const global = useGlobalState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { subscription } = useParams();
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      const found = await findDuplicateUser(values.email, values.firstname, values.lastname);
      if (!found?.length) {
        var key = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
        var hash = CryptoJS.AES.encrypt(values.parentalPassword, key, { mode: CryptoJS.mode.ECB }).toString();
        createFirebaseAuth(values.email, values.password, values.firstname, values.lastname, hash, values.birthDate, 0);
      } else {
        toastRef.current.toggleShow('User already exists', true);
        setLoading(false);
      }
    },
  });

  async function findDuplicateUser(email: string, firstName: string, lastName: string) {
    const username = firstName + lastName.substring(0, 1);
    var firstLetter = username[0].toUpperCase();
    var lastLetter = username[username.length - 1].toUpperCase();
    var middleLetters = username.substring(1, username.length - 1);

    const userFinal = firstLetter + middleLetters + lastLetter;
    //can you find user in firebase firestore by username and email
    const querySub = query(collection(db, 'user'), where('email', '==', email), where('login', '==', userFinal));
    const resp: any = await QueryUtils.executeQuery(querySub);
    return resp;
  }

  function convertLogin(username: string) {
    var firstLetter = username[0].toUpperCase();
    var lastLetter = username[username.length - 1].toUpperCase();
    var middleLetters = username.substring(1, username.length - 1);

    return firstLetter + middleLetters + lastLetter;
  }

  const createFirebaseAuth = async (email: string, pwd: string, firstName: string, lastName: string, parentalPassword: string, birthDate: any, attempt: number) => {
    let generatedLogin = convertLogin(firstName + lastName.substring(0, 1)) + (attempt || '');
    //generatedLogin = convertLogin(generatedLogin);
    const auth = getAuth();
    if (attempt >= 5) {
      setLoading(false);
      return;
    }
    try {
      const user = await createUserWithEmailAndPassword(auth, generatedLogin + '@mockexamcentre.com', pwd);
      if (subscription?.includes('subscriptionIntent')) {
        sessionStorage.setItem('subscriptionIntent', subscription?.split(':')[1] || '');
      }
      createUserInDatabase(user.user?.uid, email, pwd, firstName, lastName, parentalPassword, new Date(birthDate), generatedLogin);
    } catch (e: any) {
      if (e.message === 'Firebase: Error (auth/email-already-in-use).') {
        createFirebaseAuth(email, pwd, firstName, lastName, parentalPassword, birthDate, ++attempt);
      } else {
        toastRef.current.toggleShow(e.message, true);
      }
    }
  };

  const createUserInDatabase = async (uid: string, email: string, pwd: string, firstName: string, lastName: string, parentalPassword: string, birthDate: any, generatedLogin: string) => {
    const campaign = window.localStorage.getItem('campaign') || '';
    await setDoc(doc(db, 'user', uid), {
      id: uid,
      firstName,
      lastName,
      parentalPassword,
      campaign,
      date: new Date().toISOString(),
      birthDate: birthDate.toISOString(),
      login: generatedLogin,
      email,
    });

    global.setUserNameInput(generatedLogin);

    const analytics = getAnalytics();
    setUserProperties(analytics, { registered: 'yes' });
    setUserId(analytics, uid);
    //  setLoading(false);
  };

  return (
    <Auth>
      <section id="login" className="bg-white signup">
        <form className="form w-100" noValidate id="kt_login_signup_form" onSubmit={formik.handleSubmit}>
          {/* begin::Title */}
          <div className="pb-lg-15">
            <h3 className="fw-bolder text-dark display-6 size">Create Account</h3>
            {subscription?.includes('subscriptionIntent') && <div className="create-acc-warning">Please create an account before proceeding to checkout</div>}
            <p className="text-muted fw-bold login-title">
              Already have an account?{' '}
              <a onClick={() => navigate(subscription ? `/login/${subscription}` : '/login')} className="login-instead">
                Login instead
              </a>
            </p>
          </div>
          {/* end::Title */}

          {formik.status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/* begin::Form group Email */}
          <div className="mb-5">
            <label className="form-label fs-6 fw-bolder text-dark pt-5">Parent/Guardian's Email Address</label>
            <input
              type="email"
              autoComplete="off"
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Firstname */}
          <div className="pupil">
            <div className="mb-5">
              <label className="form-label fs-6 fw-bolder text-dark pt-5">Pupil's First name</label>
              <input
                type="text"
                autoComplete="off"
                {...formik.getFieldProps('firstname')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.firstname && formik.errors.firstname,
                  },
                  {
                    'is-valid': formik.touched.firstname && !formik.errors.firstname,
                  }
                )}
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.firstname}</div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Lastname */}
            <div className="mb-5">
              <label className="form-label fs-6 fw-bolder text-dark pt-5">Pupil's Surname</label>
              <input
                type="text"
                autoComplete="off"
                {...formik.getFieldProps('lastname')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.lastname && formik.errors.lastname,
                  },
                  {
                    'is-valid': formik.touched.lastname && !formik.errors.lastname,
                  }
                )}
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.lastname}</div>
                </div>
              )}
            </div>
          </div>
          {/* end::Form group */}

          <div className="fv-row">
            <label className="form-label fs-6 fw-bolder text-dark pt-5">Date of birth</label>
            <input
              type="date"
              autoComplete="off"
              {...formik.getFieldProps('birthDate')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.birthDate && formik.errors.birthDate,
                },
                {
                  'is-valid': formik.touched.birthDate && !formik.errors.birthDate,
                }
              )}
            />
            {formik.touched.birthDate && formik.errors.birthDate && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.birthDate}</div>
              </div>
            )}
          </div>

          {/* begin::Form group Password */}
          <div className="mb-5">
            <label className="form-label fs-6 fw-bolder text-dark pt-5">Password</label>
            <input
              type="password"
              autoComplete="off"
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className="">
            <label className="form-label fs-6 fw-bolder text-dark pt-5">Confirm Password</label>
            <input
              type="password"
              autoComplete="off"
              {...formik.getFieldProps('changepassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                },
                {
                  'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                }
              )}
            />
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.changepassword}</div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Parental Password */}
          <div className="fv-row">
            <label className="form-label fs-6 fw-bolder text-dark pt-5">Parental Password</label>
            <input
              type="password"
              autoComplete="off"
              {...formik.getFieldProps('parentalPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.parentalPassword && formik.errors.parentalPassword,
                },
                {
                  'is-valid': formik.touched.parentalPassword && !formik.errors.parentalPassword,
                }
              )}
            />
            {formik.touched.parentalPassword && formik.errors.parentalPassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.parentalPassword}</div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className="">
            <div className="form-check form-check-custom form-check-solid mb-5">
              <input className="form-check-input mt-6" type="checkbox" id="kt_login_toc_agree" {...formik.getFieldProps('acceptTerms')} />
              <label className="form-check-label fw-bold text-gray-600 mt-6" htmlFor="kt_login_toc_agree">
                I Agree to the
                <Link to="" className="ms-1" onClick={() => pdfModal?.current?.handleShow(<TermsAndConditions />)}>
                  terms and conditions
                </Link>
                .
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                </div>
              )}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className="d-flex flex-wrap pb-lg-0 pb-5">
            <button type="submit" id="kt_login_signup_form_submit_button" className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-4" disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}>
              {!loading && <span className="indicator-label">Submit</span>}
              {loading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <Link to="/login">
              <button type="button" id="kt_login_signup_form_cancel_button" className="btn btn-light-primary fw-bolder fs-6 px-8 py-4 my-3">
                Cancel
              </button>
            </Link>
          </div>
          {/* end::Form group */}
        </form>
      </section>
      <ToastMessage ref={toastRef} />
      <PdfModal ref={pdfModal} title="Terms and Conditions" />
    </Auth>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import './admin-dashboard.scss';
import { ProtectedPages } from '../../layouts/admin/ProtectedLayout';
import { WidgetBox } from '../../components/widget-box/WidgetBox';
import { DonutChart as DonutChart } from '../../components/donut-chart/DonutChart';
import { collection, limit, orderBy, query, where } from 'firebase/firestore';
import { Exam } from '../../models/exam';
import { useUserState } from '../../services/User';
import { QueryUtils } from '../../util/query-utils';
import { db } from '../../services/firebase';
import { ArrayUtils } from '../../util/array-utils';
import { useStaticState } from '../../services/StaticContext';
import { ExamResult } from '../../models/exam-result';
import Chart from 'react-apexcharts';
import { addMinutes, format } from 'date-fns';
import { Utils } from '../../util/string-utils';
import { Role } from '../../models/role.enum';
import { User } from '../../models/user';
import { BarChart } from '../../components/bar-chart/BarChart';
import { CalendarWidget } from '../../components/calendar-widget/CalendarWidget';
import { UploadExamsWidget } from '../../components/upload-exams-widget/UploadExamsWidget';

const AdminDashboard = () => {
  const user = useUserState();
  const { examTypes, subjects, subSubjects } = useStaticState();
  const [allResults, setAllResults] = useState<ExamResult[]>([]); //these are all user results
  const [allExams, setAllExams] = useState<Exam[]>([]);
  const [allStudents, setAllStudents] = useState<User[]>();

  useEffect(() => {
    if (user?.id && subjects?.length) {
      getUsers();
      getExamResultsOverTime();
    }
  }, [user?.id, subjects]);

  async function getUsers() {
    const queryUsers = query(collection(db, 'user'));
    const respUsers = (await QueryUtils.executeQuery(queryUsers)) as User[];
    setAllStudents(respUsers.filter((user) => user.role !== Role.ADMIN));
  }

  //Does not matter weather its online or not, statitcs should still show from offline exams
  async function getExamResultsOverTime() {
    const queryExams = query(collection(db, 'exam'));
    const respExams = (await QueryUtils.executeQuery(queryExams)) as Exam[];
    setAllExams(respExams);

    const querySub = query(collection(db, 'results'), orderBy('created_date', 'desc'));
    let respResults = (await QueryUtils.executeQuery(querySub)) as ExamResult[];
    respResults = Utils.filterOutOnGoingExamsAndOld(respExams, respResults);

    updateSelectedExamResults(respExams, respResults);
  }

  function updateSelectedExamResults(respExams: Exam[], respResults: ExamResult[]) {
    respResults?.forEach((result) => {
      const exam = respExams?.find((exam) => exam.id === result.examId);
      result.examType = exam?.type;
      result.questions = exam?.questions;

      if (!result.submitted_date && exam?.timeMinutes) {
        result.submitted_date = addMinutes(result.created_date.toDate(), exam.timeMinutes);
      }

      result.correctAnswers = 0;
      exam?.questions.forEach((question) => {
        if (ArrayUtils.answeredCorrectly(question, result)) {
          result.correctAnswers++;
        }
      });

      result.skipped = result!.questions!.length - (result?.answers?.length || 0);
    });

    setAllResults(respResults);
  }

  function percent(quantity?: number, total?: number) {
    if (quantity !== undefined && total) return ((quantity / total) * 100).toFixed(2) + '%';
  }

  function getDonutChartData() {
    const userExamsCorrectAnswers = allResults?.map((item) => item.correctAnswers || 0);
    const userExamsSkippedAnswers = allResults?.map((item) => item.skipped || 0);
    const chartData = {
      options: {
        labels: ['Correct', 'Incorrect', 'Skipped'],
        colors: ['rgba(0, 227, 150, 0.85)', '#b84644', 'orange'],
        /*  tooltip: {
          y: {
            formatter: function(value: any) {
              return value + '%';
            }
          }
        }, */
        /* plotOptions: {
          pie: {

          }
        }, */
        /* dataLabels: {

        } */
        //dataLabels: {enabled: false},
        legend: {
          position: 'bottom',
        },
      },
      series: [
        userExamsCorrectAnswers?.reduce((acc, currentValue) => acc + currentValue, 0),
        allResults?.map((item) => allExams!.find((exam) => exam.id === item.examId)!.questions!.length - (item.correctAnswers || 0))?.reduce((acc, currentValue) => acc + currentValue, 0),
        userExamsSkippedAnswers?.reduce((acc, currentValue) => acc + currentValue, 0),
      ],
    };

    return chartData;
  }

  function getMostPerformedExamsChart() {
    const categories: any[] = [];
    const data: any[] = [];
    const examMap: any[] = [];

    allResults.forEach((result) => {
      const foundExam = examMap.find((e) => e.examId === result.examId);
      if (!foundExam) {
        examMap.push({ examId: result.examId, quantity: 1 });
      } else {
        foundExam.quantity++;
      }
    });

    // Sort the exams by quantity in descending order
    examMap.sort((a, b) => b.quantity - a.quantity);

    examMap.slice(0, 10).forEach((item) => {
      // Take only the first 10 exams
      const exam = allExams.find((e) => e.id === item.examId);
      if (exam) {
        categories.push(exam.title);
        data.push(item.quantity);
      }
    });

    const chartData = {
      options: {
        chart: { id: 'Most taken exams' },
        xaxis: { categories },
      },
      series: [{ name: 'series-1', data }],
    };

    return chartData;
  }

  return (
    <ProtectedPages>
      {user.id && user.role == Role.ADMIN && (
        <section id="admin-dashboard">
          <div className="widgets-container">
            <div className="welcome-box">
              <div className="title-welcome">
                <img src="/icons/user.svg" height={50} />
                <div>
                  <span className="first-line">{allStudents?.length}</span>
                  <span className="second-line">Students</span>
                </div>
              </div>

              <div className="welcome-data">
                <span>{allStudents?.filter((user) => user.payments)?.length} Paid</span>
                <span>{allStudents?.filter((user) => !user.payments)?.length} Free</span>
              </div>
              <div></div>
            </div>
            <UploadExamsWidget></UploadExamsWidget>
            {/* <WidgetBox title="Calendar" isDate={true} subtitle='Next exams' text1='Calendar' text2='Placeholder text 2'/> */}
            <CalendarWidget title="Calendar" isDate={true} subtitle="Next exams" text1="Calendar" text2="Placeholder text 2" />
          </div>

          <div className="widgets-container">
            {allResults && <DonutChart title="Student's Performance" data={getDonutChartData()} />}
            {allResults && <BarChart data={getMostPerformedExamsChart()} />}
          </div>
        </section>
      )}
    </ProtectedPages>
  );
};

export default AdminDashboard;

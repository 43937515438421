import React, { useState, useRef, useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './navbar-landing.scss'
import useWindowDimensions from '../../services/window-dimensions';

let block: boolean;
const NavbarLanding = (props: any) => {
  const navigate = useNavigate();
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const location = useLocation();
  const mobileNavRef = useRef<HTMLDivElement>(null);
  const { height, width, isMobile } = useWindowDimensions();

 /*  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      setTimeout(() => {
      if (mobileNavRef.current && !mobileNavRef.current.contains(event.target as Node)) {
        if (!block) setIsNavExpanded(false)
      }
      }, 10);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }); */

  function setCurrentNav(navValue: string) {
    setIsNavExpanded(!isNavExpanded);
    window.localStorage.setItem('currentNav', navValue);
  }

  function getCurrentNav(): string {
    const val = window.localStorage.getItem('currentNav');
    if (val) {
      return val;
    } else {
      return 'home';
    }
  }

  function isActive(page: string) {
    if (location.pathname.includes('perform-routine')) {
      return '';
    }

    if (getCurrentNav() === page) {
      return ' active';
    } else {
      return '';
    }
  }

  function menuClick() {
    block = true;
    setIsNavExpanded(!isNavExpanded);
    setTimeout(() => {
      block = false;
    }, 100);
  }

  function goToPage(route: string) {
    navigate(route);
    menuClick();
  }

  function goToTop() {
   /*  navigate('/landing');
    document.documentElement.scrollTop = 0; */
    window.location.href = 'https://mockexamcentre.co.uk/';
  }

  function onClickMobileNav(item: string) {
    /* if (location.pathname.includes('free-test')) {
      navigate('/landing/section:' + item);
    } */
    setCurrentNav('');
    window.location.href = 'https://mockexamcentre.co.uk/';
  }

  return (

    ( location.pathname.includes('/landing') || location.pathname === '/free-test' ) ? <div id='landing-nav' className={isMobile ? ' mobile': '' + (isNavExpanded ? " custom-expanded" : "")}>
      <Navbar bg="light" expand="sm">
        <div className='nav-container'>
          <div className="menu-content-container">
            {!isMobile && 
            <div className='header-section'>
              <div className="logo" onClick={goToTop}>
                <img className="blue-logo-mock" src="/media/images/blue-logo-mock.svg" alt="logo-mock" />
              </div>
              <div className='items-header'>
                <Nav.Link onClick={() => goToTop()}>Home</Nav.Link>
                <Nav.Link onClick={() => onClickMobileNav('about')} id="about">About Us</Nav.Link>
                <Nav.Link onClick={() => onClickMobileNav('prices')} id="prices">Prices</Nav.Link>
                <Nav.Link onClick={() => onClickMobileNav('exams')} id="exams">Exams</Nav.Link>
                <Nav.Link onClick={() => onClickMobileNav('faqs')} id="faqs">Faqs</Nav.Link>

                <button id="button-login" className="login-button" onClick={() => goToPage('/login')}>
                  <span className="login-button-text">LOG IN</span>
                </button>

                <button className="get-started-free-button" onClick={() => goToPage('/free-test')} >
                  <span className="get-started-free-button-text">GET STARTED FOR FREE</span>
                </button>
                </div>
            </div>
            }

             {isMobile && <div className="right-nav-container">
                <div onClick={goToTop} className="logo">
                  <img className="blue-logo-mock" src="/media/images/blue-logo-mock.svg" alt="logo-mock" />
                </div>
                  <img src="/media/images/menu-icon.svg" className='menu-btn' alt="logo-mock" onClick={() => menuClick()}  />
            </div>}
          </div>

        </div>

        {isMobile && <div ref={mobileNavRef} className={ isNavExpanded ? "nav-container-mobile expanded" : "nav-container-mobile" }>
          <Nav.Item className={"underline" + isActive('dashboard')} as={Link} to="/dashboard" onClick={() => setCurrentNav('dashboard')}>
            <span>Home</span>
          </Nav.Item>
          <Nav.Item className={"underline" + isActive('')} id="about" onClick={() => onClickMobileNav('about')}>
            <span>About Us</span>
          </Nav.Item>
          <Nav.Item className={"underline" + isActive('')} id="prices" onClick={() => onClickMobileNav('prices')}>
            <span>Prices</span>
          </Nav.Item>
          <Nav.Item className={"underline" + isActive('exams-list')} id="exams" onClick={() => onClickMobileNav('exams')}>
            <span>Exams</span>
          </Nav.Item>
          <Nav.Item className={"underline" + isActive('')} id="faqs" onClick={() => onClickMobileNav('faqs')}>
            <span>Faqs</span>
          </Nav.Item>

          <div className='mobile-btns'>
            <button id="button-login" className="login-button" onClick={() => goToPage('/login')}>
              <span className="login-button-text">Login</span>
            </button>

            <button className="get-started-free-button" onClick={() => goToPage('/free-test')} >
              <span className="get-started-free-button-text mobile">Get started for free</span>
            </button>
          </div>

          <div className='contact-info'>
            <span className='contact-number'>0800 112 3811</span>
            <span>info@mockexamcentre.co.uk</span>
          </div>

          <div className="media-icons">
              <img src="/media/images/instagram.svg" alt="instagram-icon" />
              <img src="/media/images/facebook.svg" alt="facebook-icon" />
              <img src="/media/images/linkedin.svg" alt="linkedin-icon" />
              <img src="/media/images/twitter.svg" alt="twitter-icon" />
              <img src="/media/images/youtube.svg" alt="youtube-icon" />
            </div>
        </div>}
      </Navbar>
    </div>
    : null
  )
}

export default NavbarLanding

import React, { useEffect, useRef, useState } from 'react';
import './ExamCreationStep2.scss';
import { ProtectedPages } from '../../../layouts/admin/ProtectedLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { Exam } from '../../../models/exam';
import { addDoc, collection, doc, getDoc, query, setDoc, where } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useStaticState } from '../../../services/StaticContext';
import { ArrowLeftShort, Exclamation, StarFill, Trash, Trash2Fill, Trash3, Trash3Fill, TrashFill } from 'react-bootstrap-icons';
import { ref, getDownloadURL, uploadBytesResumable, getStorage, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { SubSubject } from '../../../models/sub-subject';
import { Subject } from '../../../models/subject';
import { Question } from '../../../models/question';
import { Utils } from '../../../util/string-utils';
import { Button, Form } from 'react-bootstrap';
import { ArrayUtils } from '../../../util/array-utils';
import { useLoader } from '../../../services/LoaderProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { ConfirmModal } from '../../../components/confirm-modal/ConfirmModal';
import { ExamResult } from '../../../models/exam-result';
import { QueryUtils } from '../../../util/query-utils';
import { ConfirmModalExam } from '../../../components/confirm-modal-exam/ConfirmModalExam';

const selectSubjectPlaceholder = 'Select subject...';
const selectSubSubjectPlaceholder = 'Select sub-subject...';
const uncat = 'Uncategorized';
const modalDesc = 'This exam has been completed by users, if you edit it, a copy of it will be created with the draft status, while the previous one will be deleted and the users will lose access to their results. This page will be reloaded.'

const ExamCreationStep2 = () => {
  const navigate = useNavigate();
  const subjectRef: any = useRef(null);
  const subSubjectRef: any = useRef(null);
  const optionsContainerRef: any = useRef(null);
  const saveBtnRef: any = useRef(null);
  let latestImageClick: any = null;

  const confirmModalSaveRef: any = useRef(null);
  const confirmModalNewQuestionRef: any = useRef(null);
  const confirmModalSaveAndContinueRef: any = useRef(null);
  const confirmModalDeleteRef: any = useRef(null);

  let { examId }: any = useParams();
  const {examTypes, subjects, subSubjects} = useStaticState();
  const [exam, setExam] = useState<Exam>();
  const [originalExam, setOriginalExam] = useState<Exam>();
  const {startLoader, stopLoader} = useLoader();
  const [selectedQuestion, setSelectedQuestion] = useState<Question>();
  const [selectedSubjectSubs, setSelectedSubjectSubs] = useState<SubSubject[]>();
  const [examSubjectsRows, setExamSubjectsRows] = useState<Subject[]>();
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showHeaderImage, setShowHeaderImage] = useState(false);
  const [selectedImageUploadIndex, setSelectedImageUploadIndex] = useState<any>();
  const [selectedImageUpload, setSelectedImageUpload] = useState<any>();
  const [examResults, setExamResults] = useState<ExamResult[]>();

  useEffect(() => {
    if (examTypes?.length && subSubjects?.length && subjects?.length) {
      fetchApi();

      window.addEventListener('paste', (e: any) => {
        setSelectedImageUpload(e.clipboardData.files[0])
      });
    }
  }, [examTypes?.length, subSubjects?.length, subjects?.length]);

  useEffect(() => {
    pasteImageFromClipboard(selectedImageUpload);
  }, [selectedImageUpload]);

  async function fetchApi() {
    const docRef = doc(db, 'exam', examId);
    const docSnap = await getDoc(docRef);
    let examVar: Exam;

    if (!docSnap.exists()) return;

    examVar = { ...(docSnap.data() as Exam), id: docSnap.id } as Exam;

    if (!examVar.questions?.length) {
      examVar.questions = [];
      addNewQuestion(examVar);
    }
    setExam({ ...examVar });
    setOriginalExam({ ...examVar });

    const selectedQuestion = examVar.questions.find((q) => !!q.selected);
    setShowHeaderImage(selectedQuestion?.headerImageUrl);
    setSelectedQuestion(selectedQuestion);

    if (selectedQuestion?.subject) {
      setSelectedSubjectSubs(subSubjects.filter((s: any) => s.subjectId === selectedQuestion.subject));
    }

    fetchResults(examVar);

    populateSubjects(examVar);
  }

  async function fetchResults(exam: Exam) {
    const querySub = query(collection(db, 'results'), where('examId', '==', exam.id));
    const querySub2 = query(collection(db, 'additional-results'), where('examId', '==', exam.id));
    const resp = await QueryUtils.executeQuery(querySub);
    const resp2 = await QueryUtils.executeQuery(querySub2);
    const userResults = resp2.concat(resp);

    setExamResults(userResults);
  }

  function populateSubjects(examVar: Exam) {
    const subs: Subject[] = [];

    examVar.questions?.forEach((question) => {
      if (!question.subject) {
        if (!subs.some((sub) => sub.id === uncat)) {
          subs.push({ id: uncat, name: uncat });
        }
      }

      if (!subs.some((sub) => sub.id === question.subject)) {
        if (question?.subject) {
          const found = subjects.find((s: any) => s.id === question.subject);
          if (found) subs.push(found);
        }
      }
    });
    setExamSubjectsRows(subs);
  }

  function mockPasteImage() {
    fetch('/logo-mock.png')
    .then(response => response.blob())
    .then(blob => {
      const file = new File([blob], 'myImage.png', { type: blob.type });
      setSelectedImageUpload(file);
    })
    .catch(error => console.error(error));
  }

  async function addNewQuestion(examVar?: Exam) {
    //if (exam?.onlineOnce) return;
    startLoader();
    const uid = uuidv4().replace(/-/gi, '');
    const examCopy = exam ? { ...exam } : examVar;

    if (examCopy) {
      if (!examCopy.questions) examCopy.questions = [];

      const prevSelectedQuestion = {...selectedQuestion} as Question;
      const newSelectedQuestion: any = { id: uid, order: examCopy.questions!.length + 1, selected: true, options: ['', '', '', '', ''] };
      if (prevSelectedQuestion.subject) newSelectedQuestion.subject = prevSelectedQuestion.subject;
      if (prevSelectedQuestion.subSubject) newSelectedQuestion.subSubject = prevSelectedQuestion.subSubject;
      examCopy.questions.push(newSelectedQuestion);

      examCopy.questions = ArrayUtils.updateObjectInArray(examCopy?.questions, newSelectedQuestion);
      setExam({ ...examCopy } as any);

      const defaultResults = [];
      for (let i = 0; i < 15; i++) {
        defaultResults.push(Math.floor(Math.random() * examCopy.questions!.length) + 1);
      }
      examCopy.defaultResults = defaultResults;

      if (examResults?.length) {
        await setDoc(doc(db, 'exam', examId), {...examCopy, deleted: true});
        const resp = await addDoc(collection(db, 'exam'), {...examCopy, isOnline: false, onlineOnce: false});
        navigate('/exam-creation-subject/' + resp.id);
        window.location.reload()
      } else {
        await setDoc(doc(db, 'exam', examId), examCopy);
      }

      setSelectedQuestion(newSelectedQuestion);

      rebuildExamSubSubjects(examCopy);
    }

    stopLoader();
  }

  async function saveQuestion(selQuestion?: Question) {
    const questionVar = selQuestion || ({ ...selectedQuestion } as Question);
    removeSubjectsFromQuestion(questionVar);

    const examCopy = { ...exam } as Exam;
    examCopy.questions = examCopy?.questions?.map((quest) => (quest.id !== questionVar.id ? quest : questionVar));

    setExam(examCopy);

    rebuildExamSubSubjects(examCopy);

    const defaultResults = [];
    for (let i = 0; i < 15; i++) {
      defaultResults.push(Math.floor(Math.random() * examCopy.questions!.length) + 1);
    }
    examCopy.defaultResults = defaultResults;

    if (examResults?.length) {
      await setDoc(doc(db, 'exam', examId), {...examCopy, deleted: true});
      const resp = await addDoc(collection(db, 'exam'), {...examCopy, isOnline: false, onlineOnce: false});
      navigate('/exam-creation-subject/' + resp.id);
      window.location.reload()
    } else {
      await setDoc(doc(db, 'exam', examId), examCopy);
    }
    setSaveLoading(false);
  }

  async function saveQuestionImage(selQuestion?: Question) {
    const questionVar = selQuestion || ({ ...selectedQuestion } as Question);
    removeSubjectsFromQuestion(questionVar);

    const examCopy = { ...originalExam } as Exam;
    examCopy.questions = examCopy?.questions?.map((quest) => (quest.id !== questionVar.id ? quest : questionVar));

    setExam(examCopy);
/* 
    rebuildExamSubSubjects(examCopy);

    const defaultResults = [];
    for (let i = 0; i < 15; i++) {
      defaultResults.push(Math.floor(Math.random() * examCopy.questions!.length) + 1);
    }
    examCopy.defaultResults = defaultResults; */

    await setDoc(doc(db, 'exam', examId), examCopy);
    setSaveLoading(false);
  }

  async function saveSpecial() {
    const questionVar = ({ ...selectedQuestion } as Question);
    const examCopy = { ...originalExam } as Exam;
    if (!questionVar.correctOptions?.length) {
      return;
    }
    startLoader()

    const foundQuestion = examCopy?.questions?.find((q) => q.id === questionVar.id);
    if (foundQuestion) {
      foundQuestion.correctOptions = questionVar.correctOptions;
      foundQuestion.videoUrl = questionVar.videoUrl || '';
      foundQuestion.headerImageUrl = showHeaderImage ? (questionVar.headerImageUrl || '') : null;
    }

    await setDoc(doc(db, 'exam', examId), examCopy);
    window.location.reload()
  }

  function rebuildExamSubSubjects(examCopy: Exam) {
    const newSubs = new Set();
    examCopy.questions.forEach((question) => newSubs.add(question.subject || uncat));

    const result = orderedTheSame(Array.from(newSubs)).map((su) => subjects.find((s) => s.id === su) || { id: uncat, name: uncat });
    setExamSubjectsRows(result?.length ? result : [{ id: uncat, name: uncat }]);
  }

  async function deleteQuestion() {
    startLoader();
    setDeleteLoading(true);
    const examCopy = { ...exam } as Exam;
    examCopy.questions = examCopy.questions
      .filter((quest) => quest.id !== selectedQuestion?.id)
      .map((quest) => {
        return { ...quest, order: quest.order ? quest.order - 1 : 0 };
      });
    setExam(examCopy);

    rebuildExamSubSubjects(examCopy);

    const defaultResults = [];
    for (let i = 0; i < 15; i++) {
      defaultResults.push(Math.floor(Math.random() * examCopy.questions!.length) + 1);
    }
    examCopy.defaultResults = defaultResults;

    if (examResults?.length) {
      await setDoc(doc(db, 'exam', examId), {...examCopy, deleted: true});
      const resp = await addDoc(collection(db, 'exam'), {...examCopy, isOnline: false, onlineOnce: false});
      navigate('/exam-creation-subject/' + resp.id);
    } else {
      await setDoc(doc(db, 'exam', examId), examCopy);
    }
    setSelectedQuestion(examCopy.questions[0]);
    setShowHeaderImage(examCopy.questions[0].headerImageUrl);
    setDeleteLoading(false);
    stopLoader();
  }

  function orderedTheSame(newSubs: any[]) {
    if (examSubjectsRows) {
      return newSubs.sort(function (a, b) {
        return examSubjectsRows.findIndex((e) => e.id === a) - examSubjectsRows.findIndex((e) => e.id === b);
      });
    } else {
      return [];
    }
  }

  function removeSubjectsFromQuestion(questionVar: Question) {
    if (!questionVar.subject) delete questionVar.subject;
    if (!questionVar.subSubject) delete questionVar.subSubject;
  }

  function pasteImageFromClipboard(file: any) {
    if (selectedImageUploadIndex !== undefined) {
      if (saveLoading || deleteLoading) return;
      setSaveLoading(true);

      if (selectedImageUploadIndex === 0) {
        uploadImage(file, 'questionImageUrl');
      } else if (selectedImageUploadIndex === 1) {
        uploadImage(file, 'resolutionImageUrl', 'answer');
      } else if (selectedImageUploadIndex === 2) {
        uploadImage(file, 'headerImageUrl', 'header');
      } else {
        setSelectedQuestion({ ...selectedQuestion! });
        saveQuestion();
      }
    }
  }

  const handleSubmit = async (e: any, goToNext = false) => {
    e.preventDefault();
    if (saveLoading || deleteLoading) return;
    setSaveLoading(true);

    const file = e.target[0]?.files?.[0];
    const resolutionImageUrl = e.target[1]?.files?.[0];
    const headerImageUrl = e.target[2]?.files?.[0];

    if (latestImageClick === 0) {
      uploadImage(file, 'questionImageUrl');
    } else if (latestImageClick === 1) {
      uploadImage(resolutionImageUrl, 'resolutionImageUrl', 'answer');
    } else if (latestImageClick === 2) {
      uploadImage(headerImageUrl, 'headerImageUrl', 'header');
    } else {
      setSelectedQuestion({ ...selectedQuestion! });
      await saveQuestion();
    }
    latestImageClick = null;

    if (goToNext && !examResults?.length) {
      const questionIndex = exam?.questions.findIndex(q => q.id === selectedQuestion!.id);

      if (questionIndex! < (exam!.questions!.length -1)) {
        setSelectedQuestion({...exam!.questions[questionIndex!+1]});
      } else {
        addNewQuestion();
      }
    }
  };

  function uploadImage(file: any, reference: any, type?: string) {
    if (!file?.name) {
      return;
    }
    let prefix = '';
    if (type === 'header') {
      prefix += 'header-';
    } else if (type === 'answer') {
      prefix += 'answer-';
    }

    const storage = getStorage();
    const storageRef = ref(storage, `exams/${examId}/${selectedQuestion?.id}/${prefix}${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot: any) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error: any) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setSelectedQuestion({ ...selectedQuestion!, [reference]: downloadURL });
          examResults?.length
            ? saveQuestionImage({ ...selectedQuestion!, [reference]: downloadURL })
            : saveQuestion({ ...selectedQuestion!, [reference]: downloadURL });
        });
      }
    );
  }

  function updateOption(selectedQuestionOption: any, value: string, i: number) {
    const selQuestionCopy = { ...selectedQuestion } as Question;
    if (selQuestionCopy?.options) {
      selQuestionCopy.options[i] = value;

      setSelectedQuestion(selQuestionCopy);
    }
  }

  function updateIsMath() {
    const selQuestionCopy = { ...selectedQuestion } as Question;
    selQuestionCopy.isMath = !selQuestionCopy.isMath;

    selQuestionCopy.correctOptions = [];

    if (selQuestionCopy?.isMath) {
      selQuestionCopy.options = ['','','','','','','','','','']
    } else {
      selQuestionCopy.options = ['','','','','']
    }

    setSelectedQuestion(selQuestionCopy)
  }

  function addOption() {
    const selQuestionCopy = { ...selectedQuestion } as Question;
    if (selQuestionCopy?.options && selQuestionCopy?.options.length <= 20) {
      selQuestionCopy.options.push('');
      setSelectedQuestion(selQuestionCopy);
    }

    setTimeout(() => {
      optionsContainerRef.current.scrollTo(0, optionsContainerRef.current.scrollHeight);
    }, 100);
  }

  function addOptionMath() {
    const selQuestionCopy = { ...selectedQuestion } as Question;
    if (selQuestionCopy?.options && selQuestionCopy?.options.length <= 50) {
      selQuestionCopy.options.push('');
      selQuestionCopy.options.push('');
      selQuestionCopy.options.push('');
      selQuestionCopy.options.push('');
      selQuestionCopy.options.push('');
      selQuestionCopy.options.push('');
      selQuestionCopy.options.push('');
      selQuestionCopy.options.push('');
      selQuestionCopy.options.push('');
      selQuestionCopy.options.push('');
    }
    setSelectedQuestion(selQuestionCopy);
  }

  function deleteOption(i: number) {
    const selQuestionCopy = { ...selectedQuestion };

    if (selQuestionCopy.options && selQuestionCopy.options?.length > 1) {
      selQuestionCopy.options.splice(i, 1);
      setSelectedQuestion(selQuestionCopy as any);
    }
  }

  function deleteOptionMath() {
    const selQuestionCopy = { ...selectedQuestion };
    if (selQuestionCopy?.options && selQuestionCopy.options.length > 10) {

      if (selQuestionCopy.options && selQuestionCopy.options?.length > 1) {
        selQuestionCopy.options = selQuestionCopy.options.filter((opt, i) => i <= (selQuestionCopy!.options!.length - 11));
        setSelectedQuestion(selQuestionCopy as any);
      }
    }
  }

  async function updateQuestionSubject(e: any) {
    const questionCopy = { ...selectedQuestion } as Question;
    const oldSubject = questionCopy.subject;
    if (selectSubjectPlaceholder === e.target.value) {
      questionCopy.subject = undefined;
    } else {
      questionCopy.subject = e.target.value;
      setSelectedSubjectSubs(subSubjects.filter((s) => s.subjectId === e.target.value));
    }

    setSelectedQuestion(questionCopy);
  }

  async function updateQuestionSubSubject(e: any) {
    const q = { ...selectedQuestion };
    q.subSubject = selectSubSubjectPlaceholder === e.target.value ? undefined : e.target.value;
    setSelectedQuestion(q as any);
  }

  function selectQuestion(question: Question) {
    if (saveLoading || deleteLoading) return;

    setSelectedQuestion(question);
    setShowHeaderImage(question.headerImageUrl);
    if (!question.subject) {
      subjectRef.current.value = selectSubjectPlaceholder;
      subSubjectRef.current.value = selectSubSubjectPlaceholder;
      setSelectedSubjectSubs([]);
    } else {
      setSelectedSubjectSubs(subSubjects.filter((s: any) => s.subjectId === question.subject));
    }
  }

  function isChecked(i: number) {
    if (selectedQuestion && selectedQuestion.correctOptions?.length) {
      return selectedQuestion.correctOptions.some((c) => c === i);
    } else {
      return false;
    }
  }

  function updateCorrectOption(i: number) {
    if (!selectedQuestion) return;
    if (!selectedQuestion.correctOptions?.length) selectedQuestion.correctOptions = [];
    if (selectedQuestion.correctOptions.some((c) => c === i)) {
      selectedQuestion.correctOptions = selectedQuestion.correctOptions.filter((item) => item !== i);
    } else {
      selectedQuestion.correctOptions.push(i);
    }

    setSelectedQuestion({ ...selectedQuestion });
  }

  function hasErrors(question: Question) {
    return !question.questionImageUrl || !question.options?.length || !question.correctOptions?.length || !question.subject || !question.subSubject;
  }

  function done() {
    navigate('/exams-list');
  }

  function backToCreation() {
    navigate('/exam-creation/' + examId);
  }

  function updateQuestionOrder(e: any) {
    startLoader();
    const examCopy = { ...exam } as Exam;

    if (examCopy?.questions?.length) {
      const fromItem = examCopy?.questions.splice(
        examCopy.questions.findIndex((q) => q.id === selectedQuestion?.id),
        1
      )[0];
      examCopy?.questions.splice(e.target.value, 0, fromItem);
    }

    setExam(examCopy);

    setTimeout(() => {
      stopLoader();
    }, 500);
  }

  function updateHeaderImage() {
    if (showHeaderImage) {
      setSelectedQuestion({ ...selectedQuestion!, headerImageUrl: null });
      if (!examResults?.length) {
        saveQuestion();
      }
    }
    console.log(showHeaderImage);
    setShowHeaderImage(!showHeaderImage);
  }

  return (
    <ProtectedPages>
      <section id="subject-creation">
        <div className="header-box">
          <div className="left-panel">
            <img src="/icons/angle-right.svg" height={20} />
            <span className="exam-type">
              {examTypes?.find((e: any) => e.id == exam?.type)?.name}: {exam?.title}
            </span>
          </div>
          <div className="right-panel">
            <button type="button" className="btn btn-primary btn-short" onClick={done}>
              <span className="indicator-label">Done</span>
            </button>
          </div>
        </div>

        <div className="sub-suject-container">
          <div className="sub-suject-box">
            <span className="title">Questions</span>
            {exam?.questions
              .map((question, qIndex) => (
                <div key={question.id} onClick={() => selectQuestion(question)} className={'number-box' + (selectedQuestion?.id === question.id ? ' selected' : '') + (hasErrors(question) ? ' has-errors' : '')}>
                  {qIndex + 1}
                </div>
              ))}
            <div onClick={() => examResults?.length ? confirmModalNewQuestionRef.current.handleShow(() => addNewQuestion()) : addNewQuestion()} className="add-q-btn">
              <span>+</span>
            </div>
          </div>
        </div>

        <div>
          <form onSubmit={(e) => handleSubmit(e)} className="form">
            <div className="question-form">
              <div className="left-form">
                <span>Question Image</span>
                {!selectedQuestion?.questionImageUrl && (
                  <div className={"upload-box" + (selectedImageUploadIndex === 0 ? ' selected' : '')} onClick={() => setSelectedImageUploadIndex(0)}>
                    <img className="upload-img" src="/icons/upload.svg" />
                    <input
                      className="upload-input"
                      name="questionImage"
                      type="file"
                      onChange={(e) => {
                        latestImageClick = 0;
                        setSelectedImageUpload(e.target.files![0]);
                      }}
                    />
                  </div>
                )}

                {selectedQuestion?.questionImageUrl &&<div className={"img-container" + (selectedImageUploadIndex === 0 ? ' selected' : '')} onClick={() => setSelectedImageUploadIndex(0)}>
                  <img className="uploaded-file" id="first-image" src={selectedQuestion?.questionImageUrl} alt="uploaded file" />
                  <input
                    className="upload-input secondary"
                    name="questionImage"
                    type="file"
                    onChange={(e) => {
                      latestImageClick = 0;
                      setSelectedImageUpload(e.target.files![0]);
                    }}
                  />
                </div>}

                <span>Resolution Image</span>
                {!selectedQuestion?.resolutionImageUrl && (
                  <div className={"upload-box" + (selectedImageUploadIndex === 1 ? ' selected' : '')} onClick={() => setSelectedImageUploadIndex(1)}>
                    <img className="upload-img" src="/icons/upload.svg" />
                    <input
                      className="upload-input"
                      name="resImage"
                      type="file"
                      onChange={(e) => {
                        latestImageClick = 1;
                        setSelectedImageUpload(e.target.files![0]);
                      }}
                    />
                  </div>
                )}

                {selectedQuestion?.resolutionImageUrl && <div className={"img-container" + (selectedImageUploadIndex === 1 ? ' selected' : '')} onClick={() => setSelectedImageUploadIndex(1)}>
                  <img className="uploaded-file" id="second-image" src={selectedQuestion?.resolutionImageUrl} alt="uploaded file" />
                  <input
                    className="upload-input secondary"
                    name="resImage"
                    type="file"
                    onChange={(e) => {
                      latestImageClick = 1;
                      setSelectedImageUpload(e.target.files![0]);
                    }}
                  />
                </div>}

                <div className="toggle-container">
                  {!selectedQuestion?.headerImageUrl && showHeaderImage && (
                    <div className={"upload-box" + (selectedImageUploadIndex === 2 ? ' selected' : '')} onClick={() => setSelectedImageUploadIndex(2)}>
                      <img className="upload-img" src="/icons/upload.svg" />
                      <input
                        className="upload-input"
                        name="headerImage"
                        type="file"
                        onChange={(e) => {
                          latestImageClick = 2;
                          setSelectedImageUpload(e.target.files![0]);
                        }}
                      />
                    </div>
                  )}

                  {showHeaderImage && selectedQuestion?.headerImageUrl &&(
                    <div className={"img-container" + (selectedImageUploadIndex === 2 ? ' selected' : '')} onClick={() => setSelectedImageUploadIndex(2)}>
                      <img className="uploaded-file" src={selectedQuestion?.headerImageUrl} alt="uploaded file" />
                      <input
                        className="upload-input secondary"
                        name="headerImage"
                        type="file"
                        onChange={(e) => {
                          latestImageClick = 2;
                          setSelectedImageUpload(e.target.files![0]);
                        }}
                      />
                    </div>
                  )}

                  <Form.Check type="switch" id="custom-switch" label="Header image" checked={showHeaderImage} onChange={() => updateHeaderImage()} />
                </div>
              </div>

              <div className="right-form">
                <div className="order-select-box">
                  <span>Question order</span>
                  <select disabled={!!examResults?.length} className="form-select form-select-lg form-select-solid" data-control="select3" value={exam?.questions?.findIndex((q) => q.id === selectedQuestion?.id)} onChange={updateQuestionOrder}>
                    {exam?.questions?.map((question: Question, i: number) => (
                      <option key={'numb' + i} value={i}>
                        {i + 1}
                      </option>
                    ))}
                  </select>

                  <div className="form-check form-check-custom form-check-solid ml-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      disabled={!!examResults?.length}
                      id="inlineCheckbox1"
                      checked={!selectedQuestion?.isMath}
                      onChange={updateIsMath}
                    />
                    <label className="form-check-label fw-bold" htmlFor="inlineCheckbox1">
                      A-E
                    </label>

                    <input
                      className="form-check-input ms-5"
                      type="checkbox"
                      disabled={!!examResults?.length}
                      id="inlineCheckbox2"
                      checked={selectedQuestion?.isMath}
                      onChange={updateIsMath}
                    />
                    <label className="form-check-label fw-bold" htmlFor="inlineCheckbox2">
                      0-9
                    </label>
                  </div>
                </div>

                <span className="opts">Options</span>
                <div ref={optionsContainerRef} className={"options-container" + (selectedQuestion?.isMath ? ' tiffin' : '')}>
                  {selectedQuestion?.isMath ?
                  <>
                    {selectedQuestion?.options?.map((selectedQuestionOption: any, i: number) => (
                      <div className="option-box" key={'options' + i}>
                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={isChecked(i)} onChange={() => updateCorrectOption(i)} /> {/* checked={!isPaid} onChange={() => {}} */}
                        <div className="inner-box">
                          <div className="letter-div">{i% 10}</div>
                        </div>
                      </div>
                    ))}
                    <button type="button" style={{marginLeft: '10px', marginBottom: '10px'}} className="btn btn-primary fw-bolder add-opt-btn" onClick={addOptionMath}>
                      <span className="indicator-label">+</span>
                    </button>
                    <div className="trash-box" style={{marginLeft: '10px'}} onClick={() => deleteOptionMath()}>
                      <img src="/icons/trash.png" className="trash" />
                    </div>
                  </>
                  :
                  <>
                    {selectedQuestion?.options?.map((selectedQuestionOption: any, i: number) => (
                    <div className="option-box" key={'options' + i}>
                      <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={isChecked(i)} onChange={() => updateCorrectOption(i)} /> {/* checked={!isPaid} onChange={() => {}} */}
                      <div className="inner-box">
                        <div className="letter-div">{Utils.getLetter(i)}</div>
                        <input value={selectedQuestionOption} onChange={(e) => updateOption(selectedQuestionOption, e.target.value, i)} className="form-control form-control-lg form-control-solid" type="string" autoComplete="off" />
                      </div>
                      <div className="trash-box" onClick={() => deleteOption(i)}>
                        <img src="/icons/trash.png" className="trash" />
                      </div>
                    </div>
                  ))}
                  </>}
                </div>

                {selectedQuestion?.options && selectedQuestion?.options?.length < 20 && (
                  <button disabled={!!examResults?.length} type="button" className="btn btn-primary fw-bolder add-opt-btn" onClick={addOption}>
                    <span className="indicator-label">+</span>
                  </button>
                )}

                <div className="combo-boxes">
                  <select disabled={!!examResults?.length} className="form-select form-select-lg form-select-solid" data-control="select2" data-placeholder="Select Type..." ref={subjectRef} value={selectedQuestion?.subject || ''} onChange={updateQuestionSubject}>
                    <option key="empty-subject" value={undefined}>
                      {selectSubjectPlaceholder}
                    </option>
                    {subjects?.map((subject: Subject, i: number) => (
                      <option key={'subj' + subject.id} value={subject.id}>
                        {subject.name}
                      </option>
                    ))}
                  </select>

                  <select disabled={!!examResults?.length} className="form-select form-select-lg form-select-solid" data-control="select3" data-placeholder="Select Type..." ref={subSubjectRef} value={selectedQuestion?.subSubject || ''} onChange={updateQuestionSubSubject}>
                    <option key="empty-sub-subject" value={undefined}>
                      {selectSubSubjectPlaceholder}
                    </option>
                    {selectedSubjectSubs?.map((subSubject: SubSubject, i: number) => (
                      <option key={'subsubj' + subSubject.id} value={subSubject.id}>
                        {subSubject.name}
                      </option>
                    ))}
                  </select>

                  <input className='form-control form-control-lg form-control-solid' value={selectedQuestion?.videoUrl} onChange={(e) => setSelectedQuestion(({...selectedQuestion!, videoUrl: e.target.value}))} placeholder='Resolution video url...'></input>
                </div>

                <div className="footer">
                  <Button style={{ opacity: '0' }} variant="light" className="back-btn btn-short">
                    <ArrowLeftShort className="arrow-left" />
                    <span>Back</span>
                  </Button>

                  <div className="right-btns">
                    {exam?.questions?.length && exam?.questions?.length > 1 && (
                      <button type="button" className="btn btn-danger btn-short" onClick={(e) => examResults?.length ? confirmModalDeleteRef.current.handleShow(() => deleteQuestion()) : deleteQuestion()} disabled={saveLoading || deleteLoading}>
                        {!deleteLoading && <span className="indicator-label">Delete</span>}
                        {deleteLoading && (
                          <span className="indicator-progress" style={{ display: 'block' }}>
                            Deleting... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    )}

                    <button ref={saveBtnRef} type="button" 
                       onClick={(e) => {
                        if (examResults?.length) {
                          confirmModalSaveRef.current.handleShow(() => handleSubmit(e));
                        } else {
                          handleSubmit(e);
                        }
                      }}
                      className="btn btn-primary btn-short save-btn" disabled={saveLoading || deleteLoading}>
                      {!saveLoading && <span className="indicator-label">Save</span>}
                      {!!examResults?.length && <FontAwesomeIcon className="fa-exclamation" icon={faExclamation} />}
                      {saveLoading && (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                          Saving... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>

                    <button ref={saveBtnRef} type="button" onClick={(e) => examResults?.length ? confirmModalSaveAndContinueRef.current.handleShow(() => handleSubmit(e, true)):  handleSubmit(e, true)} className="btn btn-primary btn-short save-btn cont" disabled={saveLoading || deleteLoading || !!examResults?.length}>
                      {!saveLoading && <span className="indicator-label">Save & continue</span>}
                      {!!examResults?.length && <FontAwesomeIcon className="fa-exclamation continue" icon={faExclamation} />}
                      {saveLoading && (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                          Saving... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>

                    {!!examResults?.length && <button onClick={saveSpecial} className="btn btn-primary btn-short save-btn" type='button'>Spec. Save</button>}
                  </div>
                </div>
              </div>
            </div>

            <div>V1.1</div>
          </form>

          <ConfirmModalExam ref={confirmModalSaveRef} title="Exam Edit"confirmBtn="Confirm" description={modalDesc}/>

          <ConfirmModalExam ref={confirmModalNewQuestionRef} title="Exam Edit" confirmBtn="Confirm" description={modalDesc}/>

          <ConfirmModalExam ref={confirmModalSaveAndContinueRef} title="Exam Edit" confirmBtn="Confirm" description={modalDesc}/>

          <ConfirmModalExam ref={confirmModalDeleteRef} title="Exam Edit" confirmBtn="Confirm" description={modalDesc}/>
          <button id="test-paste" onClick={mockPasteImage}></button>
        </div>
      </section>
    </ProtectedPages>
  );
};

export default ExamCreationStep2;
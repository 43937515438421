import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { User } from '../../models/user';
import { useUserState } from '../../services/User';
import './AnswersCardHeader.scss';

export function AnswersCardHeader() {
  const user: User = useUserState();
  const [dateOfBirth, setDateOfBirth] = useState<any>();
  const [dateOfTest, setDateOfTest] = useState<any>();

  useEffect(() => {
    if (user?.birthDate){ 
      setDateOfTest(format(new Date(),'ddMMyy').toString());
      setDateOfBirth(format(new Date(user.birthDate),'ddMMyy').toString());
    } else {
      setDateOfTest(format(new Date(),'ddMMyy').toString());
      setDateOfBirth('111111');
    }
  }, [user]);

  return (
    <section id="AnswersCardHeader">
      <div className="exam-header">
        <div className="first-header-section">
          <div className="container-date">
            <div className="date">DATE OF TEST</div>
            <div className="date-text-header">
              <span className="date-text">DAY</span>
              <span className="date-text">MONTH</span>
              <span className="date-text">YEAR</span>
            </div>

            <div className="boxs-date">
              {dateOfTest && Array.from(dateOfTest)?.map((char: any, i: number) => 
                <div key={i} className="box-date">{char}</div>
              )}
            </div>
          </div>

          <div className="container-date">
            <div className="date">DATE OF BIRTH</div>
            <div className="date-text-header">
              <span className="date-text">DAY</span>
              <span className="date-text">MONTH</span>
              <span className="date-text">YEAR</span>
            </div>

            <div className="boxs-date">
              {dateOfBirth && Array.from(dateOfBirth)?.map((char: any, i: number) => 
                <div key={i} className="box-date">{char}</div>
              )}
            </div>
          </div>

          <div className="data-container">
            <span className="data-line">
              Current School:
              <div className="student-name">Mock Exam Centre</div>
            </span>
            <span className="data-line">Venue:</span>
            <span className="data-line">Session:</span>
          </div>
        </div>

        <div className="second-header-section">
          <div className="name-box">
            <span>Please write your name here</span>
            <span>Don't write outside the box.</span>
          </div>

          <div className="instruction-box">
            <span>Instructions:</span>

            <span>1. Verify that you are marking the answer for the correct question number. </span>

            <span>2. Mark one or more answers for each question on this answer sheet.</span>
          </div>
        </div>
      </div>
    </section>
  );
}

import { differenceInSeconds, format, subSeconds } from 'date-fns';
import { collection, doc, getDoc, orderBy, query, setDoc, where } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { PauseBtn, PauseBtnFill, PauseFill, StarFill } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { GlaCard } from '../../components/answers-card/gla-card/GlaCard';
import { Type3AnswersCard } from '../../components/answers-card/sutton/SuttonCard';
import { StopWatch } from '../../components/stop-watch/StopWatch';
import { ProtectedPages } from '../../layouts/admin/ProtectedLayout';
import { Exam } from '../../models/exam';
import { Question } from '../../models/question';
import { ExamResult } from '../../models/exam-result';
import { db } from '../../services/firebase';
import './ExamAnswers.scss';
import { ConfirmModal } from '../../components/confirm-modal/ConfirmModal';
import { Subject } from '../../models/subject';
import { useStaticState } from '../../services/StaticContext';
import { QueryUtils } from '../../util/query-utils';
import { enGB, enUS } from 'date-fns/locale';
import { addMinutes } from 'date-fns/esm';
import { Utils } from '../../util/string-utils';
import { Modal } from 'react-bootstrap';
import { useUserState } from '../../services/User';

const ExamAnswers = () => {
  const user = useUserState();

  const [showZoomModal, setShowAuthModal] = useState(false);
  const handleClose = () => setShowAuthModal(false);
  const handleShow = () => setShowAuthModal(true);

  let { examId }: any = useParams();
  let { resultId }: any = useParams();
  const [examSubjects, setExamSubjects] = useState<Subject[]>();
  const { examTypes, subjects, subSubjects } = useStaticState();

  const [exam, setExam] = useState<Exam>();
  const [selectedExamResult, setSelectedExamResult] = useState<ExamResult>();
  const [examResultList, setExamResultList] = useState<ExamResult[]>();
  const [selectedImage, setSelectedImage] = useState<any>();

  useEffect(() => {
    if (examTypes?.length && subjects?.length) {
      fetchApis();
    }
  }, [examTypes?.length, subjects?.length]);

  async function fetchApis() {
    const examVar = await fetchExamApi();
    if (examVar) {
      fetchResultApi(examVar);
      buildExamSubjects(examVar);
    }
  }

  async function fetchExamApi() {
    const docRef = doc(db, 'exam', examId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const examVar = { ...(docSnap.data() as Exam), id: docSnap.id };
      setExam({ ...examVar });
      return examVar;
      //console.log(examVar);
    } else {
      console.log('No such document!');
    }
  }

  async function fetchResultApi(examVar: Exam) {
    const customQuery = query(collection(db, 'results'), where('examId', '==', examId), where('userId', '==', user.id), orderBy("created_date", "asc"));
    const results = (await QueryUtils.executeQuery(customQuery) as ExamResult[]).filter(res => !Utils.isExamOnGoing(examVar, res));

    const customQuery2 = query(collection(db, 'additional-results'), where('examId', '==', examId), where('userId', '==', user.id), orderBy("created_date", "asc"));
    const results2 = (await QueryUtils.executeQuery(customQuery2) as ExamResult[]).filter(res => !Utils.isExamOnGoing(examVar, res));

    const examResults = results2.concat(results).sort((a,b) => a.created_date > b.created_date ? 1 : -1);

    examResults.forEach(r => {
      if (!r.submitted_date && examVar?.timeMinutes) {
        r.submitted_date = addMinutes(r.created_date.toDate(), examVar.timeMinutes);
      }
    });

    setExamResultList(examResults);

    setSelectedExamResult(examResults[examResults.length -1]);
  }

  function buildExamSubjects(examVar: Exam) {
    const examSubjects: Subject[] = [];
    examVar?.questions.forEach(question => {
      if (!examSubjects.some(s => s.id === question.subject)) {
        const found = subjects.find(s => s.id === question.subject);
        if (found) {
          examSubjects.push(found);
        }
      }
    });

    setExamSubjects(examSubjects)
  }

  function fetchSelectedImage(question: Question) {
    setSelectedImage(question.questionImageUrl);
    handleShow();
  }

  function answeredCorrectly(question:Question) {
    let isCorrect;
    const found = selectedExamResult?.answers?.find(answer => answer.questionId === question.id);
    if (found && found.markedOptions?.length && found.markedOptions?.length === question.correctOptions?.length) {
      if (question.correctOptions?.length) {
        let anyWrong = true;
        question.correctOptions.forEach(correctOption => {
          const right = found.markedOptions.some(m => m === correctOption);
          if (!right) anyWrong = false;
        });

        if (anyWrong) isCorrect = true;
      }
    }

    return isCorrect;
  }

  function getResultForQuestion(question: Question) {
    const foundAnswer = selectedExamResult?.answers?.find(answer => answer.questionId === question.id);
    return foundAnswer;
  }

  function getLetter(index: number) {
    const letterIndex = index;
    const letter = String.fromCharCode(letterIndex + 'A'.charCodeAt(0));
    return letter;
  }

  function setSelectedDate(e: any) {
    setSelectedExamResult(examResultList?.find(r => r.id === e.target.value));
  }

  function getDateFormat(examRes: ExamResult) {
    //console.log(examRes.created_date.toDate().toString());
    if (examRes.submitted_date){
    var result = format(
      new Date(examRes.submitted_date instanceof Date ? examRes.submitted_date : examRes.submitted_date.toDate()),
      'd MMMM yyyy HH:mm:ss'
      //enGB!.formatLong!.date({width:"long"})
    );

    return result;
    }
    //return examRes.created_date.id;
  }

  return (
    <ProtectedPages>
      {exam && selectedExamResult && (
        <section id="exam-answers">
          <main className="exam-container">
            <select
              className="form-select form-select-lg form-select-solid"
              data-control="select2"
              data-placeholder="Select Type..."
              value={selectedExamResult?.id}
              onChange={setSelectedDate}
            >
              {examResultList?.map((examRes, i: number) => (
                <option key={examRes.id} value={examRes.id} className={i === 0 ? "official": ''}>
                  {getDateFormat(examRes)}
                </option>
              ))}
            </select>
            <div className="exam-questions">
              {examSubjects?.map((subject, i) => (
                <div className="subject-container-parent" key={subject.id}>
                  <div className="header-box">
                    <span className='header-span'>{subject.name}</span>
                  </div>
                  {exam?.questions?.filter(q => q.subject === subject.id)?.map((question: Question, i: number) => (
                    <div key={'quest' + i} className="question-box">
                      <div className="question-header">
                        <span>Question: {i + 1}</span>
                      </div>

                      <div className="question-image">
                        <img src={question.questionImageUrl} alt="" />
                        <div className="magnifying-glass-icon">
                          <img onClick={() => fetchSelectedImage(question)} src="/icons/magnifying-glass-icon.png" alt="magnifying-glass-icon" />
                        </div>
                      </div>

                      <div className='answer-box'>
                        <div>
                          <span className='answer-title'>Your answer{getResultForQuestion(question) && getResultForQuestion(question)!.markedOptions?.length > 1 ? 's' : ''}</span>
                          {getResultForQuestion(question)?.markedOptions?.map((option: number, i: number) => (
                            <div className='option-row' key={'o' + option}>
                              <img src={answeredCorrectly(question) ? '/icons/answer-marked-green.svg' :'/icons/answer-marked-red.svg'} />
                              <div>{question!.options && question!.options[i] && question!.options[i] !== '' ? question?.options[option] : getLetter(option)}</div>
                            </div>
                          ))}
                        </div>
                        <div>
                          <span className='answer-title'>Correct answer{question?.correctOptions && question?.correctOptions?.length > 1 ? 's': ''}</span>
                          {question?.correctOptions?.map((option: number, i: number) => (
                            <div className='option-row' key={'o' + option}>
                              <img src='/icons/answer-marked-green.svg' />
                              <div>{question!.options && question!.options[i] && question!.options[i] !== '' ? question?.options[i] : getLetter(option)}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </main>

        {selectedImage && <Modal show={showZoomModal} onHide={handleClose} centered dialogClassName={"zoom-modal"}>
          <Modal.Body>
             <div>
              <img src={selectedImage} />
             </div>
          </Modal.Body>
        </Modal>}
        </section>
      )}
    </ProtectedPages>
  );
};

export default ExamAnswers;

//import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import { useAuthState } from './firebase';
import { useEffect } from 'react';


const PublicRoutes = (props: any) => {
  const { user, isAuthenticated, isLoading } = useAuthState();

  if (isAuthenticated && !isLoading) {
    window.localStorage.setItem('currentNav', 'home');
  }

	return isAuthenticated && !isLoading
    ? <Navigate to="/exams-list"/>
    : <Outlet/>
  }


export default PublicRoutes;
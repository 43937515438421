import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import useWindowDimensions from '../../services/window-dimensions';
import { PaymentPlans } from '../payment-plans/PaymentPlans';
import { SwiperPayment } from '../swiper-payment/SwiperPayment';
import './modal-payment.scss';

type Props = {
  title: string;
  description: any;
  onCancel?: any;
  onConfirm: any;
};

export const ModalPayment = forwardRef((props: Props, ref) => {
  const [selectedPeriod, setSelectedPeriod] = useState('monthly');
  useImperativeHandle(ref, () => ({
    handleShow,
    handleHide,
  }));

  const [showModal, setShowModal] = useState(false);
  const { height, width, isMobile, isTablet, isMobileOnly, isTabletPortrait, isDesktop } = useWindowDimensions();

  const handleHide = () => {
    setShowModal(false);
  };

  const handleShow = (data: any) => {
    setShowModal(true);
  };

  return (
    <section id="exam-results-modal">
      <Modal show={showModal} onHide={handleHide} centered dialogClassName="modal-payment">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="body-text flex flex-col items-center">
            <span>{props.description}</span>
            <ButtonGroup style={{width: '280px', marginTop: '10px'}} className=''>
              <Button onClick={() => setSelectedPeriod('monthly')} active variant={selectedPeriod === 'monthly' ? 'primary' : 'secondary'}>Monthly</Button>
              <Button onClick={() => setSelectedPeriod('annual')} variant={selectedPeriod === 'annual' ? 'primary' : 'secondary'} >Annual  (10% Discount)</Button>
            </ButtonGroup>
          </div>
          {isDesktop ? (
            <PaymentPlans monthly={selectedPeriod === 'monthly'} onChoosePlan={props.onConfirm} />
          ) : (
            <div className="" style={{height: '200px'}}>
              <SwiperPayment monthly={selectedPeriod === 'monthly'} onChoosePlan={props.onConfirm} />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </section>
  );
});

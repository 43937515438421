import { useEffect, useRef, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, updateEmail, UserCredential } from 'firebase/auth';
import Button from 'react-bootstrap/Button';
import './profile.scss';
import * as Yup from 'yup';
//import { SocialLogin } from '../../components/signup/SocialLogin';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { ToastMessage } from '../../components/toast/ToastMessage';
import { useUserState } from '../../services/User';
import { ProtectedPages } from '../../layouts/admin/ProtectedLayout';
import { ParentalPassword } from '../../components/parental-password/ParentalPassword';
import { Modal } from 'react-bootstrap';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { addYears, isWithinInterval } from 'date-fns';
import useWindowDimensions from '../../services/window-dimensions';
import { PaymentPlans } from '../../components/payment-plans/PaymentPlans';
import { SwiperPayment } from '../../components/swiper-payment/SwiperPayment';
import { useNavigate } from 'react-router-dom';
import { ModalPayment } from '../../components/modal-payment/ModalPayment';
import { Utils } from '../../util/string-utils';
import { Plan } from '../../models/plan';
import { ConfirmModal } from '../../components/confirm-modal/ConfirmModal';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  parentName: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Parent name is required'),
  firstName: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('First name is required'),
  lastName: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Last name is required'),
  //password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
});

const Profile = () => {
  const toastRef: any = useRef(null);
  const user: any = useUserState();
  const parentalPasswordRef: any = useRef(null);
  const modalPaymentRef: any = useRef(null);
  const confirmModalRef: any = useRef(null);
  const [parentalPassword, setParentalPassword] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [loadingPasswordReset, setLoadingPasswordReset] = useState(false);
  const [loadingParentalPassword, setLoadingParentalPassword] = useState(false);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string>('');
  const navigate = useNavigate();
  const [userPlans, setUserPlans] = useState<any>([]);
  const { height, width, isMobile, isTablet, isMobileOnly, isTabletPortrait, isDesktop } = useWindowDimensions();
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [loading, setLoading] = useState(false);
  const [birthDate, setBirthDate] = useState<any>();
  const [birthDateChanged, setBirthDateChanged] = useState<any>();

  const initialValues = {
    email: (user.email as string) || '',
    firstName: (user.firstName as string) || '',
    lastName: (user.lastName as string) || '',
    parentName: (user.parentName as string) || '',
    login: (user.login as string) || '',
    //birthDate: user.birthDate ? new Date() : '',
    //phone: (user.phone as string) || '',
  };

  function dateToString(date: any) {
    if (!date) return '';
    const isoDate = new Date(date).toISOString();
    return `${isoDate.substring(0, 10)}`;
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setShowModal(false);
      updIdentifier(values);
    },
  });

  useEffect(() => {
    setBirthDate(user?.birthDate);
  }, [user?.birthDate]);

  useEffect(() => {
    console.log(user);
    if (user?.id) {
      queryStripeApi(user);
    }
  }, [user?.id]);

  async function queryStripeApi(user: any) {
    if (!user?.checkoutSessions) {
      setLoadingPlans(false);
      return;
    }
    const headers = {
      Authorization: 'Bearer ' + process.env.REACT_APP_PUBLIC_STRIPE_API_KEY,
    };

    try {
      getPlans(user?.checkoutSessions);
    } catch (e) {
      console.log(e);
      setLoadingPlans(false);
    }
  }

  async function getPlans(subscriptions: string[]) {
    const headers = {
      Authorization: 'Bearer ' + process.env.REACT_APP_PUBLIC_STRIPE_API_KEY,
    };

    const userPlans = [];

    // Iterate over each plan in the array
    for (const subId of subscriptions) {
      try {
        const response = await axios.post(
          'https://us-central1-mock-exam-centre-prod.cloudfunctions.net/productInfo',
          //'http://127.0.0.1:5001/mock-exam-centre-prod/us-central1/productInfo',
          {
            subscriptionId: subId,
          },
          { headers }
        );

        // Handle the response from the cloud function
        console.log(`Plan ID: ${subId} - Info:`, response.data);
        const data = response.data;
        data.subId = subId;
        userPlans.push(response.data);
      } catch (error) {
        console.error(`Error processing plan ${subId}:`, error);
        // Handle any error that occurred during the HTTP request
      }
    }

    setLoadingPlans(false);
    setUserPlans(userPlans);
  }

  useEffect(() => {
    if (formik.values?.firstName !== user.firstName || formik.values?.lastName !== user.lastName) {
      formik.setFieldValue('login', formik.values?.firstName + formik.values?.lastName.substring(0, 1));
    }
  }, [formik.values?.firstName, formik.values?.lastName]);

  function convertToDate(dateNumber: any) {
    const createdAt = new Date(dateNumber * 1000).toDateString();
    return createdAt;
  }

  function convertToDateYear(dateNumber: any) {
    const createdAt = new Date(dateNumber * 1000);
    const oneYearFromPurchase = addYears(createdAt, 1);

    return oneYearFromPurchase.toDateString();
  }

  async function updIdentifier(data: any, attempt = 0) {
    const { firstName, lastName, email } = data;
    data.login = firstName + lastName.substring(0, 1) + (attempt || '');

    try {
      if (getAuth().currentUser && data.login !== user.login) {
        await reauthAndSave();
      } else {
        updateUser(formik.values);
      }
    } catch (e: any) {
      if (e.message === 'Firebase: Error (auth/requires-recent-login).') {
        parentalPasswordRef.current.handleShow();
      }

      if (e.message === 'Firebase: Error (auth/email-already-in-use).') {
        updIdentifier(data, ++attempt);
      } else {
        console.log(e);
        setLoading(false);
      }
    }
  }

  async function reauthAndSave() {
    const auth: any = getAuth();

    try {
      await updateEmail(auth.currentUser, formik.values.login + '@mockexamcentre.com');
      updateUser(formik.values);
    } catch (e) {
      throw e;
    }
  }

  async function updateUser(data: any) {
    await updateDoc(doc(db, 'user', user.id), { ...data, birthDate: new Date(birthDate).toISOString() });

    setLoading(false);
    toastRef.current.toggleShow('Changes saved successfully!');
  }

  function inputBuilder(fieldName: string, placeholder: string, disabled = false) {
    const touched: any = formik.touched;
    const touchedField: any = touched[fieldName];
    const errors: any = formik.errors;
    const errorsField = errors[fieldName];

    return (
      <>
        <input
          placeholder={placeholder}
          {...formik.getFieldProps(fieldName)}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': touchedField && errorsField },
            {
              'is-valid': touchedField && !errorsField,
            }
          )}
          disabled={disabled}
          type={fieldName}
          name={fieldName}
          autoComplete="off"
        />
        {touchedField && errorsField && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{errorsField}</div>
          </div>
        )}
      </>
    );
  }

  function setUserBirthDate(e: any) {
    if (e.target.value) {
      setBirthDate(e.target.value);
      setBirthDateChanged(true);
    }
  }

  function checkParentalPass() {
    var key = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
    var hash = CryptoJS.AES.encrypt(parentalPassword, key, { mode: CryptoJS.mode.ECB }).toString();

    if (user.parentalPassword === hash) {
      formik.handleSubmit();
    } else {
      toastRef.current.toggleShow('Incorrect password.');
    }
  }

  async function sendEmailResetPassword() {
    if (loadingPasswordReset) return;
    setLoadingPasswordReset(true);
    await axios.post('https://us-central1-mock-exam-centre-prod.cloudfunctions.net/sendPasswordResetEmail', {
      userId: user.id,
      isParental: false,
    });
    toastRef.current.toggleShow('Email sent successfully!');
    setLoadingPasswordReset(false);
  }

  async function sendEmailResetParentalPass() {
    if (loadingParentalPassword) return;
    setLoadingParentalPassword(true);
    await axios.post('https://us-central1-mock-exam-centre-prod.cloudfunctions.net/sendPasswordResetEmail', {
      userId: user.id,
      isParental: true,
    });
    toastRef.current.toggleShow('Email sent successfully!');
    setLoadingParentalPassword(false);
  }

  function onSelectPlan(examTypeId: string) {
    modalPaymentRef.current.handleHide();
    Utils.handleCheckoutSimple(examTypeId, user);
  }

  async function handleCancelSubscription(subId: string) {
    setSelectedSubscriptionId(subId);
    confirmModalRef.current?.handleShow();
  }

  async function doConfirmCancel() {
    const headers = {
      Authorization: 'Bearer ' + process.env.REACT_APP_PUBLIC_STRIPE_API_KEY,
    };

    const responseCancel = await axios.post(
      'https://us-central1-mock-exam-centre-prod.cloudfunctions.net/cancelSubscription',
      //'http://127.0.0.1:5001/mock-exam-centre-prod/us-central1/productInfo',
      {
        subscriptionId: selectedSubscriptionId,
      },
      { headers }
    );

    const response = await axios.post(
      'https://us-central1-mock-exam-centre-prod.cloudfunctions.net/productInfo',
      //'http://127.0.0.1:5001/mock-exam-centre-prod/us-central1/productInfo',
      {
        subscriptionId: selectedSubscriptionId,
      },
      { headers }
    );

    toastRef.current.toggleShow('Subscription cancelled successfully!');
    confirmModalRef.current?.handleCancel();
  }

  return (
    <ProtectedPages>
      <div id="profile">
        <section className="main-content">
          <div className="box-password">
            <form className="form w-100">
              {/*   <h3 className="text-profile">Profile</h3> */}
              <div className="profile-data-container">
                <div>
                  <label className="form-label fs-6 fw-bolder text-dark">Guardian or Parent Full Name</label>
                  {inputBuilder('parentName', 'Parent Full Name')}
                </div>

                <div>
                  <label className="form-label fs-6 fw-bolder text-dark">Username</label>
                  {inputBuilder('login', 'Username', true)}
                </div>

                <div>
                  <label className="form-label fs-6 fw-bolder text-dark">Email of parent or relative</label>
                  {inputBuilder('email', 'Email')}
                </div>

                <div>
                  <label className="form-label fs-6 fw-bolder text-dark">Pupil's First Name</label>
                  {inputBuilder('firstName', 'First Name', true)}
                </div>

                <div>
                  <label className="form-label fs-6 fw-bolder text-dark">Pupil's Last Name</label>
                  {inputBuilder('lastName', 'Last Name', true)}
                </div>

                <div>
                  <label className="form-label fs-6 fw-bolder text-dark">Pupil's Date of Birth</label>
                  <input
                    type="date"
                    className="form-control form-control-lg form-control-solid"
                    value={dateToString(birthDate)}
                    onChange={setUserBirthDate}
                  />
                </div>
              </div>
              {/* end::Form group */}

              {/* begin::Action */}
              <div className="update-profile-btn">
                <div className="update-btn">
                  <button
                    type="button"
                    id="kt_login_signin_form_submit_button"
                    className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
                    onClick={() => (user.role == 1 ? formik.handleSubmit() : toggleModal())}
                    disabled={(formik.isSubmitting || !formik.isValid || !formik.dirty) && !birthDateChanged}
                  >
                    {!loading && <span className="indicator-label">Update</span>}
                    {loading && (
                      <span className="indicator-progress" style={{ display: 'block' }}>
                        Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>

                <div className="reset-pwds-container">
                  <button onClick={sendEmailResetPassword} className="btn btn-primary fs-6 px-8 py-4 me-3 second-button reset-button">
                    {!loadingPasswordReset && <span className="indicator-label">Reset Account Password by Email</span>}
                    {loadingPasswordReset && (
                      <span className="indicator-progress" style={{ display: 'block' }}>
                        Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                  <button
                    className="btn btn-primary fs-6 px-8 py-4 me-3 second-button reset-button"
                    onClick={sendEmailResetParentalPass}
                  >
                    {!loadingParentalPassword && <span className="indicator-label">Reset Parental Password by Email</span>}
                    {loadingParentalPassword && (
                      <span className="indicator-progress" style={{ display: 'block' }}>
                        Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
              {/* end::Action */}
            </form>
          </div>

          {loadingPlans && (
            <div className="indicator-progress mt-14" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <span style={{ scale: '2' }} className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </div>
          )}

          {!loadingPlans && (
            <>
              {!!userPlans?.length && (
                <div className="subscription-text">
                  <span className="subscription">Subscriptions</span>
                </div>
              )}

              {!userPlans?.length && (
                <div className="no-plan-container" style={{ maxWidth: '1200px' }}>
                  {/* <img className="scale-90" src="/icons/empty.svg"></img> */}
                  <span className="text-lg font-medium">No active plans</span>
                  <span className="text-xs" style={{ color: '#6D7274' }}>
                    Click the button below to view and subscribe to our plans!
                  </span>
                </div>
              )}

              <div className="plan-container">
                {userPlans?.map((plan: any, i: number) => (
                  <div key={'profile-plan-' + i} className="plan-box">
                    <div className="complete-package">
                      <span>{plan?.name}</span>
                    </div>
                    <div>
                      <span>Plan</span>
                      <span>{plan?.name}</span>
                    </div>
                    <div>
                      <span>Price</span>
                      <span style={{ fontWeight: 'bolder' }}>£{plan?.price / 100}</span>
                    </div>
                    <div>
                      <span>Billing start</span>
                      <span>{convertToDate(plan?.current_period_start)}</span>
                    </div>
               {/*      <div>
                      <span>Renewal date</span>
                      <span>{convertToDateYear(plan?.current_period_end)}</span>
                    </div> */}
                    <div>
                      <span>Payment Status</span>
                      {!plan.refunded ? (
                        <span style={{ color: 'rgb(40 154 40)', fontWeight: 'bolder' }}>Paid</span>
                      ) : (
                        <span style={{ color: 'red', fontWeight: 'bolder' }}>Refunded</span>
                      )}
                    </div>
                    {plan.active && (
                      <div>
                        <span></span>
                        {plan?.cancel_at ? (
                          <span style={{ color: 'red', fontWeight: 'bolder' }}>Cancelled</span>
                        ) : (
                          <Button
                            size="sm"
                            className="btn-danger py-1 px-3 text-xs"
                            onClick={() => handleCancelSubscription(plan.subId)}
                          >
                            Cancel
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}

          {!loadingPlans && (
            <button
              type="button"
              style={{ maxWidth: '94%', width: '1200px' }}
              className="btn btn-primary fw-bolder"
              onClick={() => modalPaymentRef.current.handleShow()}
            >
              <span className="indicator-label">View Plans</span>
            </button>
          )}
        </section>
      </div>
      <ToastMessage ref={toastRef} />
      <ParentalPassword onSuccess={reauthAndSave} ref={parentalPasswordRef} />
      <ConfirmModal
        ref={confirmModalRef}
        onConfirm={doConfirmCancel}
        title="Subscription cancellation"
        confirmBtn="Confirm"
        description={`Are you sure you want to cancel your subscription?`}
      />

      <ModalPayment
        onConfirm={(linkUrl: string) => onSelectPlan(linkUrl)}
        description={<span>Subscribe to get access to all our premium exams now!</span>}
        ref={modalPaymentRef}
        title="payment"
      ></ModalPayment>

      <Modal show={showModal} onHide={toggleModal} dialogClassName="global-modal confirm-parental-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            placeholder="Parental password"
            value={parentalPassword}
            onChange={(e) => setParentalPassword(e.target.value)}
          ></input>

          <div className="custom-success-footer">
            <Button onClick={toggleModal} variant="light" className="back-btn btn-short">
              <span>Cancel</span>
            </Button>

            <Button id="button-start-modal" onClick={() => checkParentalPass()}>
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </ProtectedPages>
  );
};
export default Profile;

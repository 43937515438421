import { Exam } from '../../../models/exam';
import { Question } from '../../../models/question';
import { ExamResult } from '../../../models/exam-result';
import './TiffinCard.scss';
import { useEffect, useState } from 'react';
import { useStaticState } from '../../../services/StaticContext';
import { Subject } from '../../../models/subject';
import { AnswersCardHeader } from '../../answers-card-header/AnswersCardHeader';

type Props = {
  exam: Exam | undefined;
  result: ExamResult;
  onAnswer: any;
};

export const TiffinCard = (props: Props) => {
  const { examTypes, subjects, subSubjects } = useStaticState();
  const [examSubjects, setExamSubjects] = useState<Subject[]>();
  const mathArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    if (subjects?.length) {
      buildExamSubjects();
    }
  }, [subjects?.length]);

  function buildExamSubjects() {
    const examSubjects: Subject[] = [];
    props.exam?.questions.forEach((question) => {
      if (!examSubjects.some((s) => s.id === question.subject)) {
        const found = subjects.find((s) => s.id === question.subject);
        if (found) {
          examSubjects.push(found);
        }
      }
    });
    setExamSubjects(examSubjects);
  }

  function onAnswerSelect(question: Question, option: any, marked: boolean) {
    props.onAnswer(question, option, marked);
  }

  function isMarked(question: Question, option: any) {
    const found = props.result.answers?.find((ans) => ans.questionId === question.id);
    return found && found.markedOptions.find((m) => m === option) !== undefined;
  }

  function getLetter(index: number) {
    const letterIndex = index;
    const letter = String.fromCharCode(letterIndex + 'A'.charCodeAt(0));
    return letter;
  }

  function getWidth(size: number) {
    return 35 * Math.ceil(size / 10) + 'px';
  }

  return (
    <section id="exam-perform-tiffin">
      <AnswersCardHeader />

      <div className="answer-card">
        <div className="tiffin-answers-card">
          {examSubjects?.map((subject, a) => (
            <div className="subject-container" key={subject.id}>
              <span className="header-section">
                <strong>{subject.name}</strong>
              </span>
              <main className="content-answers">
                <div className="answers-container">
                  {props.exam?.questions
                    ?.filter((q) => q.subject === subject.id)
                    ?.map((question: any, i: number) => (
                      <div key={'tiffin-question-'+a+'-'+i} className={'answers-container' + (i % 2 === 0 ? ' color' : '')}>
                        <div className="answers-options" style={{ width: getWidth(question?.options?.length) }}>
                          <span className="question-number">{i + 1}</span>
                          <div className="math-container">
                            {question?.options?.map((option: any, j: number) => (
                              <div key={'option-tiffin' + i + j}>
                                <div>
                                  <div className={'inner-option' + (isMarked(question, j) ? ' selected' : '')} onClick={() => onAnswerSelect(question, j, true)}>
                                    <div>
                                      <img className="red-box-image" src="/media/images/red-box-image-left.png" alt="" />
                                      <span key={'testkey'+i+j}>{question.isMath ? j % 10 : getLetter(j)}</span>
                                      <img className="red-box-image" src="/media/images/red-box-image-right.png" alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          {/*   {question?.options?.map((option: any, i: number) => (
                    <div key={i}>
                      <div
                        className={'inner-option' +  (isMarked(question, i) ? ' selected' : '')}
                        onClick={() => onAnswerSelect(question, i, true)}
                      >
                        [ <span key={i}>{option ? option : getLetter(i)}</span> ]
                      </div>
                  </div>
                  ))} */}
                        </div>
                      </div>
                    ))}
                </div>
              </main>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

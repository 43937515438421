import { useState, useEffect, useContext, createContext, useRef, useMemo } from 'react'
import { ToastMessage } from '../components/toast/ToastMessage';
//import {Loader} from '@components';

const ToastContext = createContext(null);

export function ToastProvider({children}: any) {
  const ref: any = useRef(null);
  const showToastMessage = (msg: string) => ref.current.toggleShow(msg);
  //const stopLoader = () => ref.current.stop();
  const value: any = useMemo(
    () => ({ref, showToastMessage}),
    [ref, showToastMessage]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastMessage ref={ref} />
    </ToastContext.Provider>
  );
}

//export const useLoader = () => useContext(LoaderContext);
export const useToast = () => {
  const auth: any = useContext(ToastContext)
  return { ...auth, test: 'test' }
}
import { format } from 'date-fns';
import { collection, deleteDoc, doc, orderBy, query, where } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { db } from '../../services/firebase';
import { QueryUtils } from '../../util/query-utils';
import './CalendarWidget.scss';
import { ConfirmModal } from '../confirm-modal/ConfirmModal';
import { useToast } from '../../services/ToastContext';

type Props = {
  title: string;
  subtitle: string;
  text1: string;
  text2: string;
  text3?: string;
  hideImg?: boolean;
  isDate?: boolean;
};

export const CalendarWidget = (props: Props) => {
  const confirmModalRef: any = useRef(null);
  const { showToastMessage } = useToast();
  useEffect(() => {
    fetchApi();
    fetchReportsFromDb();
  }, []);

  const [calendarList, setCalendarList] = useState<any>();
  const [reportsList, setReportsList] = useState<any[]>([]);
  const [selectedReport, setSelectedReport] = useState<any>();

  async function fetchApi() {
    const customQuery = query(collection(db, 'exams-calendar'), orderBy('date', 'desc'));
    const results = await QueryUtils.executeQuery(customQuery);
    setCalendarList(results);
  }

  async function fetchReportsFromDb() {
    const customQuery = query(collection(db, 'reports'));
    const reports = await QueryUtils.executeQuery(customQuery);
    setReportsList(reports);
  }

  function convertDate(date: any) {
    return date.toDate().toDateString();
  }

  async function handleDeleteReport(report: any) {
    setSelectedReport(report);
    confirmModalRef.current?.handleShow();
  }

  async function handleConfirmDelete() {
    await deleteDoc(doc(db, "reports", selectedReport.id));
    setReportsList((prevReports) => prevReports.filter((report) => report.id !== selectedReport.id));
    showToastMessage('Report deleted successfully');
    confirmModalRef.current?.handleCancel();
  }

  return (
    <section id="calendar-widget">
      <div className="performance-box">
        {/* <div className="box-performance-data">
          <div className="title-performance">
            {!props.hideImg && <img src="/icons/calendar-box.svg" alt="calendar-icon" />}

            <div className="text-performace">
              <span className="first-line-performance">{props.title}</span>
              <span className="second-line-performance">{props.subtitle}</span>
            </div>
          </div>

          {calendarList?.map((item: any) => (
            <div key={item.id} className="date-panel">
              <span className="date-text">{format(item.date.toDate(), 'd/MM/yy HH:mm')}</span>
              <div className="images">
                <img src="icons/circle.svg" />
                <img src="icons/vertical-line.svg" />
              </div>
              <div className="content-div">
                <span className="exam-text">{item.name}</span>
                <a href={item.link} target="_blank">
                  {item.link}
                </a>
              </div>
            </div>
          ))}
        </div> */}
        <div className="reports">
          <div className="reports-title">
            <img src="/icons/report-icn.png" alt="report-icn.png" />
            <span className="first-line-ranking">Reports</span>
          </div>
          <div className="reports-list">
            <table>
              <thead>
                <tr className="tr">
                  <th>Exam Name</th>
                  <th>Report Date</th>
                  <th>Reports</th>
                  <th className="bin-h">Action</th>
                </tr>
              </thead>
              <tbody>
                {reportsList.sort((a, b) => (a.date > b.date ? -1 : 1))?.map((report: any, index: number) => (
                  <tr key={index} className="report-item">
                    <td>{report.examName}</td>
                    <td>{convertDate(report.date)}</td>
                    <td className="rpts">{report.report}</td>
                    <td>
                      <img onClick={() => handleDeleteReport(report)} className="bin" src="/icons/bin.png" alt="" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ConfirmModal ref={confirmModalRef} onConfirm={handleConfirmDelete} title="Report Deletion" confirmBtn="Delete" description={`Are you sure you want to delete report: "${selectedReport?.report}"`} />
    </section>
  );
};

import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import { useState, useEffect, useContext, createContext } from 'react'
import { getFirestore, initializeFirestore } from 'firebase/firestore';

export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
})

//csAQo2Gv54Qo

export const AuthContext = createContext(null)

export const AuthContextProvider = (props: any) => {
  const [user, setUser] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser as any, setError as any)
    getAuth().onAuthStateChanged(authUser => {
      setLoading(false);
    })
    return () => unsubscribe()
  }, []);

  return <AuthContext.Provider value={{ user, loading, error }} {...props} />
}

export const useAuthState = () => {
  const auth: any = useContext(AuthContext)
  return { ...auth, isLoading: auth.loading, isAuthenticated: auth.user != null }
}

const db = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true
  })
  
export { db } ;
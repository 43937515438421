import { ExamResult } from "../models/exam-result";
import { Question } from "../models/question";

export abstract class ArrayUtils {
  public static getNameById(list: any[], id: string): string {
    return list?.find(item => id === item.id)?.name;
  }

  public static updateObjectInArray(array: any, obj: any) {
    return array?.map((item: any) => (item.id !== obj.id ? item : obj));
  }

  public static answeredCorrectly(question: Question, result: ExamResult) {
    let isCorrect;
    const found = result?.answers?.find((answer) => answer.questionId === question.id);
    if (found && found.markedOptions?.length && found.markedOptions?.length === question.correctOptions?.length) {
      if (question.correctOptions?.length) {
        let anyWrong = true;
        question.correctOptions.forEach((correctOption) => {
          const right = found.markedOptions.some((m) => m === correctOption);
          if (!right) anyWrong = false;
        });

        if (anyWrong) isCorrect = true;
      }
    }

    return isCorrect;
  }
}
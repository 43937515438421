import { useEffect, useRef, useState } from 'react';
import { ModalPayment } from '../../components/modal-payment/ModalPayment';
import { ProtectedPages } from '../../layouts/admin/ProtectedLayout';
import './help.scss';
import { useUserState } from '../../services/User';
import { Utils } from '../../util/string-utils';
import { useLoader } from '../../services/LoaderProvider';
import axios from 'axios';
import { addYears, isWithinInterval } from 'date-fns';

const Help = () => {
  const modalPaymentRef: any = useRef(null);
  const user: any = useUserState();
  const { startLoader, stopLoader } = useLoader();
  const [hasPlans, setHasPlans] = useState<boolean>(false);
  function showPaymentDialog(exam: any) {
    //if (exam?.isPaid && !props.unlocked && user.role != 1) {
      modalPaymentRef.current.handleShow();
    //}
  }

  function onSelectPlan(linkUrl: string) {
    startLoader();
    Utils.handleCheckoutSimple(linkUrl, user)
  }

  useEffect(() => {
    if (user?.id) {
      queryStripeApi(user)
    }
  }, [user?.id]);

  async function queryStripeApi(user: any) {
    if (!user?.checkoutSessions) {
      return;
    }
    const headers = {
      Authorization: 'Bearer ' + process.env.REACT_APP_PUBLIC_STRIPE_API_KEY,
    };

    try {
      const respLineItems: any = await axios.post(process.env.REACT_APP_PUBLIC_PAYMENTS_FUNCTION_URL!, { payments: user?.checkoutSessions, isProd: process.env.REACT_APP_PUBLIC_PROD === 'true'});
      const respCheckouts = await Promise.all(user?.checkoutSessions?.map((sessionId: any) => axios({ method: 'GET', url: `https://api.stripe.com/v1/checkout/sessions/${sessionId}`, headers })))
      const hasAnyPlans = false;
      respLineItems?.data?.forEach((d: any, i: number) => {
        const created = respCheckouts[i]?.data?.created;
        const createdAt = new Date(created * 1000);
        const oneYearFromPurchase = addYears(createdAt, 1);

        const isWithinOneYear = isWithinInterval(new Date(), {
          start: createdAt,
          end: oneYearFromPurchase,
        });
        if (isWithinOneYear) {
          setHasPlans(true);
        }
      });
    }
    catch(e) {
      console.error(e)
    }
  }

  return (
    <ProtectedPages>
      <section id="help">
        <header>
          See how simple it is!
         {/*  <img src="media/images/play.png" alt="" />{' '} */}
        </header>

        <div className="heading">
          <h1>Step 1</h1>
          <h2>Let's try it!</h2>
          <img src="/help-page/step.1.png"></img>
        </div>

        <div className="heading">
          <h1>Step 2</h1>
          <h2>3,2,1, press Start!</h2>
          <img src="/help-page/step-2.png"></img>
        </div>

        <div className="heading">
          <h1>Step 3</h1>
          <h2>Select the correct answer</h2>
          <img src="/help-page/step-3.png"></img>
        </div>

        <div className="heading">
          <h1>Step 4</h1>
          <h2>Once you're done, submit!</h2>
          <img src="/help-page/step-4.png"></img>
        </div>

        <div className="heading">
          <h1>Step 5</h1>
          <h2>Great! Now let's see how you performed</h2>
          <div className="step5-img">
       {/*      <img className="img1" src="/help-page/step5-1.png"></img>
            <img className="img2" src="/help-page/step5-2.png"></img> */}
            <img src="/help-page/11.png"></img>
          </div>
        </div>

        <div className="heading">
          <h1>Step 6</h1>
          <h2>FIND OUT THE SOLUTIONS AND CORRECT ANSWERS</h2>
          <img src="/help-page/step-6.png"></img>
        </div>

        <div className="heading">
          <h1>Step 7</h1>
          <h2>Parent? Find your Subscriptions at the Profile</h2>
          <div className="step-7">
            <img className="step7-img" src="/help-page/step7-1.png"></img>
            <img className="step7-img2" src="/help-page/step7-2.png"></img>
          </div>
        </div>
      </section>

      {!hasPlans && <div className="subscribe" onClick={showPaymentDialog}>
        <span className="subscribe-tex">Get instant access to all 2500 questions.</span>
        <span className="Subscribe-Now">Subscribe Now!</span>
      </div>}

      <ModalPayment
        onConfirm={(linkUrl: string) => onSelectPlan(linkUrl)}
        description={
          <span>
            Subscribe to get access to all our premium exams now!
          </span>
        }
        ref={modalPaymentRef}
        title="payment"
      ></ModalPayment>
    </ProtectedPages>
  );
};
export default Help;

import React, { useState, useRef, useEffect } from 'react';
import { Button, Nav, Navbar } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import './navbar.scss'
import { useUserState } from '../../services/User';
import { db, useAuthState } from '../../services/firebase';
import { Role } from '../../models/role.enum';
import { doc, getDoc } from 'firebase/firestore';
import { Exam } from '../../models/exam';
import useWindowDimensions from '../../services/window-dimensions';
import { ModalPayment } from '../../components/modal-payment/ModalPayment';
import { Utils } from '../../util/string-utils';

let block: boolean;
const CustomNavBar = (props: any) => {
  const user = useUserState()
  const location = useLocation();
  const navigate = useNavigate();
  const modalPaymentRef: any = useRef(null);

  const { height, width, isMobile, isTablet, isMobileOnly, isTabletPortrait, isDesktop } = useWindowDimensions();
  const { isAuthenticated } = useAuthState();

  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const mobileNavRef = useRef<HTMLDivElement>(null);
  const [exam, setExam] = useState<Exam>();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      setTimeout(() => {
      if (mobileNavRef.current && !mobileNavRef.current.contains(event.target as Node)) {
        if (!block) setIsNavExpanded(false)
      }
      }, 10);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (location.pathname.includes('exam-answers')) {
      fetchExamApi();
    }
  }, [user?.id])

  async function fetchExamApi() {
    const docRef = doc(db, 'exam', location.pathname.replace('/exam-answers/', ''));
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const examVar = { ...(docSnap.data() as Exam), id: docSnap.id };
      setExam({ ...examVar });
      return examVar;
      //console.log(examVar);
    } else {
      console.log('No such document!');
    }
  }

  function setCurrentNav(navValue: string) {
    setIsNavExpanded(!isNavExpanded);
    window.localStorage.setItem('currentNav', navValue);
  }

  function getCurrentNav(): string {
    if (location.pathname.includes('exam-perform')) {
      return 'exams-list'
    } else {
      return location.pathname.replace('/','');
    }
  }

  function isActive(page: string) {
    if (getCurrentNav() === page) {
      return ' active';
    } else {
      return '';
    }
  }

  function menuClick() {
    block = true;
    setIsNavExpanded(!isNavExpanded);
    setTimeout(() => {
      block = false;
    }, 100);
  }

  function onSelectPlan(linkUrl: string) {
    console.log(linkUrl);
    modalPaymentRef.current.handleHide();
    Utils.handleCheckoutSimple(linkUrl, user);
  }


  return (

    ( (isAuthenticated || location.pathname.includes('exam-perform')) && !location.pathname.includes('/landing') && !location.pathname.includes('reset-password')) ? <div id='CustomNavBar' className={(isMobile ? 'mobile ' : '') + (isTablet ? 'tablet ' : '')+ (isNavExpanded ? "custom-expanded" : "")}>
      <Navbar bg="light" expand="sm">
        <div className='nav-container'>
          <span className='nav-title'>
            {location.pathname.includes('exam-perform') && (user.firstName ? (user.firstName + ' ' + user.lastName) : 'Demo User')}
          </span>
          {!location.pathname.includes('exam-perform') &&<div className="menu-content-container">
            {!user?.checkoutSessions?.length && <Button size='sm' className='flex items-center px-4 mr-8' style={{background: '#F3F6F9'}}>
              <span onClick={() => modalPaymentRef.current.handleShow()} className='mr-3' style={{color: '#0249FB'}}>Subscribe!</span>
              <img height={20} src="/icons/rocket.svg"></img>
            </Button>}
            <span className='profile-name' onClick={() => navigate('/profile')}>Hello, {user?.firstName}.{user?.lastName?.substring(0, 1)} {user.role === Role.ADMIN ? '(Admin)': ''}</span>
            <img src='/icons/profile.svg' />
          </div>}
        </div>

        <div ref={mobileNavRef} className={ isNavExpanded ? "nav-container-mobile expanded" : "nav-container-mobile" }>
          <Nav.Item className={"underline" + isActive('home')} as={Link} to="/home" onClick={() => setCurrentNav('home')}>
            <span>Home</span>
          </Nav.Item>
          <Nav.Item className={"underline" + isActive('your-body')} as={Link} to="/your-body" onClick={() => setCurrentNav('your-body')}>
            <span>Your Body</span>
          </Nav.Item>
          <Nav.Item className={"underline" + isActive('main-exercises')} as={Link} to="/exercises/main-exercises" onClick={() => setCurrentNav('main-exercises')}>
            <span>Exercises</span>
          </Nav.Item>
          <Nav.Item className={"underline" + isActive('library')} as={Link} to="/library" onClick={() => setCurrentNav('library')}>
            <span>Library</span>
          </Nav.Item>
          <Nav.Item className={"underline" + isActive('user')} as={Link} to="/user" onClick={() => setCurrentNav('user')}>
            <span>You</span>
          </Nav.Item>
        </div>

        <ModalPayment
          onConfirm={(linkUrl: string) => onSelectPlan(linkUrl)}
          description={<span>Complete your purchase to get access to all our premium exams now!</span>}
          ref={modalPaymentRef}
          title="payment"
        ></ModalPayment>
      </Navbar>
    </div>
    : null
  )
}

export default CustomNavBar

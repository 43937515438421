import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './pdf-modal.scss';
import { TermsAndConditions } from './terms-and-conditions/terms-and-conditions';

type Props = {
  title: string;
  //body: any
};

export const PdfModal = forwardRef((props: Props, ref) => {
  useImperativeHandle(ref, () => ({
    handleShow,
    handleCancel,
  }));

  const [body, setBody] = useState(null);

  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleCancel = () => {
    setShowAuthModal(false);
    //if (props.onCancel) props.onCancel();
  };

  const handleConfirm = () => {
    setShowAuthModal(false);
    //if (props.onConfirm) props.onConfirm();
  };

  const handleShow = (body: any) => {
    setBody(body);
    setShowAuthModal(true);
  };

  return (
    <section id="pdf-modal">
      <Modal show={showAuthModal} onHide={handleCancel} centered dialogClassName="cover-modal pdf-modal">
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body}
        </Modal.Body>
      </Modal>
    </section>
  );
});

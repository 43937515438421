import React from 'react';
import { Table } from 'react-bootstrap'; // Assuming you are using react-bootstrap
import { ExamResult } from '../../models/exam-result';
import { Exam } from '../../models/exam';
import { Question } from '../../models/question';
import { Utils } from '../../util/string-utils';

interface Props {
  examSubjects?: { id: string; name: string }[];
  exam?: Exam;
  selectedExamResult?: ExamResult;
  fetchSelectedImage: (url: string) => void;
  fetchSelectedVideo: (question: Question) => void;
}

const ResultAnswers: React.FC<Props> = ({ examSubjects, exam, selectedExamResult, fetchSelectedImage, fetchSelectedVideo }) => {
  console.log(exam)
  function answeredCorrectly(question: Question, i: number) {
    let isCorrect;
    if (!selectedExamResult) return false;
    const found = selectedExamResult.answers?.[0]?.questionId
      ? selectedExamResult.answers?.find((answer) => answer.questionId === question.id)
      : selectedExamResult.answers?.[i];

    if (found && found.markedOptions?.length && found.markedOptions?.length === question.correctOptions?.length) {
      if (question.correctOptions?.length) {
        let anyWrong = true;
        question.correctOptions.forEach((correctOption) => {
          const right = found.markedOptions.some((m) => m === correctOption);
          if (!right) anyWrong = false;
        });

        if (anyWrong) isCorrect = true;
      }
    }

    return isCorrect;
  }

  function getResultForQuestion(question: Question, index: number) {
    let foundAnswer;
    if (selectedExamResult?.answers?.[0]?.questionId) {
      foundAnswer = selectedExamResult?.answers?.find((answer) => answer.questionId === question.id);
    } else {
      foundAnswer = selectedExamResult?.answers?.[index];
    }
    return foundAnswer;
  }

  function isResultForQuestionInvalid(question: Question, index: number) {
    const foundAnswer = getResultForQuestion(question, index);

    let invalid = false;
    foundAnswer?.markedOptions?.map((option) => {
      //@ts-ignore
      if (option === undefined || option === '.') {
        invalid = true;
      }
    });

    if (!foundAnswer?.markedOptions?.length) invalid = true;

    return invalid;
  }

  return (
    <>
      {examSubjects?.map((subject, i) => (
        <div className="subject-container-parent" key={subject.id}>
          <div className={`header-box ${i !== 0 ? 'mt-4' : ''}`}>
            <span className="header-span">{subject.name}</span>
          </div>

          <div className="flex flex-col gap-5">
            {exam?.questions
              ?.filter((q) => q.subject === subject.id)
              ?.map((question: Question, i: number) => (
                <div className="border-c rounded-xl" style={{ borderColor: '#F1416C' }} key={question.id}>
                  <div style={{ borderColor: '#F1416C' }} className="flex px-6 py-4 border-b">
                    <div className="flex gap-3">
                      <img src="/icons/red-rectangle.svg"></img>
                      <span style={{ width: '105px' }}>Your answer</span>
                    </div>
                    <div className="flex gap-3">
                      <img src="/icons/green-rectangle.svg"></img>
                      <span style={{ width: '105px' }}>Right answer</span>
                    </div>

                    {(question?.videoUrl || question.resolutionImageUrl) && (
                      <div className="video-td ml-auto mr-8 gap-3 cursor-pointer">
                        {question?.resolutionImageUrl && (
                          <span
                            style={{ color: 'blue', fontWeight: '600' }}
                            onClick={() => fetchSelectedImage(question.resolutionImageUrl!)}
                          >
                            Solution
                          </span>
                        )}

                        {question?.videoUrl && (
                          <img onClick={() => fetchSelectedVideo(question)} src="/icons/video.svg" />
                        )}
                      </div>
                    )}
                  </div>

                  <div className="flex justify-between gap-10">
                    <div className="flex flex-col gap-5 p-5 m-5 rounded" style={{ background: '#F9F9F9', width: '50%' }}>
                      {question.headerImageUrl && <img src={question.headerImageUrl}></img>}
                      <span>Question {i + 1}</span>
                      <img src={question.questionImageUrl}></img>
                    </div>
                    <div style={{ width: '50%' }} className="flex flex-col gap-6 justify-center">
                      <div className={'answer-box' + (question.isMath ? ' is-math' : '')}>
                        <img
                          src={answeredCorrectly(question, i) ? '/icons/answer-marked-green.svg' : '/icons/answer-marked-red.svg'}
                        />
                        {isResultForQuestionInvalid(question, i) && <span>Not answered</span>}
                        {getResultForQuestion(question, i)?.markedOptions?.map((option: number, i: number) => (
                          <div className="option-row" key={'o' + option}>
                            {!question.isMath ? <div>{Utils.getLetter(option)}</div> : <span>{isNaN(option) ? '' : option % 10}</span>}
                          </div>
                        ))}
                      </div>

                      {/* {exam?.type == 'Wa1XNOCHd6OqZ29M9K4D' ? (
                        <GlaAnswer i={i} question={question} result={selectedExamResult!}></GlaAnswer>
                      ) : ( */}
                      <div className={'answer-box' + (question.isMath ? ' is-math' : '')}>
                        <img src="/icons/answer-marked-green.svg" />
                        {question?.correctOptions?.map((option: number, i: number) => (
                          <div className="option-row" key={'o' + option}>
                            <div>{question.isMath ? option % 10 : Utils.getLetter(option)}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default ResultAnswers;
